<template>
  <div class="select-step">
    <div class="font-bold title-page">CM動画追加</div>
    <div class="description-page">検証方法を選んでください。</div>
    <div class="form-group required" v-if="currentUser.role == 'admin'">
      <label>クライアント</label>
      <Select
            :data="data"
            :idSelected="idSelected"
            :isSelected="isSelected"
            @select="selectBuyer"
            @initSelectedId="initSelectedBuyer"
            :element="element"
      ></Select>
    </div>
    <div class="row-9">
      <div class="row-9-col-3 step-item">
        <div class="step-content">
          <div class="step-name">{{ listStep.step1.name }}</div>
          <div class="step-description">
            制作するCM動画の特長を区分するときや多くの反応種別を取得したいときに適しています。反応種別は個別に設定可能です。
          </div>
          <div class="chart-images d-flex justify-content-center align-items-center">
            <img src="/images/img/charts/radar-chart.png" alt="">
          </div>
          <button class="btn-change"
                  @click="changeStep(listStep.step1.id)"
                  :class="{
                    'btn-changed': stepChanged == listStep.step1.id,
                    'btn-disabled': isDisabled,
                  }">選択
          </button>
        </div>
      </div>
      <div class="row-9-col-3 step-item">
        <div class="step-content">
          <div class="step-name">{{ listStep.step2.name }}</div>
          <div class="step-description">
            最もベーシックな検証方法です。反応種別は個別に設定可能です。
          </div>
          <div class="chart-images d-flex justify-content-center align-items-center">
            <img src="/images/img/charts/circle-chart.png" alt="">
          </div>
          <button class="btn-change"
                  @click="changeStep(listStep.step2.id)"
                  :class="{
                    'btn-changed': stepChanged == listStep.step2.id,
                  }">選択
          </button>
        </div>
      </div>
      <div class="row-9-col-3 step-item">
        <div class="step-content">
          <div class="step-name">{{ listStep.step3.name }}</div>
          <div class="step-description">
            メディアの変数を横軸で固定し、純粋なクリエイティブの影響力が測定可能です。
          </div>
          <div class="chart-images d-flex justify-content-center align-items-center">
            <img src="/images/img/charts/line-chart.png" alt="">
          </div>
          <button class="btn-change"
                  @click="changeStep(listStep.step3.id)"
                  :class="{
                    'btn-changed': stepChanged == listStep.step3.id,
                    'btn-disabled': isDisabled,
                  }">選択
          </button>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="form-group">
        <button class="btn btn-primary btn-submit" :disabled="stepChanged == null" @click="submitStep">
          次へ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { planTypes } from "@/commons/constants";
import Select from "@/components/Video/Select.vue";
export default {
  components: {
    Select
  },
  name: "SelectStep",
  props: {
    listStep: Object,
    stepChanged: Number,
    data: Array,
    idSelected: [Number, String],
    isSelected: Boolean,
    element: String,
    clients: Array,
    currentUser: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buyerId: null,
      buyerSelected: {},
      isDisabled: false,
      planTypes,
    }
  },
  methods: {
    isDisablePlanAdvance(){
      if(this.buyerSelected && this.buyerSelected.plan != planTypes.ADVANCE_PLAN.buyer_plan) {
        this.isDisabled = true
        return
      }
      this.isDisabled = false
    },
    changeStep(step) {
      this.$emit("changeStep", step)
    },
    submitStep() {
      this.$emit("submitStep")
      if(this.currentUser.role == 'admin'){
        this.$emit("selectBuyer", this.buyerSelected)
      }
    },
    initSelectedBuyer(id){
      this.selectBuyer(id)
    },
    initCheckBuyerPlan(){
      if(this.currentUser.role == 'buyer'){
        if(this.currentUser.plan != planTypes.ADVANCE_PLAN.buyer_plan){
          this.isDisabled = true
          return
        }
        this.isDisabled = false
      }
    },
    selectBuyer(id) {
      this.buyerId = parseInt(id);
      this.buyerSelected = this.clients.filter(data => data.id === this.buyerId)[0]
      if (!this.buyerSelected) return
      this.isDisablePlanAdvance()
      this.$emit("selectBuyer", this.buyerSelected)
    }
  },
  created(){
  },
  mounted() {
    this.initCheckBuyerPlan()
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables/_colors.scss";

.title-page {
  font-family: 'Noto Sans JP';
  color: $black;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 12px;
}

.description-page {
  font-family: 'Avenir Next';
  color: $black;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 28px;
}

.step-content {
  padding: 22px 20px 20px 20px;
  box-shadow: 0 2px 8px rgb(51 51 51 / 10%);
  border-radius: 8px;
  min-height: 410px;
  position: relative;

  .step-name {
    font-family: 'Noto Sans JP';
    color: $black;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    padding-bottom: 8px;
  }

  .step-description {
    font-family: 'Noto Sans JP';
    color: $black;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 27px;
  }

  .chart-images {
    margin-top: 20px;
  }

  .btn-change {
    font-family: "Noto Sans JP";
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
    border: 1px solid $green;
    border-radius: 8px;
    background: $white;
    height: 40px;
    color: $green;
    cursor: pointer;

    &.btn-changed {
      background: $light-green;
    }

    &.btn-disabled {
      border-color: $middle-black;
      background-color: $light-black;
      color: $black;
      font-weight: bold;
      user-select: none;
      cursor: not-allowed;
    }
  }
}

.btn-submit {
  margin-top: 32px;
  width: 308px !important;
}
</style>