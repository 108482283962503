<template>
    <div class="login-container">
        <side-left></side-left>
        <div class="side-right">
            <div class="form-group-login">
                <form @submit.prevent="handleSubmit" novalidate>
                    <div class="title-form mb-15">{{$t('texts.login.title')}}</div>
                    
                    <div class="form-control mb-3" :class="{'error': (submitted && $v.email.$error) || errorMessage}">
                        <input class="input pl-5 pr-5" type="email" v-model="email" :placeholder="$t('placeholder.email')" />
                        <template v-if="submitted && $v.email.$error">
                            <span class="error error-message" v-if="!$v.email.required">{{$t('validation.login.email.required')}}</span>
                            <span class="error error-message" v-else-if="!$v.email.email">{{$t('validation.login.email.email')}}</span>
                            <span class="error error-message" v-else-if="!$v.email.maxLength">{{$t('validation.login.email.max-length')}}</span>
                        </template>
                    </div>
                    <div class="form-control"  :class="{'error': (submitted && $v.password.$error) || errorMessage}">
                        <input class="input pl-5 pr-5" type="password" v-model="password" :placeholder="$t('placeholder.password')" />
                        <template v-if="submitted && $v.password.$error">
                            <span class="error error-message" v-if="!$v.password.required">{{$t('validation.login.password.required')}}</span>
                            <span class="error error-message" v-else>{{$t('validation.login.password.format_login')}}</span>
                        </template>
                    </div>
                     <span class="error error-message mt-2" v-if="errorMessage">{{$t('messages.login.login_fail')}}</span>
                    <div class="button mb-7 mt-5">
                        <button class="btn btn-submit btn-primary" type="submit">{{$t('texts.login.login_btn')}}</button>
                    </div>
                </form>
                <div class=" mb-3">
                    <a class="btn-primary"  href="javascript:" @click="redirectForgot()" >{{$t('texts.login.forgot_pw')}}
                      <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 3.63397C7.16667 4.01887 7.16667 4.98113 6.5 5.36603L2 7.9641C1.33333 8.349 0.5 7.86788 0.5 7.09808L0.5 1.90192C0.5 1.13212 1.33333 0.650998 2 1.0359L6.5 3.63397Z" fill="#49AB94"/>
                      </svg>

                    </a>
                </div>
                <div id="seal_area"></div>
            </div>
        </div>
    </div>
    
</template>

<script>
import Vue from 'vue';
import router from '../../routes/index';
import {required,email, maxLength, minLength} from "vuelidate/lib/validators";
import SideLeft from "../../components/Login/SideLeft.vue";
import { checkPass } from "../../commons/rules";
const jQuery = require("jquery");

  export default {
    name: 'Login',
    components: {
      SideLeft
    },
    data() {
      return {
        email: '',
        password: '',
        errorMessage: false,

        submitted: false,
        isLoading: false,
      };
    },
    validations: {
      email: {required, email, maxLength: maxLength(255)},
      password: {required, minLength: minLength(8), maxLength: maxLength(16), checkPass},
    },
    methods: {
        redirectForgot() {
            this.$router.push({name: 'forgot-password'});
        },
        handleSubmit() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errorMessage = '';
                return;
            }
            this.isLoading = true;
            this.$auth.login({
                body: {
                    email: this.email,
                    password: this.password
                },
                success: function (res) {
                    if (res.body && (res.body.plan == 5 || res.body.plan == 6)) {
                        this.submitted = false;
                        this.isLoading = false;
                    } else {
                        this.$store.commit('setCurrentUser', res.body);
                        this.$store.commit('setToken', res.body.access_token)
                        this.$store.commit('setLogged', true)
                        if (Vue.checkPrevious) {
                            this.$router.go(-1);
                        } else {
                            // if (this.$store.getters.currentUser.plan && this.$store.getters.currentUser.plan == 5) {
                            //     router.push({name: 'mrk-report'});
                            // } else {
                                router.push({name: 'home'});
                            // }
                        }
                    }
                },
                error: function (err) {
                    console.log(err)
                    // this.$toasted.error(res.body.message);
                    // Failed login message
                    this.submitted = false;
                    this.isLoading = false;
                    this.errorMessage = err.body.Message;
                },
            })
        },
        pasteSealScript(){
            const element = document.getElementById('seal_area')
            if(element){
                element.insertAdjacentHTML('beforeend',
                `<div class="seal_box">
                    <span id="csi_siteseal_tag" oncontextmenu="return false;">
                        <a id="csi_siteseal_profile_link">
                            <img loading="lazy" alt="light_100x50.png" id="csi_siteseal_image" width="100" height="50" src="#" />
                        </a>
                    </span>
                </div>`)
                element.insertAdjacentHTML('beforeend',
                `<div class="seal_box">
                    <div id="ss_gmo_globalsign_secured_site_seal" oncontextmenu="return false;" style="width:100px; height:50px">
                        <img id="ss_gmo_globalsign_img" src="data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7" alt="" onclick="ss_open_profile()" style="cursor:pointer; border:0; width:100px" >
                    </div>
                </div>`)
            }

            const target = document.getElementById('seal_script')
            for(let i = 0; i < 2; i++){
                const script = document.createElement('script')
                script.setAttribute('defer', 'defer')
                if(i == 0){
                    script.setAttribute('src', 'https://siteseal.gmo-cybersecurity.com/static/scripts/siteseal.js')
                } else {
                    script.setAttribute('src', 'https://seal.atlas.globalsign.com/gss/one/seal?image=seal_100-50_ja_t.png')
                }
                target.appendChild(script)
            }
            let vm = this
            vm.setStyle();
        },
        getHeight(){
            let rightContent = jQuery(".side-right")[0];
            if(rightContent){
            this.contentHeight = rightContent.clientHeight;
                this.setStyle();
            }
        },
        setStyle(){
            let element = document.getElementById('csi_siteseal_tag')
            let secureSeal = document.getElementById('ss_gmo_globalsign_secured_site_seal')
            if(element && secureSeal){
                secureSeal.id = 'secure-seal';
                element.style.cssText = 'display: inline-block; margin-right: 10px;'
                secureSeal.style.cssText = 'display: inline-block; '
            }
        },
        setPhoneStyle(windowWidth){
            let element = document.getElementById('csi_siteseal_tag')
            if(windowWidth <= 420){
                element.style.cssText = 'display: inline-block; margin-right: 0; margin-bottom: 10px;'
            } else {
                element.style.cssText = 'display: inline-block; margin-right: 10px; margin-bottom: 0;'
            }
        }
    },
    created() {
    },
    updated(){
        let element = document.getElementById('csi_siteseal_tag')
        let secureSeal = document.getElementsByName('ss_imgTag')[0]
        if(element && secureSeal){
            element.style.display = 'none'
            secureSeal.style.display = 'none'
        }

        let vm = this
        setTimeout(function () {
            vm.getHeight()
        }, 300)
    },
    mounted() {
        this.pasteSealScript();
        window.addEventListener('resize', this.getHeight)
        window.addEventListener('load', () => {
            this.setPhoneStyle(window.innerWidth);
        });
        window.addEventListener('resize', () => {
            this.setPhoneStyle(window.innerWidth);
        });
    }
  };
</script>

<style lang="scss" scoped>
@import "@/styles/_login.scss";
#seal_area{
    position: absolute;
    right: 0;
    bottom: 0;
    height: auto;
    margin: 35px 100px;
    display: flex;
    align-items: center;
}
#csi_siteseal_tag{
    display: none;
}

#ss_img{
  display: none !important;
}

img[name="ss_imgTag"]{
  display: none;
  width: 100px;
  height: 50px;
}
@media screen and (max-width: 420px) {
    #seal_area{
        width: 100px;
        display: inline-block;
        margin:35px 0;
        left: 50%;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(0, 0);
    }

    .seal_box{
        width: 100px;
        height: 50px;
    }
    
}
</style>

