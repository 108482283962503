<template>
  <div
    class="modal add-segment-multiple form-single modal-show"
    v-show="isShown"
    ref="modal"
  >
    <div class="modal-content">
      <div class="header">
        <div class="btn-back">
          <i class="icon icon-prev" @click="closeModal()"></i>
        </div>
      </div>
      <div class="body">
        <div class="label">{{ attribute.name }}</div>
        <div class="checkbox-list-select" v-if="attribute.id == 12">
          <div
            class="checkbox-segment"
            v-for="regionCluster in regionClusterList"
            :key="regionCluster.name"
            @click="toggleRegionCluster(regionCluster)"
          >
            <i
              class="icon icon-checkbox"
              :class="{ checked: isCheckedRegionCluster(regionCluster) }"
            ></i>
            <span class="label-segment">{{ regionCluster.name }}</span>
          </div>
        </div>
        <div class="checkbox-list-select">
          <div
            class="checkbox-segment"
            v-for="value in attribute.values"
            :key="'attribute-value-' + value.id"
            @click="toggleValue(value)"
          >
            <i
              class="icon icon-checkbox"
              :class="{ checked: isChecked(value) }"
            ></i>
            <span class="label-segment">{{ value.value }}</span>
          </div>
        </div>
        <div class="btn-submit">
          <button
            class="btn btn-primary btn-addition"
            @click="addSegment()"
            :disabled="!segmentSelecteds.length"
          >
            追加
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "../../commons/event_bus";

export default {
  props: {
    isShown: Boolean,
    attribute: Object,
    attributeStatus: Object,
    prevSelected: Array
  },
  data() {
    return {
      segmentSelecteds: [],
      selectedClusters: [],
      regionClusterList: [
        {name: '北海道', regions: ['北海道']},
        {name: '東北', regions: ['青森県', '秋田県', '岩手県', '宮城県', '山形県', '福島県']},
        {name: '関東', regions: ['茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県']},
        {name: '中部', regions: ['新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県']},
        {name: '近畿', regions: ['三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県']},
        {name: '中国', regions: ['鳥取県', '島根県', '岡山県', '広島県', '山口県']},
        {name: '四国', regions: ['徳島県', '香川県', '愛媛県', '高知県']},
        {name: '九州沖縄', regions: ['福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県']},
      ]
    };
  },
  methods: {
    closeModal() {
      EventBus.$emit("toggleModal", "segment-selected");
    },
    addSegment() {
      let data = {
        segment_id: this.attribute.id,
        segment_name: this.attribute.name,
        segment_list: this.segmentSelecteds,
        category: this.attribute.category,
      };
      if (this.segmentSelecteds.length > 0) {
        this.$emit("addSegment", data);
      }
      this.segmentSelecteds = [];
    },
    getValueChecked(data) {
      if (data) {
        this.segmentSelecteds.push(data);
      }
    },
    isChecked(value) {
      return this.segmentSelecteds.find((e) => e.id == value.id);
    },
    toggleValue(value) {
      let segmentSelected = value;
      if (segmentSelected) {
        let index = this.segmentSelecteds.findIndex((e) => e.id == value.id);
        if (index != -1) {
          this.segmentSelecteds.splice(index, 1);
        } else {
          this.segmentSelecteds.push(segmentSelected);
        }
      }
    },
    toggleRegionCluster(regionCluster) {
      if(regionCluster) {
        let index = this.selectedClusters.findIndex((e) => e.name == regionCluster.name)
        let isOffAllRegion = false;
        if(index != -1) {
          this.selectedClusters.splice(index, 1);
          isOffAllRegion = true;
        } else {
          this.selectedClusters.push(regionCluster);
        }
        regionCluster.regions.forEach(region => {
          let regionIndex = this.segmentSelecteds.findIndex((e) => e.value == region);
          if(isOffAllRegion && regionIndex != -1) {
            this.segmentSelecteds.splice(regionIndex, 1);
          } else if(!isOffAllRegion && regionIndex == -1) {
            let value = this.attribute.values.find(e => e.value == region);
            this.segmentSelecteds.push(value);
          }
        })
      }
    },
    isCheckedRegionCluster(regionCluster) {
      return this.selectedClusters.find((e) => e.name == regionCluster.name)
    },
    initSelected(data) {
      this.segmentSelecteds = [];
      this.selectedClusters = [];
      if (data && data.list && data.list.length) {
         data.list.forEach(element => {
            let selected = this.attribute.values.find(e => e.id == element.id);
            if (selected) {
              this.segmentSelecteds.push(selected)
            }
         });
      }
    }
  },
  mounted() {
    this.initSelected(this.attributeStatus)
  },
  watch: {
    attributeStatus: function(data) {
      this.initSelected(data);
    },
    isShown: function(newValue) {
      this.$refs.modal.scrollTop = 0
      if (newValue) {
        let currentAttribute = this.prevSelected.find(e => e.id == this.attribute.id);
        if (currentAttribute) {
          this.segmentSelecteds  = JSON.parse(JSON.stringify(currentAttribute.list));
        } else {
          this.selectedClusters = [];
          this.segmentSelecteds = []
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_project.scss";
</style>