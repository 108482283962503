<template>
  <div class="row">
    <div class="col-13">
      <div class="header">動画再生テスト</div>
      <div class="content mt-3 row">
        <div class="col-5 content-left">
          <div class="title-medium mb-4 color-black">
            対象動画をアンケート配信前に確認いただくテストページです。
          </div>
          <div class="left-content color-black mb-4">
            <div class="number-serial">
              <div class="number-serial__row">
                <div class="number-serial__row-number">①</div
                ><span class="title-large"
                  >再生ボタンを押すと動画が再生されます。気になる部分があれば該当のボタンを押してください。</span
                >
              </div>
              <div class="number-serial__row">
                <div class="number-serial__row-number">②</div
                ><span class="title-large">動画の再生が終わりましたら、テストは終了です。</span>
              </div>
            </div>
          </div>
          <div class="title-medium color-black mb-2">
            もし、エラーメッセージが出た場合はエンコードエラーが発生している場合があります。動画に問題がないかご確認をお願いいたします。
          </div>
          <div class="title-small color-black-middle">
            ※音声が再生されることがあるので、音量等にご注意ください。<br />
            ※テストページでのリアクションの押下分は集計結果には反映されませんのでご安心ください。<br />
            ※動画によっては特定の倍速で再生できないことがございます。ご了承ください。
          </div>
          <div class="btn-submit">
            <button class="btn btn-primary-outline" @click="prevPage()">
              前のページへ戻る
            </button>
          </div>
        </div>
        <div class="col-7 content-right">
          <div class="video">
            <div class="video-detail">
              <video
                id="video"
                class="
                  video-js
                  vjs-default-skin vjs-big-play-centered vjs-nofull
                "
                controls
                controlsList="nodownload"
                preload="auto"
                disablepictureinpicture
                data-setup='{ "playbackRates": [1,2,4,6,8,10] }'
                v-if="videoRegister.video_url_type == videoUrlUpload"
              >
                <source :src="videoRegister.video_url" type="video/mp4" />
                <p class="vjs-no-js">
                  To view this video please enable JavaScript, and consider
                  upgrading to a web browser that
                  <a
                    href="http://videojs.com/html5-video-support/"
                    target="_blank"
                  >
                    supports HTML5 video
                  </a>
                </p>
              </video>
              <!-- <div
                class="video-icon"
                v-if="videoRegister.video_url_type == videoUrlUpload"
              >
                <i class="icon icon-btn-play"></i>
              </div> -->

              <div
                class="video-react-youtube"
                v-if="videoRegister.video_url_type !== videoUrlUpload"
              >
                <div id="video-youtube"></div>
              </div>
            </div>
          </div>
          <div class="react mt-6" v-if="videoRegister.deliver_period_type == listStep.step2.id">
            <button
              v-if="iconInteraction.rank_2.length"
              class="btn btn-react btn-interaction-left disable mr-5"
              :style="{ backgroundColor : colorInteraction.rank_2}"
              @click="eventReact('left')"
            >
              <div class="btn-icon-text">
                <img class="icon-left" style="margin-right : 8px; width : 24px" :src="iconInteraction.rank_2" alt="">
                {{textInteraction.rank_2}}
              </div>
            </button>
            <button
              v-if="iconInteraction.rank_3.length"
              class="btn btn-react btn-interaction-right disable"
              :style="{ backgroundColor : colorInteraction.rank_3}"
              @click="eventReact('right')"
            >
              <div class="btn-icon-text">
                <img class="icon-right" style="margin-right : 8px; width : 24px" :src="iconInteraction.rank_3" alt="">
                {{textInteraction.rank_3}}
              </div>
            </button>
          </div>
          <div class="react mt-6 d-flex align-items-center" v-if="videoRegister.deliver_period_type == listStep.step1.id">
            <button
                class="btn btn-react btn-interaction-middle btn-interaction-left disable"
                :style="{ backgroundColor : colorInteraction.rank_2}"
                @click="eventReact('middle')"
            >
              <div class="btn-icon-text">
                <img class="icon-middle" style="margin-right : 8px; width : 24px" :src="iconInteraction.rank_2" alt="">
                {{textInteraction.rank_2}}
              </div>
            </button>
          </div>
          <div class="form-group wrapper-btn pb-7 mt-6 mb-0">
            <button class="btn btn-primary btn-submit" @click="nextPage()">
              次へ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {stepListInfo} from "@/commons/constants";

const jQuery = require("jquery");
import videojs_cus from "../../components/VideoJsCustom";
export default {
  data() {
    let videoRegister = JSON.parse(localStorage.getItem("video_register"));
    let videoUrl = videoRegister.video_url;
    return {
      videoRegister,
      videoUrl,
      videoUrlUpload: 1,
      isVideoNotFail: false,
      isVideoPlaying: false,
      videoFile: null,
      videoEntity: null,
      listStep : JSON.parse(JSON.stringify(stepListInfo)),
    };
  },
  computed : {
    textInteraction() {
      return {
        rank_2 : this.videoRegister.interaction_btn_1_text ?? '',
        rank_3 : this.videoRegister.interaction_btn_2_text ?? '',
      }
    },
    iconInteraction() {
      return {
        rank_2 : this.videoRegister.interaction_btn_1 && Object.keys(this.videoRegister.interaction_btn_1).length ? this.videoRegister.interaction_btn_1.btn_icon_url : '',
        rank_3 : this.videoRegister.interaction_btn_2 && Object.keys(this.videoRegister.interaction_btn_2).length ? this.videoRegister.interaction_btn_2.btn_icon_url : '',
      }
    },
    colorInteraction(){
      let color_rank_2 = this.videoRegister.interaction_btn_1 && Object.keys(this.videoRegister.interaction_btn_1).length ? this.videoRegister.interaction_btn_1.color : '';
      let color_rank_3 = this.videoRegister.interaction_btn_2 && Object.keys(this.videoRegister.interaction_btn_2).length ? this.videoRegister.interaction_btn_2.color : ''
      return {
        rank_2: color_rank_2 == 1 ? '#4976ab' : color_rank_2 == 2 ? '#e5bb26' : color_rank_2 == 3 ? '#ce6060' : color_rank_2 == 4 ? '#cf2f99' : color_rank_2 == 5 ? '#ab7249' : '',
        rank_3: color_rank_3 == 1 ? '#4976ab' : color_rank_3 == 2 ? '#e5bb26' : color_rank_3 == 3 ? '#ce6060' : color_rank_3 == 4 ? '#cf2f99' : color_rank_3 == 5 ? '#ab7249' : '',
      }
    },
  },
  methods: {
    setHeightVideo(){
      let videoDetail = jQuery('.video');
      let width = videoDetail.width()
      let height = (width * 9) / 16;
      if (height > 800) {
        height = 800;
        let widthVideo = (height * 16) / 9;
        videoDetail.width(widthVideo)
      }
      videoDetail.height(height)
    },
    nextPage() {
      if (this.isVideoNotFail) {
        this.$router.push({
          name: "video-complete",
          params: { video_file: this.videoFile },
        });
      }
    },
    prevPage() {
      if (this.videoRegister.id) {
        this.$router.push({
          name: "video-edit",
          params: { video_id: this.videoRegister.id, video_file: this.videoFile },
        });
      } else {
        this.$router.push({
          name: "add",
          params: { video_file: this.videoFile },
        });
      }
    },
    initVideo() {
      var $source = jQuery("#video");
      this.videoEntity = videojs_cus($source[0], {
        language: "ja",
        controlBar: {
          fullscreenToggle: false,
        },
      });
    },
    onPlayVideo() {
      let vm = this;
      jQuery("#video").on("play", function () {
        jQuery(".video-icon").css("display", "none");
        jQuery(".react").find(".btn-interaction-left").css("opacity", 1);
        jQuery(".react").find(".btn-interaction-right").css("opacity", 1);
        vm.isVideoPlaying = true;
      });
    },
    onPauseVideo() {
      let vm = this;
      jQuery("#video").on("pause", function () {
        jQuery(".video-icon").css("display", "flex");
        jQuery(".react").find(".btn-interaction-left").css("opacity", 0.7);
        jQuery(".react").find(".btn-interaction-right").css("opacity", 0.7);
        vm.isVideoPlaying = false;
      });
    },
    checkVideoEnded() {
      let vm = this;
      jQuery("#video").on("ended", function () {
        vm.isVideoNotFail = true;
        jQuery(".btn-submit").css("opacity", 1);
      });
    },
    onWaiting() {
      let vm = this;
      jQuery("#video").on("waiting", function () {
        if(vm.videoEntity.playbackRate() > 2) {
          vm.videoEntity.playbackRate(1)
          vm.videoEntity.currentTime(vm.videoEntity.currentTime() - 0.1)
        }
      });
    },
    settingDefault() {
      jQuery(".btn-submit").css("opacity", 0.7);
      jQuery(".video-icon").on("click", function () {
        jQuery(this).css("display", "none");
        jQuery("#video").trigger("play");
      });
      
    },
    videoYoutube() {
      if (window && window.YT && typeof window.YT == "undefined") {
        var tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
      this.onYouTubePlayer();
    },
    onYouTubePlayer() {
      let video_url = this.videoUrl;
      let wrapper_video_id = "video-youtube";
      let youtube_video_id = video_url;
      this.videoEntity = new window.YT.Player(wrapper_video_id, {
        height: "100%",
        width: "100%",
        videoId: youtube_video_id,
        playerVars: {
          rel: 0,
          controls: 1,
          disablekb: 1,
          showinfo: 0,
          hl: "ja",
          autoplay: 0,
          fs: 0,
          playsinline: 1,
        },
        events: {
          onStateChange: this.onPlayerStateChange,
        },
      });
    },
    onPlayerStateChange(event) {
      let vm = this;
      // let VIDEO_END = 0 ;
      let VIDEO_PLAYING = 1;
      let VIDEO_PAUSE = 2;
      switch (event.data) {
        case VIDEO_PLAYING:
          jQuery(".video-icon").css("display", "none");
          jQuery(".react").find(".btn-interaction-left").css("opacity", 1);
          jQuery(".react").find(".btn-interaction-right").css("opacity", 1);
          jQuery(".btn-submit").css("opacity", 1);
          vm.isVideoNotFail = true;
          vm.isVideoPlaying = true;
          break;
        case VIDEO_PAUSE:
          jQuery(".video-icon").css("display", "flex");
          jQuery(".react").find(".btn-interaction-left").css("opacity", 0.7);
          jQuery(".react").find(".btn-interaction-right").css("opacity", 0.7);
          vm.isVideoPlaying = false;
          break;
      }
    },
    eventReact(e) {
      if (this.isVideoPlaying) {
        let wrapper = jQuery(".video-detail");
        let videoHeight = wrapper.css("height").slice(0, -2);
        let PC_SCREEN_WIDTH = 1200;
        let $originImg = jQuery(`.btn-interaction-${e}`).find(`.icon-${e}`).first();
        for (let index = 0; index < 3; index++) {
          setTimeout(() => {
            let randomNum = Math.floor(Math.random() * 4) + 1;
            let isPcAnim =
              wrapper.find("video").width() > PC_SCREEN_WIDTH ? "_pc" : "";
            let randomAnim = "anim" + randomNum + isPcAnim;
            let $floatingImg = $originImg.clone().css("display", "block");
            wrapper.append($floatingImg);
            wrapper.find(`.icon-${e}`).css("position", "absolute");
            if (this.iconInteraction.rank_2.length && this.iconInteraction.rank_3.length) {
              wrapper.find(`.icon-left`).css("left", "30%");
              wrapper.find(`.icon-right`).css("left", "60%");
            } 
            wrapper.find(`.icon-${e}`).css("top", videoHeight + "px");
            $floatingImg.css(
              "animation",
              "2s ease-in-out " + randomAnim + " normal infinite"
            ).css("width","120px").css("height","120px");
            setInterval(() => {
              $floatingImg.remove();
            }, 2000);
          }, 200 * index);
        }
      }
    },
  },
  mounted() {
    this.setHeightVideo();
    this.settingDefault();
    this.onPlayVideo();
    this.onPauseVideo();
    this.onWaiting();
    this.checkVideoEnded();
    if (this.videoRegister.video_url_type == this.videoUrlUpload) {
      this.initVideo();
    } else {
      this.videoYoutube();
    }
    jQuery(".wrapper-container").css('overflow', 'hidden')
    window.addEventListener("resize", this.setHeightVideo);
  },
  created() {
    this.videoFile = this.$route.params.video_file;
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_add.scss";
.video-js {
  // min-height: 355px !important;
  height: 100%;
  width: 100%;
}
.video-detail {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .video-icon {
    display: flex;
    position: absolute;
    z-index: 2;
    cursor: pointer;
  }
}
.wrapper-btn {
  margin-left: auto;
  button {
    width: 308px;
  }
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}
.content {
  height: 70vh;

  .title-small {
    white-space: normal !important;
  }
  .video {
    .video-react-youtube {
      width: 100%;
      height: 100%;
    }
  }
  .content-right{
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
