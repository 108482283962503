<template>
    <div class="mt-3">
        <div class="segment-attritute" :class="{'active': isOpen}" v-if="attributeData">
            <div class="name-attritute"  @click="handleToggle">{{attributeData.name}}</div>
            <i class="icon icon-arrow-bottom"></i>
            <i class="icon icon-arrow-top"></i>
            <div class="attritute-value" v-if="iconInteraction.rank_2.length && iconInteraction.rank_3.length">
                <template v-for="reportAttribute in reportAttributes">
                    <div class="row-4 mt-5" :key="'report-attribute-value-' + reportAttribute.attribute_value_id">
                        <div class="row-4-col-2" v-if="reportAttribute['2_top']">
                            <div class="card card-segment card-subtitle" :class="{'inactive': !reportAttribute['2_top'].video_id}">
                                <div class="content" :class="{'bg-when-not-interaction' : reportAttribute['2_top'].interaction_count == 0}">
                                    <div class="segment-title">
                                        <div class="reaction-type mb-3">
                                            <img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank_2" alt="">
                                            <div>{{textInteraction.rank_2}}の最大値カット</div>
                                        </div>
                                        <div class="title-right">
                                            <div class="number-total">{{reportAttribute['2_top'].interaction_count && reportAttribute['2_top'].interaction_count % 1 != 0 ? reportAttribute['2_top'].interaction_count.toFixed(1): reportAttribute['2_top'].interaction_count | numberWithCommas | addPointZero }}%</div>
                                            <div class="name">{{getAttributeValueNameById(reportAttribute.attribute_value_id)}}</div>
                                        </div>
                                    </div>
                                    <div class="segment-body"  v-if="reportAttribute['2_top'].video_id">
                                        <div class="body-left" v-if="reportAttribute['2_top'].interaction_count != 0">
                                            <img class="card-image" :src="reportAttribute['2_top'].image_data">
                                        </div>
                                        <div class="body-right" v-if="reportAttribute['2_top'].interaction_count != 0">
                                            <div class="label">{{getVideoName(reportAttribute['2_top'].video_id)}}</div>
                                            <div class="value">{{reportAttribute['2_top'].event_at}}s</div>
                                        </div>
                                        <div v-if="reportAttribute['2_top'].interaction_count == 0" style="color : #999999">このセグメントの反応値はございません</div>
                                    </div>
                                    <div class="segment-body" v-else>
                                        <div class="message">このセグメントの反応値はございません。</div>
                                    </div>
                                </div>
                                <div v-if="reportAttribute['2_top'].interaction_count != 0" class="right-card" @click="gotoDetailVideo(reportAttribute['2_top'].video_id)">
                                    <i class="icon icon-arrow-right" v-if="reportAttribute['2_top'].interaction_count != 0"></i>
                                </div>
                            </div>
                        </div>
                        <div class="row-4-col-2" v-if="reportAttribute['3_top']">
                            <div class="card card-segment card-subtitle"  :class="{'inactive': !reportAttribute['3_top'].video_id}">
                                <div class="content" :class="{'bg-when-not-interaction' : reportAttribute['3_top'].interaction_count == 0}">
                                    <div class="segment-title">
                                        <div class="reaction-type mb-3">
                                            <img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank_3" alt="">
                                            <div>{{textInteraction.rank_3}}の最大値カット</div>
                                        </div>
                                        <div class="title-right">
                                            <div class="number-total">{{reportAttribute['3_top'].interaction_count && reportAttribute['3_top'].interaction_count % 1 != 0 ? reportAttribute['3_top'].interaction_count.toFixed(1): reportAttribute['3_top'].interaction_count | numberWithCommas | addPointZero }}%</div>
                                            <div class="name">{{getAttributeValueNameById(reportAttribute.attribute_value_id)}}</div>
                                        </div>
                                    </div>
                                    <div class="segment-body" v-if="reportAttribute['3_top'].video_id">
                                        <div class="body-left" v-if="reportAttribute['3_top'].interaction_count != 0">
                                            <img class="card-image" :src="reportAttribute['3_top'].image_data">
                                        </div>
                                        <div class="body-right" v-if="reportAttribute['3_top'].interaction_count != 0">
                                            <div class="label">{{getVideoName(reportAttribute['3_top'].video_id)}}</div>
                                            <div class="value">{{reportAttribute['3_top'].event_at}}s</div>
                                        </div>
                                        <div v-if="reportAttribute['3_top'].interaction_count == 0" style="color : #999999">このセグメントの反応値はございません</div>
                                    </div>
                                    <div class="segment-body" v-else>
                                        <div class="message">このセグメントの反応値はございません。</div>
                                    </div>
                                </div>
                                <div v-if="reportAttribute['3_top'].interaction_count != 0" class="right-card"  @click="gotoDetailVideo(reportAttribute['3_top'].video_id)">
                                    <i class="icon icon-arrow-right" v-if="reportAttribute['3_top'].interaction_count != 0"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="attritute-value" v-if="iconInteraction.rank_2.length && !iconInteraction.rank_3.length">
                <template>
                    <div class="row-4 mt-5">
                        <div class="row-4-col-2" v-for="reportAttribute in reportAttributes" :key="'report-attribute-value-'+reportAttribute.attribute_value_id">
                            <div class="card card-segment card-subtitle" :class="{'inactive': !reportAttribute['2_top'].video_id}">
                                <div class="content" :class="{'bg-when-not-interaction' : reportAttribute['2_top'].interaction_count == 0}">
                                    <div class="segment-title">
                                        <div class="reaction-type mb-3">
                                            <img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank_2" alt="">
                                            <div>{{textInteraction.rank_2}}の最大値カット</div>
                                        </div>
                                        <div class="title-right">
                                            <div class="number-total">{{reportAttribute['2_top'].interaction_count && reportAttribute['2_top'].interaction_count % 1 != 0 ? reportAttribute['2_top'].interaction_count.toFixed(1): reportAttribute['2_top'].interaction_count | numberWithCommas | addPointZero }}%</div>
                                            <div class="name">{{getAttributeValueNameById(reportAttribute.attribute_value_id)}}</div>
                                        </div>
                                    </div>
                                    <div class="segment-body"  v-if="reportAttribute['2_top'].video_id">
                                        <div class="body-left" v-if="reportAttribute['2_top'].interaction_count != 0">
                                            <img class="card-image" :src="reportAttribute['2_top'].image_data">
                                        </div>
                                        <div class="body-right" v-if="reportAttribute['2_top'].interaction_count != 0">
                                            <div class="label">{{getVideoName(reportAttribute['2_top'].video_id)}}</div>
                                            <div class="value">{{reportAttribute['2_top'].event_at}}s</div>
                                        </div>
                                        <div v-if="reportAttribute['2_top'].interaction_count == 0" style="color : #999999">このセグメントの反応値はございません</div>
                                    </div>
                                    <div class="segment-body" v-else>
                                        <div class="message">このセグメントの反応値はございません。</div>
                                    </div>
                                </div>
                                <div v-if="reportAttribute['2_top'].interaction_count != 0" class="right-card" @click="gotoDetailVideo(reportAttribute['2_top'].video_id)">
                                    <i class="icon icon-arrow-right" v-if="reportAttribute['2_top'].interaction_count != 0"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="attritute-value" v-if="iconInteraction.rank_3.length && !iconInteraction.rank_2.length">
                <template>
                    <div class="row-4 mt-5">
                        <div class="row-4-col-2" v-for="reportAttribute in reportAttributes" :key="'report-attribute-value-'+reportAttribute.attribute_value_id">
                            <div class="card card-segment card-subtitle" :class="{'inactive': !reportAttribute['3_top'].video_id}">
                                <div class="content" :class="{'bg-when-not-interaction' : reportAttribute['3_top'].interaction_count == 0}">
                                    <div class="segment-title">
                                        <div class="reaction-type mb-3">
                                            <img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank_3" alt="">
                                            <div>{{textInteraction.rank_3}}の最大値カット</div>
                                        </div>
                                        <div class="title-right">
                                            <div class="number-total">{{reportAttribute['3_top'].interaction_count && reportAttribute['3_top'].interaction_count % 1 != 0 ? reportAttribute['3_top'].interaction_count.toFixed(1): reportAttribute['3_top'].interaction_count | numberWithCommas | addPointZero }}%</div>
                                            <div class="name">{{getAttributeValueNameById(reportAttribute.attribute_value_id)}}</div>
                                        </div>
                                    </div>
                                    <div class="segment-body"  v-if="reportAttribute['3_top'].video_id">
                                        <div class="body-left" v-if="reportAttribute['3_top'].interaction_count != 0">
                                            <img class="card-image" :src="reportAttribute['3_top'].image_data">
                                        </div>
                                        <div class="body-right" v-if="reportAttribute['3_top'].interaction_count != 0">
                                            <div class="label">{{getVideoName(reportAttribute['3_top'].video_id)}}</div>
                                            <div class="value">{{reportAttribute['3_top'].event_at}}s</div>
                                        </div>
                                        <div v-if="reportAttribute['3_top'].interaction_count == 0" style="color : #999999">このセグメントの反応値はございません</div>
                                    </div>
                                    <div class="segment-body" v-else>
                                        <div class="message">このセグメントの反応値はございません。</div>
                                    </div>
                                </div>
                                <div v-if="reportAttribute['3_top'].interaction_count != 0" class="right-card" @click="gotoDetailVideo(reportAttribute['3_top'].video_id)">
                                    <i class="icon icon-arrow-right" v-if="reportAttribute['3_top'].interaction_count != 0"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        attributeData: Object,
        reportAttributes: Array,
        interactionType:Number,
        videos: Array,
        project : Object
    },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        textInteraction() {
            return {
                rank_2 : this.project.interaction_btn_1 && Object.keys(this.project.interaction_btn_1).length ? this.project.interaction_btn_1.text : '',
                rank_3 : this.project.interaction_btn_2 && Object.keys(this.project.interaction_btn_2).length ? this.project.interaction_btn_2.text : '',
            }
        },
        iconInteraction() {
            return {
                rank_2 : this.project.interaction_btn_1 && Object.keys(this.project.interaction_btn_1).length ? this.project.interaction_btn_1.btn_icon_url : '',
                rank_3 : this.project.interaction_btn_2 && Object.keys(this.project.interaction_btn_2).length ? this.project.interaction_btn_2.btn_icon_url : '',
            }
        },
    },
    methods: {
        getInteractionIcon(video_id){
            let video = this.videos.find(vid => vid.id == video_id);
            return {
                rank_2 : video && Object.keys(video.interaction_btn_1).length ? video.interaction_btn_1.btn_icon_url : '',
                rank_3 : video && Object.keys(video.interaction_btn_2).length ? video.interaction_btn_2.btn_icon_url : '',
            }
        },
        getInteractionText(video_id){
            let video = this.videos.find(vid => vid.id == video_id);
            return {
                rank_2 : video && Object.keys(video.interaction_btn_1).length ? video.interaction_btn_1.text : '',
                rank_3 : video && Object.keys(video.interaction_btn_2).length ? video.interaction_btn_2.text : '',
            }
        },
        getVideoName(video_id) {
            let video = this.videos.find(vid => vid.id == video_id);
            return video ? video.name : ''
        },
        gotoDetailVideo(video_id) {
            if (video_id) {
                let top_detail_url = this.$router.resolve({
                    name: 'top-detail',
                    params: { video_id },
                });
                window.open(top_detail_url.href, '_blank');
            }

        },
        handleToggle() {
            this.isOpen = !this.isOpen
        },
        getAttributeValueNameById(attribute_value_id) {
            let values = this.attributeData.values;
            let find = values.find(e => e.id == attribute_value_id);
            return find ? find.value : ''
        }
    },
    watch: {
        reportAttributes: function () {
            this.isOpen  = false;
        },
    }
}
</script>
<style lang="scss" scoped>
    .bg-when-not-interaction {
        background-color : #EBEBEB;
        border-radius: 8px;
    }
</style>