import { render, staticRenderFns } from "./ButtonOption.vue?vue&type=template&id=53a40187&scoped=true&"
import script from "./ButtonOption.vue?vue&type=script&lang=js&"
export * from "./ButtonOption.vue?vue&type=script&lang=js&"
import style0 from "./ButtonOption.vue?vue&type=style&index=0&id=53a40187&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a40187",
  null
  
)

export default component.exports