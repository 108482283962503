<template>
  <div class="d-flex align-items-center mt-7 mb-4">
    <div class="interaction">
      <div v-for="(mediaType, index) in mediaTypes" :key="index" @click="selectMediaType(mediaType)"
           :class="{'media-type-selected': selectedId === mediaType.id}">
        {{ mediaType.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mediaTypes: Array,
    selectedId: Number
  },
  methods: {
    selectMediaType(mediaType) {
      this.$emit('selectMediaType', mediaType)
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.interaction {
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 152px;
    height: 50px;
    padding: 0 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    color: #999999;
    border: 1px solid #999999;
    border-right: 0;

    &:hover {
      opacity: 0.75;
    }
  }

  div:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  div:last-child {
    border-right: 1px solid #999999;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .media-type-selected {
    color: #FFFFFF;
    background-color: #49AB94;
    border: 1px solid #49AB94;
  }
}

.video-type {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    width: 85px;

    span {
      cursor: pointer;
    }
  }

  .video-type-active {
    color: #49AB94;
    font-weight: 700;

    span {
      border-bottom: 2px solid #49AB94;
    }
  }

  .border-x {
    border-left: 1px solid #EBEBEB;
    border-right: 1px solid #EBEBEB;
  }
}
</style>
