<template>
  <div>
    <highcharts
      :id="'chart-line-' + id"
      class="mt-2"
      :options="chartOptions"
      ref="lineChartSummary"
      :constructor-type="'chart'"
    ></highcharts>
  </div>
</template>
<script>
import {Chart} from "highcharts-vue";
import {lineChartOption} from "@/commons/charts";

export default {
  props: {
    currentDateIndex: Number,
    dataReport: Object,
    id: Number,
  },
  components: {
    highcharts: Chart,
  },
  watch: {
    currentDateIndex() {
      this.initSeriesData();
    }
  },
  computed: {},
  data() {
    let chartOptions = JSON.parse(JSON.stringify(lineChartOption));

    chartOptions = Object.assign(chartOptions, {
      chart: {
        type: "line",
        borderColor: "#EBEBEB",
        borderRadius: 5,
        marginRight: 35,
        height: "100%",
      },
      tooltip: {
        useHTML: true,
        enabled: true,
        backgroundColor: "#ebebeb",
        borderRadius: "8",
        borderColor: "transparent",
        shadow: false,
        style: {
          color: "#49ab94",
          fontSize: "10px",
          lineHeight: "16px",
          fontFamily: "Noto Sans JP",
          fontWeight: "400"
        },
        hideDelay: 0,
        formatter() {
          if (this.series.name == 'total') {
            return `<span>&nbsp;${Math.round(this.y * 100) / 100}%</span>`;
          } else return false
        },
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1
            }
          },
        },
      },
      xAxis: {
        tickmarkPlacement: "on",
        minPadding: 0,
        categories: ["1d", "2d", "3d", "4d", "5d", "6d", "7d"],
        title: {
          text: "<span class='title-x-chart'>Day</span>",
          align: "high",
          offset: 0,
          x: 35,
          y: 8.5,
          useHTML: true,
          style: {
            color: "#999999",
            fontSize: window.innerWidth >= 1400 ? "11px" : "8px",
            fontFamily: "Avenir Next",
          }
        },
        labels: {
          style: {
            color: "#999999",
            fontSize: window.innerWidth >= 1400 ? "11px" : "8px",
            fontFamily: "Avenir Next",
          }
        },
        gridLineWidth: 1,
        lineWidth: 0,
        min: 0,
        max: 6
      },
      yAxis: {
        lineWidth: 0,
        gridLineWidth: 0,
        tickInterval: 20,
        startOnTick: false,
        endOnTick: false,
        min: -0.5,
        max: 100.5,
        title: {
          text: ""
        },
        labels: {
          formatter: function () {
            if (this.value != 0) {
              return this.value + '%';
            }
          },
          style: {
            color: "#999999",
            fontSize: window.innerWidth >= 1400 ? "11px" : "8px",
            fontFamily: "Avenir Next",
          },
        },
      },
    })

    return {
      chart: null,
      chartOptions: {
        ...chartOptions,
      },
    };
  },
  methods: {
    initSeriesData() {
      this.chartOptions.series = JSON.parse(JSON.stringify([
        {
          name: 'total',
          type: "line",
          pointPlacement: 'on',
          data: this.formatDataWithDateIndex(this.currentDateIndex, this.dataReport.report),
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false
              },
            }
          },
          color: "#49AB94"
        }
      ]));
      this.chartOptions.xAxis.categories = this.formatLabelWithDateIndex(this.currentDateIndex)
    },
    formatDataWithDateIndex(index, data) {
      let new_data = [];
      for (let i = 0; i < 7; i++) {
        new_data[i] = data[index + i]
      }
      return new_data;
    },
    formatLabelWithDateIndex(index) {
      let new_label = [];
      for (let i = 0; i < 7; i++) {
        new_label[i] = (index + i + 1) + 'd'
      }
      return new_label;
    }
  },
  mounted() {
    this.chart = this.$refs.lineChartSummary.chart;
    this.chart.redraw();
  },
  created() {
    this.initSeriesData();
  },
};
</script>
<style lang="scss">
.label-inline-chart {
  position: absolute;
  color: #FFFFFF;
  background-color: #999999;
  border-radius: 2px;
  padding: 1px;
  top: -7px;
  left: -25px;
  font-weight: 500;
}

@media screen and (max-width: 1400px) {
  .label-inline-chart {
    font-size: 8px;
    left: -15px;
  }
}
</style>
