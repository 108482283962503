<template>
  <div class="group-tag-list">
    <template v-for="project in projects">
      <div
        class="tag tag-one"
        :class="{ active: projectId == project.id }"
        :key="'project-' + project.id"
        @click="selectProject(project)"
      >
        <div class="text-center">
          <template v-if="showProjectType">
            <p v-if="project.panel_type === 1 && project.lite_flg === 1" class="m-0">LITE</p>
            <p v-else-if="project.panel_type === 1 && project.lite_flg === 0" class="m-0">BASIC</p>
            <p v-else-if="project.panel_type === 2" class="m-0">オリジナルパネル</p>
          </template>
          {{ project.name }}
        </div>
      </div>
    </template>
    <button v-if="hasAddBtn" class="btn btn-add"></button>
  </div>
</template>

<script>
export default {
  props: {
    projects: {
      type: Array,
    },
    hasAddBtn: {
      type: Boolean,
      default: false,
    },
    selected: Number,
    showProjectType : {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      projectIdSelected: null,
    };
  },
  methods: {
    selectProject(project) {
      let projectId = project.id;
      this.$emit("selectProject", project);
      this.projectIdSelected = projectId;
    },
  },
  computed: {
    projectId() {
      return this.projectIdSelected;
    },
  },
  watch: {
    projects: function(projects) {
      this.projectIdSelected = projects.length > 0 ? projects[projects.length - 1].id : null;
    },
    selected: function() {
      this.projectIdSelected = this.selected
    }
  },
};
</script>
<style lang="scss" scoped>
.group-tag-list {
  display: flex;
  flex-wrap: wrap;
  .tag,
  .btn-add {
    margin-right: 10px;
    margin-top: 10px;
  }
}
</style>
