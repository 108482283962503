export default {
    state: {
        lists: [], // list segment attribute,
    },
    getters: {
        getAttributes: state => state.lists,
        getAttributesActive: state => state.lists.filter(e => e.status)
    },
    mutations: {
        setAttributes(state, attributes) {
            state.lists = attributes;
        },

    },
    actions: {}
}