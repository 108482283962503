<template>
    <div class="labels-list mt-8 pb-2"> 
            <div class="table-labels table-wrapper">
                <!-- <div class="loader" v-if="isLoading"></div> -->
                <table id="tabledata" class="table table-scroll">
                    <colgroup >
                        <col class="table-col" style="width : 250px"/>
                        <col style="width : 250px" class="table-col" v-for="video,index in listVideo" :key="'colgroup-'+index"/>
                    </colgroup>
                    <tr >
                        <th></th>
                        <th v-for="video,index in listVideo" :key="'video-name-'+index" :style="[selectedVideo(video.id) ? styleTable : {}]" style="text-align : start;position: relative">
                            {{video.name}}
                            <div class="button-action" v-if="index == 0 && projectNotes.length == 0 && isFirst == false">
                                <i class="icon icon-table-add mr-2 ml-2" @click="createProjectItemNote()"></i>
                                <i class="icon icon-table-minius mr-2 disabled" style="opacity : 0.3" ></i>
                                <i class="icon icon-table-add mr-2 ml-2" @click="createProjectItemNote()"></i>
                            </div>
                        </th>
                        
                    </tr>
                    <tr v-if="projectNotes.length == 0 && isFirst == true" :key="key">
                         <td class="col-td-first col-note input-title">
                            <textarea @input="setHeight($event)" class="table-textarea input-title" type="text" @change="addProjectNote($event)"></textarea>
                        </td>
                        <td v-for="video,index in listVideo" :key="'content-'+index" :style="[selectedVideo(video.id) ? styleTable : {}]"  class="col-note" @mouseenter="setPositionAction(video.id)">
                            <textarea @input="setHeight($event)" class="table-textarea input-content" type="text" @change="addVideoNote($event,video.id)"></textarea>
                            <div class="button-action" v-if="video.id == videoMouseEnterId">
                                <i class="icon icon-table-add mr-2 ml-2 disabled" style="opacity : 0.3"></i>
                                <i class="icon icon-table-minius mr-2" @click="openModalConfirm()"></i>
                                <i class="icon icon-table-add mr-2 ml-2 disabled" style="opacity : 0.3"></i>

                            </div>
                        </td>
                    </tr>
                    <tr v-for="projectNote,idx in projectNotes" :key="'project-item-'+projectNote.id">
                        <td class="col-td-first col-note input-title">
                            <textarea @input="setHeight($event)" class="table-textarea input-title" type="text" v-model="projectNote.title" @change="editProjectNote($event,projectNote.id)"></textarea>
                            <span class="tooltiptext" v-if="projectNote && projectNote.title">最終編集者：{{ projectNote.edited_by.name }}</span>

                        </td>
                        <td v-for="video,index in listVideo" :key="'content-'+index" :style="[selectedVideo(video.id) ? styleTable : {}]"  class="col-note" @mouseenter="setPositionAction(video.id)">
                            <textarea  @input="setHeight($event)" class="table-textarea input-content" type="text" :value="getVideoContent(video.id, projectNote.id)" @change="editVideoContent($event,projectNote.id, video.id, getVideoNoteId(video.id, projectNote.id))"></textarea>
                            <span class="tooltiptext" v-if="getUpdatedUserName(video.id, projectNote.id)">最終編集者：{{ getUpdatedUserName(video.id, projectNote.id) }}</span>
                            <div class="button-action" v-if="idx + 1 == projectNotes.length && video.id == videoMouseEnterId">
                                <i class="icon icon-table-add mr-2 ml-2" @click="createProjectItemNote()"></i>
                                <i class="icon icon-table-minius mr-2" @click="openModalConfirm()"></i>
                                <i class="icon icon-table-add mr-2 ml-2" @click="createProjectItemNote()"></i>

                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="virtual-scrollbar" v-show="listVideo.length > 3">
                <input type="range" min="1" max="100" value="1" class="slider" id="scrollBarTable">
                <div class="slide-after"></div>
            </div>
            <modal-confirm  v-if="isShowModalConfirm" :message="modalMessage" @confirm="removeProjectNote()"  @closeModalConfirm="isShowModalConfirm = false"></modal-confirm>
        </div>
</template>
<script>
const jQuery = require('jquery')
import ModalConfirm from "./../../components/Modal/ModalConfirm.vue"
export default {
    props : {
        listVideo : Array,
        checkedVideos : Array,
        dataReport : Object
    },
    components: {
        ModalConfirm
    },
    watch: {
        dataReport : function(data){
            if(data) {
                jQuery("#scrollBarTable").val(1);
                jQuery(".table-wrapper").scrollLeft(0)
                this.getProjectItemNote()
            }
        },
        projectNotes : function() {
            this.$nextTick(()=>{
                let textArea = jQuery('.table-textarea')
                textArea.each(function() {
                    if ((this.scrollHeight - jQuery(this).height()) > 4) {
                        let height = this.scrollHeight;
                        this.style.height = height + 'px';
                    } 
                })
            })
        },
        listVideo(data){
            if(data.length) {
                this.videoMouseEnterId = data[0].id;
            }
            this.$nextTick(()=>{
                let colWidth = jQuery('.table-labels').width()/4.03;
                let textArea = jQuery('.table-textarea');

                jQuery('.table-col').each(function() {
                    jQuery(this).css('width',`${colWidth}px`)    
                });
                textArea.each(function() {
                    if ((this.scrollHeight - jQuery(this).height()) > 4) {
                        let height = this.scrollHeight;
                        this.style.height = height + 'px';

                    } 
                });
            })
            this.isFirst = true;
        }
    },
    data(){
        let videoMouseEnterId = this.listVideo.length ? this.listVideo[0].id : null;
        return {
            styleTable : {
                borderColor : '#49AB94',
                color : '#49AB94',
                borderLeft : '1px solid #49AB94',

            },
            projectNotes : [],
            currentUser: this.$store.getters.currentUser,
            projectNoteCurrentId : null,
            videoNoteCurrentId : null,
            videoCurrentId : null,
            title : "",
            content : "",
            status : 1,
            isShowModalConfirm : false,
            modalMessage : '行を削除すると、入力している内容が消えますがよろしいでしょうか？',
            videoMouseEnterId ,
            isInput : false,
            key : 0,
            isFirst : true
        }
    },
    methods :{
       
        setPositionAction(videoId){
            if(this.isInput == false) {
                this.videoMouseEnterId = videoId
            }
        },
        setHeight(e){
                this.isInput = true;
                if ((e.target.scrollHeight - jQuery(e.target).height()) > 4) {
                let height = e.target.scrollHeight;
                e.target.style.height = height + 'px';
            }
        },
        selectedVideo(videoId){
            if (this.$props.checkedVideos.length) {
                let result = this.$props.checkedVideos.find(item => item.id == videoId);
                if (result && Object.keys(result).length) return true;
                else return false;
            } else return false
            
        },
        getVideoContent(videoId, projectNoteId){
            let projectNote = this.projectNotes.find(item=> projectNoteId == item.id);
            let videoNotes = projectNote.videos_note;
           
            if (videoNotes.length) {
                let value = videoNotes.find(item => item.video_id == videoId);
                if (value) {
                    return value.content
                } else {
                    return ""
                }
            } else {
                return ""
            }
        },
        getUpdatedUserName(videoId, projectNoteId) {
            let projectNote = this.projectNotes.find(item=> projectNoteId == item.id);
            let videoNotes = projectNote.videos_note;
           
            if (videoNotes.length) {
                let value = videoNotes.find(item => item.video_id == videoId);
                if (value) {
                    return value.edited_by.name
                } else {
                    return ""
                }
            } else {
                return ""
            }
        },
        getVideoNoteId(videoId, projectNoteId){
            let projectNote = this.projectNotes.find(item=> projectNoteId == item.id);
            let videoNotes = projectNote.videos_note;
           
            if (videoNotes.length) {
                let value = videoNotes.find(item => item.video_id == videoId);
                if (value) {
                    return value.id
                } else {
                    return null
                }
            } else {
                return null
            }
        }, 
        editProjectNote(e,id){
            this.projectNoteCurrentId = id;
            this.title = e.target.value;
            this.status = 1;
            this.isInput = false;
            if(this.checkXss(this.title)) {
                this.updateProjectItemNote();
            } else {
                this.title = "";
                this.$toasted.error("タイトルに「<>&\";'」の文字は使用できません");
                this.getProjectItemNote();
                return;
            }
          
            
        },
        editVideoContent(e,projectNoteId, videoId, videoNoteId) {
            this.content = e.target.value;
            this.projectNoteCurrentId = projectNoteId;
            this.videoCurrentId = videoId;
            this.videoNoteCurrentId = videoNoteId;
            this.status = 1;
            this.isInput = false;
            if(this.checkXss(this.content)) {
                if (videoNoteId) {
                    this.updateVideoNote()
                } else {
                    this.createVideoNote()
                }
            } else {
                this.content = "";
                this.$toasted.error("内容に「<>&\";'」の文字は使用できません");
                this.getProjectItemNote();
                return;
            }
            
        },
        removeProjectNote(){
            this.projectNoteCurrentId = this.projectNotes[this.projectNotes.length - 1].id;
            this.status = 0;    
            this.updateProjectItemNote();
            this.$emit("closeModal");
            this.isShowModalConfirm = false;
            
        },
        openModalConfirm(){
            this.isFirst = false;
            if( this.projectNotes.length) {
                let projectNoteCurrent = this.projectNotes[this.projectNotes.length - 1];
                let title = projectNoteCurrent.title;
                let videosNote = projectNoteCurrent.videos_note;
                if (videosNote.length) {
                    let result = videosNote.filter(item => item.content.length > 0);
                    if (!title.length && !result.length) {
                        this.removeProjectNote();
                    } else {
                        this.isShowModalConfirm = true
                    }
                } else {
                    if (!title.length) {
                        this.removeProjectNote();
                    } else {
                        this.isShowModalConfirm = true
                    }
                }
                
            }
        },
        checkXss(content){
            if(content.length) {
                let re = /^[^<>&";']+$/;
                return re.test(content)
            } else {
                return true
            }
            
        },
        addProjectNote(e){
            let value = e.target.value;
            this.title = value;
            if(this.checkXss(this.title)) {
                this.createProjectItemNote();
            } else {
                this.$toasted.error("タイトルに「<>&\";'」の文字は使用できません");
                this.title = "";
                this.key ++;
                return;
            }
        },
        addVideoNote(e, videoId){
            let value = e.target.value;
            this.videoCurrentId = videoId;
            this.content = value;
            if ( this.checkXss(this.content) ){
                this.createProjectNoteAndCreateVideoNote();
            } else {
                this.$toasted.error("内容に「<>&\";'」の文字は使用できません");
                this.content = "";
                this.key ++;
                return;
            }
        },
        
        // api
        getProjectItemNote(){
            let url = this.$router.resolve({
                name: "api.project-item-note",
            }).href;
            let params = {
                project_id : this.$props.dataReport.ad_id
            }
            this.$http2.get(url, params, (data) => {
                let result = data.items;
                this.projectNotes = result;
            });
        },
        createProjectItemNote(){
            let url = this.$router.resolve({
                name: "api.project-item-note.create",
            }).href;
            let params = {
                project_id : this.$props.dataReport.ad_id,
                title : this.title,
                status : 1
            }
            this.$http2.post(url, params, () => {
                this.title = "";
                this.getProjectItemNote();
            },
            ()=>{
            });
        },
        updateProjectItemNote(){
            let query = {
                id : this.projectNoteCurrentId
            }
            let url = this.$router.resolve({
                name: "api.project-item-note.update",
                params : query
            }).href;
            let params = {
                project_id : this.$props.dataReport.ad_id,
                title : this.title,
                status : this.status
            }
            this.$http2.put(url, params, () => {
                this.title = "";
                this.getProjectItemNote()
            },
            ()=>{
                this.getProjectItemNote();

            });
        },
        createVideoNote(){
            let url = this.$router.resolve({
                name: "api.video-content-note.create",
            }).href;
            let params = {
                video_id : this.videoCurrentId,
                content : this.content,
                status : 1,
                project_item_note_id : this.projectNoteCurrentId
            }
            this.$http2.post(url, params, () => {
                this.getProjectItemNote()
            },
            ()=>{
                this.getProjectItemNote();

            });
        },
        updateVideoNote(){
            let query = {
                id : this.videoNoteCurrentId
            }
            let url = this.$router.resolve({
                name: "api.video-content-note.update",
                params : query
            }).href;
            let params = {
                video_id : this.videoCurrentId,
                content : this.content,
                status : this.status,
                project_item_note_id : this.projectNoteCurrentId
            }
            this.$http2.put(url, params, () => {
                this.getProjectItemNote()
            },
            ()=>{
                this.getProjectItemNote();

            });
        },
        createProjectNoteAndCreateVideoNote(){
            let url = this.$router.resolve({
                name: "api.project-item-note.create",
            }).href;
            let params = {
                project_id : this.$props.dataReport.ad_id,
                title : "",
                status : 1
            }
            this.$http2.post(url, params, () => {
                let url = this.$router.resolve({
                name: "api.project-item-note",
                }).href;
                let params = {
                    project_id : this.$props.dataReport.ad_id
                }
                this.$http2.get(url, params, (data) => {
                    let result = data.items;
                    this.projectNotes = result;
                    this.projectNoteCurrentId = this.projectNotes.length ? this.projectNotes[0].id : null;
                    this.createVideoNote();
                });
            },
            ()=>{
            });
        }
    },
    mounted(){
        jQuery('#scrollBarTable').on('change', function (e) {
            let val = e.target.value;
            let toX = val > 1 ? val/100*jQuery(".table-scroll").width() : 0;
            jQuery(".table-wrapper").scrollLeft(toX)
            //    console.log(val, $(".table-scroll").width())
        })
        let colWidth = jQuery('.table-labels').width()/4.03;
        jQuery('.table-col').each(function() {
            jQuery(this).css('width',`${colWidth}px`)    
        })
    },
}
</script>
<style lang="scss" scoped>
    .col-td-first {
        background-color: #EBEBEB;
    }
    .button-action {
        // visibility: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 20px;
        position:absolute;
        bottom : -20px;
        left : 0;
        background-color: #999999;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

.virtual-scrollbar {
  display: flex;
  align-items: center;
  height: 25px;
  width: 100%;
}
.virtual-scrollbar .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  background: #999999;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.virtual-scrollbar .slide-after {
  width: 5px;
  height: 5px;
  border-radius: 2px;
  background-color: #999999;
  display: block;
  margin-left: -2px;
}
.virtual-scrollbar .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 60px;
  height: 20px;
  background-image: url("/images/svg/icon_scrollbar_thumb.svg");
  cursor: pointer;
}
.virtual-scrollbar .slider::-moz-range-thumb {
  width: 60px;
  height: 20px;
  background-image: url("/images/svg/icon_scrollbar_thumb.svg");
  cursor: pointer;
}

</style>