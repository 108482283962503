<template>
  <div class="time-max-interaction mt-8">
    <div class="row-2">
      <div class="row-2-col-1" v-for="(value, key) in data" :key="key">
        <div class="card card-max-interaction w-100" v-if="value.video_id && value.interaction_icon">
          <div class="content">
            <div class="title mb-3">
              <div class="left">
                <img style="margin-right : 8px; width : 24px" :src="value.interaction_icon" alt="">
                {{ value.interaction_text }}の最大値カット
              </div>
              <div class="right">
                {{
                  value.interaction_count | numberWithCommas | addPointZero
                }}%
              </div>
            </div>
            <div class="body">
              <img class="card-image" :src="value.image_url" />
            </div>
            <div class="bottom">
              <div class="left">
                {{ value.video_name }}
              </div>
              <span class="right"> {{ value.interaction_at }}s </span>
            </div>
          </div>
          <router-link class="right-card" :to="{ name: 'top-detail', params: { video_id: value.video_id }}" target="_blank">
            <i class="icon icon-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getReportInteractionTop
} from '@/commons/api_service'
import {
  getImageSecond,
  roundPercent
} from '@/commons/helpers'
import {getUrlQuery} from "../../commons/helpers";
export default {
    props: {
        // adId: Number,
        // project : Object

    },
    computed : {
      // iconInteraction() {
      //   return {
      //     rank2 : this.project.interaction_btn_1 && Object.keys(this.project.interaction_btn_1).length ? this.project.interaction_btn_1.btn_icon_url : '',
      //     rank3 : this.project.interaction_btn_2 && Object.keys(this.project.interaction_btn_2).length ? this.project.interaction_btn_2.btn_icon_url : '',
      //   }
      // },
      // textInteraction() {
      //   return {
      //     rank2 : this.project.interaction_btn_1 && Object.keys(this.project.interaction_btn_1).length ? this.project.interaction_btn_1.text : '',
      //     rank3 : this.project.interaction_btn_2 && Object.keys(this.project.interaction_btn_2).length ? this.project.interaction_btn_2.text : '',
      //   }
      // },
    },
    data() {
        return {
          data: {}
        }
    },
    watch: {
      // adId: function() {
      //   this.initData();
      // }
    },
    methods: {
      initData() {
        let {video_ids} = getUrlQuery()
        if (video_ids) {
          getReportInteractionTop({videoIds: video_ids, interactionTally : 1}, (data) => {
            this.data = {}
            if (data['2_topvideo']) {
              this.data.rank2 = data['2_topvideo']
              this.data.rank2.image_url = getImageSecond(data['2_topvideo'].image_data, data['2_topvideo'].interaction_at)
              this.data.rank2.interaction_count = roundPercent(data['2_topvideo'].interaction_count)
              let rank2Video = this.$store.getters.getVideosListInReportTop.find(e => e.id == data['2_topvideo'].video_id)
              this.data.rank2.interaction_text = rank2Video && rank2Video.interaction_btn_1 && Object.keys(rank2Video.interaction_btn_1).length
                ? rank2Video.interaction_btn_1.text
                : ''
              this.data.rank2.interaction_icon = rank2Video && rank2Video.interaction_btn_1 && Object.keys(rank2Video.interaction_btn_1).length
                ? rank2Video.interaction_btn_1.btn_icon_url
                : ''
            }
            if (data['3_topvideo']) {
              this.data.rank3 = data['3_topvideo']
              this.data.rank3.image_url = getImageSecond(data['3_topvideo'].image_data, data['3_topvideo'].interaction_at)
              this.data.rank3.interaction_count = roundPercent(data['3_topvideo'].interaction_count)
              let rank3Video = this.$store.getters.getVideosListInReportTop.find(e => e.id == data['3_topvideo'].video_id)
              this.data.rank3.interaction_text = rank3Video && rank3Video.interaction_btn_2 && Object.keys(rank3Video.interaction_btn_2).length
                ? rank3Video.interaction_btn_2.text
                : ''
              this.data.rank3.interaction_icon = rank3Video && rank3Video.interaction_btn_2 && Object.keys(rank3Video.interaction_btn_2).length
                ? rank3Video.interaction_btn_2.btn_icon_url
                : ''
            }
          })
        } else {
          this.data = {}
        }

      }
    },
    mounted() {
      // if (this.adId) {
      //   this.initData();
      // }
      this.initData()
    },
};
</script>
<style lang="">
</style>