<template>
  <div
    class="modal add-segment-selected modal-show"
    v-show="isShown"
    ref="modal"
  >
    <div class="modal-content">
      <div class="header">
        <div class="btn-close">
          <i class="icon icon-close" @click="closeModal()"></i>
        </div>
      </div>
      <div class="body">
        <div class="list-segment">
          <div class="label mb-2 d-flex justify-content-between w-100">
            <span class="no-wrap">{{ $t("texts.project.custom_segment") }}</span>
            <span v-if="playAdsLITESegment" class="ml-8 fs-m">{{ $t("texts.project.play_ads_lite_segment") }}</span>
          </div>
          <div class="list-segment-selected"
               :class="{'has-error': isSettingError || errorSelectAttribute}"
               v-if="segmentSelecteds.length"
          >
            <div
              class="segment-selected"
              v-for="(item, index) in segmentSelecteds"
              :key="'item-' + index"
            >
              <div class="name">{{ item.name }}</div>
              <div class="attribute">
                <div
                  class="value"
                  v-for="data in item.list"
                  :key="'attribute-value-' + data.id"
                >
                  {{ data.value }}
                  <i
                    class="icon icon-close-small"
                    @click="removeItem({ data: data, index: index })"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <p class="error error-message" v-if="isSettingError">
              {{
              $t("messages.setting_segment_error")
            }}
          </p>
          <p class="error error-message" v-if="errorSelectAttribute" v-html="errorSelectAttribute">
          </p>

          <div
            class="mt-3"
            v-for="category in categories"
            :key="'category' + category.id"
          >
            <div
              class="segment-attritute"
              :class="{ active: expains[category.id] }"
            >
              <div class="name-attritute" @click="toggleSegments(category.id)">
                {{ category.name }}
              </div>
              <i class="icon icon-arrow-bottom"></i>
              <i class="icon icon-arrow-top"></i>
              <div class="attritute-value">
                <div class="list-attritute">
                  <div
                    class="attribute-name"
                    v-for="segment in category.attributes"
                    :key="'attribute-value-' + segment.id"
                    @click="showForm(segment)"
                    :class="{disabled: segment.is_disabled}"
                  >
                    {{ segment.name }}
                    <i class="icon icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-submit">
          <button
            class="btn btn-primary button-download-pdf"
            :disabled="!segmentSelecteds.length || isSettingError"
            @click="settingAndOr"
          >
            AND OR設定
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "../../commons/event_bus";

export default {
  props: {
    categories: Array,
    attributes: Array,
    segmentArr: Array,
    isShown: Boolean,
    prevSegmentSelected: Array,
    playAdsLITESegment: {
      type: Boolean,
      default: false
    },
    isSettingError: {
      type: Boolean,
      default: false
    },
    errorSelectAttribute: {
      type: [String, null],
      default: null
    }
  },
  components: {},
  data() {
    return {
      attribute: {},
      showMultipleForm: false,
      showSingleForm: false,
      segmentSelecteds: [],
      expains: {},
    };
  },
  methods: {
    closeModal() {
      this.segmentSelecteds = JSON.parse(
        JSON.stringify(this.prevSegmentSelected)
      );
      EventBus.$emit("toggleModal", "");
    },
    toggleSegments(cateId) {
      this.$set(this.expains, cateId, !this.expains[cateId]);
    },
    showForm(segment) {
      // if disabled return
      if (segment.is_disabled) return
      let TYPE_MULTIPLE = 2;
      let TYPE_SINGLE = 1;
      this.attribute = segment;
      let attributeValues = segment.values;
      let found = attributeValues.find((item) => item.value.includes("->"));

      if (found) {
        this.$emit("openFormValue", { type: TYPE_MULTIPLE, data: segment });
      } else {
        this.$emit("openFormValue", { type: TYPE_SINGLE, data: segment });
      }
    },
    removeItem(data) {
      let datas = data.data;
      let index = data.index;
      this.segmentSelecteds[index].list = this.segmentSelecteds[
        index
      ].list.filter((item) => item.id != datas.id);
      if (this.segmentSelecteds[index].list.length == 0) {
        this.segmentSelecteds.splice(index, 1);
      }
      this.$emit('updateSegmentSelected', this.segmentSelecteds)
    },
    settingAndOr() {
      this.$emit("settingAndOrSegment");
    },
    sortAttribute(data) {
      return data.sort((a, b) => a.id - b.id);
    },
  },
  watch: {
    segmentArr: function (data) {
      this.segmentSelecteds = data.map((item) => {
        return {
          category: item.category,
          id: item.id,
          list: this.sortAttribute(item.list),
          name: item.name,
        };
      });
    },
    isShown: function () {
      this.$refs.modal.scrollTop = 0;
      this.$set(this, "expains", {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_project.scss";

.no-wrap {
  white-space: nowrap;
}

.error-message {
  font-size: 12px;
}

.attribute-name {
  &.disabled {
    opacity: 0.5;
  }
}
</style>