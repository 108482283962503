<template>
    <div class="page-wrapper-api">
        <div class="content-youtube-api">
            <div>
                PlayAdsとYouTubeを連携するとYouTubeの視聴率とPlayAdsのリアクションを合わせて確認することができます。<br>
                リンクを押下してYouTubeAPIの使用を許可してください。（Googleの画面に遷移します。）
            </div>
            <div class="doc-small mt-2">
                ※
                PlayAdsで表示しているYouTube視聴者維持率は公開日より後のものを使用しています。<br>
                動画公開前に再生された分の視聴率は表示されませんのでご了承ください。
            </div>
            <div class="text-link mt-4" @click="redirectYoutubeIntegration">
                YouTubeAPIの利用を許可する
            </div>
            <div class="form mt-10">
                <div class="form-title mb-4">YouTube連携の解除方法</div>
                <div class="form-content">
                    <div class="number-serial">
                        <span>①</span><span>②</span><span>③</span>
                    </div>
                    <div>
                        まず、YouTube連携を解除されるアカウントでGoogleにログインしてください。<br />
                        Googleの【アカウント管理】画面より、【セキュリティ】をお選びください。<br />
                        【アカウントにアクセスできるサードパーティ
                        アプリ】から『PlayAds』の削除をお願いいたします。
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import router from '../../routes';
import http2 from '../../commons/http2'

export default {
    name: 'IntegrateYoutubeRegister',
    methods: {
        redirectYoutubeIntegration: function(e) {
            e.preventDefault();
            let url = router.resolve({
                name: 'api.youtube.authorization'
            }).href;
            http2.get(url, {}, (data) => {
                if (data.authorization_url) {
                    window.open(data.authorization_url, '_self');
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_integrate-youtube.scss';
</style>