<template>
  <div class="home-compare-movie-form-contain">
    <div class="form-group large flex flex-col mb-0">
      <label>比較する動画を選択</label>
      <div class="form-group sub-form-group large flex flex-col">
        <label class="sub-label mt-2">1つ目の比較対象</label>
        <div class="mb-1">
          <Select
            :data="firstClientsList"
            :idSelected="firstClientId"
            :isSelected="isSelectedFirstClient"
            @select="selectFirstClient"
            :element="'firstClientsList'"
          ></Select>
        </div>
        <RadioList
          :radioList="firstComparisonTargetList"
          :idSelected="firstComparisonTargetSelectedId"
          :keyName="'firstComparisonTarget'"
          @changeIdSelected="changeFirstComparisonTarget($event)"
        ></RadioList>
      </div>
      <div class="form-group sub-form-group large flex flex-col">
        <label class="sub-label">2つ目の比較対象</label>
        <div class="mb-1">
          <Select
            :data="secondClientsList"
            :idSelected="secondClientId"
            :isSelected="isSelectedSecondClient"
            @select="selectSecondClient"
            :element="'secondClientsList'"
          ></Select>
        </div>
        <RadioList
          :radioList="secondComparisonTargetList"
          :idSelected="secondComparisonTargetSelectedId"
          :keyName="'secondComparisonTarget'"
          @changeIdSelected="changeSecondComparisonTarget($event)"
        ></RadioList>
      </div>
    </div>
  </div>
</template>
<script>
import Select from "@/components/Video/Select.vue";
import RadioList from "@/components/Top/HomeCompare/RadioList.vue"
import {required} from "vuelidate/lib/validators";

export default {
  props: {

  },
  components: {
    Select,
    RadioList,
  },
  data() {
    return {
      firstClientId: 1,
      isSelectedFirstClient: true,
      firstClientsList: [
        {
          id: 1,
          name: 'Client 1'
        },
        {
          id: 2,
          name: 'Client 2'
        },
        {
          id: 3,
          name: 'Client 3'
        },
        {
          id: 4,
          name: 'Client 4'
        },
        {
          id: 5,
          name: 'Client 5'
        }
      ],
      firstComparisonTargetSelectedId: null,
      firstComparisonTargetList: [
        {
          value: 1,
          text: "スマイルゼミ_TVCM『おうち篇』",
        },
        {
          value: 2,
          text: "shozemi",
        },
        {
          value: 3,
          text: "0611_Benesse_offline_15s",
        },
        {
          value: 4,
          text: "Z会_TVCM",
        },
        {
          value: 5,
          text: "スマイルゼミ_TVCM",
        },
        {
          value: 6,
          text: "動画A",
        },
        {
          value: 7,
          text: "動画B",
        },
      ],
      secondClientId: 1,
      isSelectedSecondClient: true,
      secondClientsList: [
        {
          id: 1,
          name: 'Client 1'
        },
        {
          id: 2,
          name: 'Client 2'
        },
        {
          id: 3,
          name: 'Client 3'
        },
        {
          id: 4,
          name: 'Client 4'
        },
        {
          id: 5,
          name: 'Client 5'
        }
      ],
      secondComparisonTargetSelectedId: null,
      secondComparisonTargetList: [
        {
          value: 1,
          text: "スマイルゼミ_TVCM『おうち篇』",
        },
        {
          value: 2,
          text: "shozemi",
        },
        {
          value: 3,
          text: "0611_Benesse_offline_15s",
        },
        {
          value: 4,
          text: "Z会_TVCM",
        },
        {
          value: 5,
          text: "スマイルゼミ_TVCM",
        },
        {
          value: 6,
          text: "動画A",
        },
        {
          value: 7,
          text: "動画B",
        },
      ],
    }
  },

  validations() {
    const validate = {
      firstComparisonTargetSelectedId: {
        required,
      },
      secondComparisonTargetSelectedId: {
        required,
      },
    }
    return validate
  },

  methods: {
    selectFirstClient(id) {
      console.log('id', id)
    },

    selectSecondClient(id) {
      console.log('id', id)
    },

    changeFirstComparisonTarget(newValue) {
      this.firstComparisonTargetSelectedId = newValue
      this.setDisabledButtonCompare()
    },

    changeSecondComparisonTarget(newValue) {
      this.secondComparisonTargetSelectedId = newValue
      this.setDisabledButtonCompare()
    },

    setDisabledButtonCompare() {
      this.$v.$touch()
      this.$emit('setDisabledButtonCompare', this.$v.$error)
    }
  },

  watch: {

  },

  created() {
    this.setDisabledButtonCompare()
  },

  destroyed() {

  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/root";

.home-compare-movie-form-contain {
  .sub-form-group {
    padding-left: 34px;
  }
}
</style>
