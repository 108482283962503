<template>
    <div class="login-container">
        <side-left></side-left>
        <div class="side-right">
            <div class="title-form mb-15">{{$t('texts.reset-pw.title')}}</div>
            <div class="form-group-login">
                <form @submit.prevent="handleSubmit" novalidate>
                    <div class="form-control mb-3" :class="{'error': (submitted && $v.password.$error) || errorMessage}">
                        <input class="input pl-5 pr-5" type="password"  v-model.trim="password" :placeholder="$t('placeholder.new_password')" />
                        <template v-if="submitted && $v.password.$error">
                            <span class="error error-message"  v-if="!$v.password.required">{{$t('validation.login.password.required')}}</span>
                            <span class="error error-message" v-if="$v.password.required">{{$t('validation.login.password.format_create_reset')}}</span>
                        </template>
                    </div>
                    <div class="form-control"  :class="{'error': (submitted && $v.confirm_password.$error) || errorMessage}">
                        <input class="input pl-5 pr-5" type="password"  v-model.trim="confirm_password" :placeholder="$t('placeholder.password_confirm')" />
                         <template v-if="submitted && $v.confirm_password.$error">
                            <span class="error error-message" v-if="!$v.confirm_password.sameAs">{{$t('validation.login.password.confirm')}}</span>
                        </template>
                    </div>
                    <div class="form-control button mt-5">
                        <button class="btn btn-submit btn-primary" type="submit">{{$t('texts.reset-pw.reset')}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";
import { xssCheck, checkPass } from "../../commons/rules";
import SideLeft from "../../components/Login/SideLeft.vue";
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
  name: "SettingPassword",
  components: {
      SideLeft
  },
  props: {
    isCreate: Boolean
  },
  data() {
    return {
      password: "",
      confirm_password: "",
      submitted: false,
      isLoading: false,
      errorMessage: null
    };
  },
  validations: {
    password: {
      required,
      maxLength: maxLength(16),
      minLength: minLength(8),
      checkPass,
      xssCheck
    },
    confirm_password: { required, sameAs: sameAs("password") }
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      let params, url;
      if (!this.isCreate) {
        url = this.$router.resolve({
          name: "api.reset.password"
        }).href;
        params = {
          email: this.$route.query.email,
          new_password: this.password,
          token: this.$route.query.token
        };
      } else {
        url = this.$router.resolve({
          name: "api.create.password"
        }).href;

        params = {
          gaid: Vue.$cookies.get('_ga'),
          password: this.password,
          token: this.$route.query.token
        };
      }
      this.$http2.put(
          url,
          params,
          () => {
            this.isLoading = false;
            let message = this.isCreate ? this.$t("messages.create_password_success") : this.$t("messages.reset_password_success")
            this.$toasted.success(message, {duration: 5000})
            this.$router.push({ name: "login" });
          },
          () => {
            this.isLoading = false;
          }
        );

    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_login.scss"; 
</style>

