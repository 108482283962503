<template>
  <div class="modal setting-average">
    <div class="modal-content" style="width : 594px">
      <div class="header">
        <div class="btn-close" @click="closeModal">
          <i class="icon icon-close"></i>
        </div>
        <div class="modal-title">平均値設定</div>

      </div>
      <div class="body">
        <div class="description">平均値として表示するラベルを選択してください。</div>
        <div class="list-video-length mt-8">
          <div class="title">ラベル</div>
          <div class="list-item mt-2">
            <div class="mr-5 mb-2" v-for="label in labels" :key="'label-' + label.id">
              <div class="item" @click="checkedLabel(label)">
                <i class="icon icon-checkbox" :class="{ checked: label.checked }"></i>
                <div class="item-name pl-2">
                  {{ label.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-submit mt-5">
          <button :disabled="!labelSelectedValues.length" class="btn btn-primary button-download-pdf"
                  @click="settingAverage"> 設定する
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '@/commons/event_bus';

export default {
  props: {
    buyerId: Number,
    labels: Array,
    labelsSelected: Array,
    isShow: Boolean,
  },
  watch: {
    isShow: function () {
      this.labelValues = [...this.labelsSelected]
      this.labelSelectedValues = [...this.labelsSelected]
      this.updateCheckingLabel()
    },
  },
  data() {
    return {
      isSetting: false,
      labelValues: [],
      labelSelectedValues: [],
    }
  },
  methods: {
    closeModal() {
      this.labelSelectedValues = [...this.labelValues]
      this.updateCheckingLabel()
      this.$emit('closeModal')
    },
    checkingLabel(id) {
      let result;
      result = this.labelSelectedValues.find(item => item.id == id);
      return !!result;
    },
    checkedLabel(label) {
      this.$set(label, "checked", !label.checked);
      let index = this.labelSelectedValues.findIndex((e) => e.id == label.id);
      if (label.checked) {
        this.labelSelectedValues.push(label)
      } else {
        this.labelSelectedValues.splice(index, 1);
      }
    },
    updateCheckingLabel() {
      let checkingLabel;
      this.labels.forEach(item => {
        checkingLabel = this.checkingLabel(item.id);
        if (checkingLabel) {
          this.$set(item, "checked", true)
        } else {
          this.$set(item, "checked", false)
        }
      })
    },
    // api
    settingAverage() {
      this.isSetting = true;
      this.labelValues = [...this.labelSelectedValues]
      EventBus.$emit("updateLabelSelected", this.labelSelectedValues)
      this.$emit('closeModal');
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/_setting_average_modal.scss';
</style>