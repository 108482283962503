<template>
  <div class="row">
    <div class="col-1 col-lg-1"></div>
    <div class="col-11 col-lg-11 mr-0 outline-area">
      <client-list
        v-if="$store.getters.isAdmin"
        :clients="clients"
        @select="updateBuyerId"
        class="mb-2"
        :selected="params.buyer_id"
      ></client-list>
      <div class="table-show-search">
        <div class="show-total">
          <div class="show">
            <div class="label">案件数</div>
            <div class="number">{{ segmentsCount }}</div>
          </div>
          <div
            class="btn btn-download btn-primary-outline mr-2"
            @click="downloadCsv"
            v-if="!$store.getters.isAdmin"
          >
            <i class="icon icon-small-download mr-1"></i>
            <div>ダウンロード</div>
          </div>
          <div
            class="btn btn-upload btn-primary-outline"
            @click="$router.push({ name: 'segment-files',query:{buyer_id : params.buyer_id} })"
          >
            <i class="icon icon-download-ellipse mr-1"></i>
            <div>アップロード状況</div>
          </div>
        </div>
        <div class="search">
          <i class="icon icon-search"></i>
          <input
            class="input input-search pl-8 pr-5"
            type="search"
            placeholder="検索"
            v-model="params.text"
          />
        </div>
      </div>
      <div class="guide-dowload" v-if="!$store.getters.isAdmin">
        <div class="guide-title">セグメントCSVの作り方</div>
        <div class="guide-content mt-4">
          <div class="guide-detail guide-1">
            <span>①</span>
            <span
              >「ダウンロード」ボタンを押して「xx.csv」をダウンロードします。(xx部分にはご自身のクライアントIDが入ります。)</span
            >
          </div>
          <div class="guide-detail guide-2">
            <span>②</span>
            <span
              >ダウンロードした『xx.csv』に動画調査を実施したい人のメールアドレスと性別・年齢をセグメント名ごとにグルーピングして記入します。
              （2行目から記入をお願いいたします。）
              セグメント名ごとに動画調査のメールを送るので、わかりやすいセグメント名にすることをお勧めします。（例：〇〇部女性）
              メールアドレスごとに年齢・性別の情報を記入します。（0：非該当、1:該当）</span
            >
          </div>
          <div class="guide-detail guide-3">
            <span>③</span>
            <span>ファイルをアップロードします。</span>
          </div>
        </div>
      </div>
      <div class="group-dowload mt-8" v-if="!$store.getters.isAdmin">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          class="mr-3"
          @vdropzone-removed-file="removeFile"
          @vdropzone-file-added="addFile"
          :options="dropzoneOptions"
          :useCustomSlot="true"
        >
          <div class="title">
            「ダウンロード」ボタンからセグメントCSVをダウンロードしてください。<br />
            セグメント情報を記入したセグメントCSVをドラッグ＆ドロップして「アップロード」ボタンを押してください。
          </div>
        </vue-dropzone>
        <div class="btn btn-upload btn-primary-outline" @click="uploadFile">
          <i class="icon icon-download mr-1"></i>
          <div>アップロード</div>
        </div>
      </div>
      <div class="record-number">表示数：{{ segmentsCount }}件</div>
      <div class="table-wrapper">
        <table class="table table-scroll">
          <tr>
            <th class="none-boder">
              <div class="icon-arrow" @click="doSort('name')">
                セグメント名
                <i
                  class="icon ml-1"
                  :class="{
                    'icon-table-arrow-bottom': isSortDesc('name'),
                    'icon-table-arrow-top': isSortAsc('name'),
                  }"
                ></i>
              </div>
            </th>
            <th class="none-boder">
              <div class="icon-arrow" @click="doSort('count')">
                登録メールアドレス件数
                <i
                  class="icon ml-1"
                  :class="{
                    'icon-table-arrow-bottom': isSortDesc('count'),
                    'icon-table-arrow-top': isSortAsc('count'),
                  }"
                ></i>
              </div>
            </th>
            <th class="none-boder w-25">
              <div class="icon-arrow">最新更新時刻</div>
            </th>
          </tr>
          <tr v-for="segment in segments" :key="segment.id">
            <td>{{ segment.name }}</td>
            <td>{{ segment.count }}</td>
            <td>{{ convertDateFormat(segment.updated_at) }}</td>
          </tr>
        </table>
      </div>
      <scroll-loader
        :loader-method="loadMoreData"
        :loader-disable="loadDisabled"
        :loader-color="'#49ab94'"
      ></scroll-loader>
    </div>
  </div>
</template>

<script>
import ClientList from "../../components/Select/ClientList.vue";
import router from "../../routes";
import http2 from "../../commons/http2";
import { listBuyersActive } from "../../commons/api_service";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

const jQuery = require("jquery");
let dropzone = null;
export default {
  name: "SegmentFilesUpload",
  components: { ClientList, vueDropzone: vue2Dropzone },
  props: {},
  data() {
    return {
      params: {
        page: 0,
        limit: 20,
        text: "",
        sort: "id",
        order: "desc",
        status: 1,
        buyer_id: 0,
      },
      clients: [],
      segmentsCount: 0,
      segments: [],
      loadDisabled: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        addRemoveLinks: true,
        acceptedFiles: ".csv",
        autoProcessQueue: false,
        thumbnailWidth: 100,
        init: function () {
          dropzone = this;
        },
      },
      file: null,
      dropzoneMax: false,
      extensionCSV: false,
      filename: "",
      isLoading: false,
    };
  },
  watch: {
    "params.buyer_id": function () {
      this.getSegments(false);
    },
    "params.text": function () {
      this.getSegments(false);
    },
  },
  methods: {
    getSegments(isContinous) {
      if (this.params.buyer_id == 0) {
        return;
      }
      this.loadDisabled = false;
      if (!isContinous) {
        this.params.page = 1;
      } else {
        this.params.page += 1;
      }
      let url = router.resolve({ name: "api.segments" }).href;
      http2.getList(
        url,
        this.params,
        (data) => {
          this.segmentsCount = data.all;
          if (!isContinous) {
            this.segments = data.items;
          } else {
            this.segments = [...this.segments, ...data.items];
          }
          if (this.params.limit > data.items.length) {
            this.loadDisabled = true;
          }
        },
        () => {
          this.loadDisabled = true;
        }
      );
    },
    loadMoreData() {
      this.getSegments(true);
    },
    updateBuyerId(selectedId) {
      this.params.buyer_id = selectedId;
      this.$router.replace({query:{buyer_id : this.params.buyer_id} })
    },
    convertDateFormat(date) {
      let parts = date.split(" ");
      let datePart = parts[0];
      let hourPart = parts[1];

      datePart = datePart.split("-").join("/");

      return [datePart, hourPart].join(" ");
    },
    doSort(sortColumn) {
      if (sortColumn == this.params.sort) {
        let currentOrder = this.params.order;
        this.params.order = currentOrder == "asc" ? "desc" : "asc";
      } else {
        this.params.sort = sortColumn;
        this.params.order = "desc";
      }
      this.getSegments(false);
    },
    isSortDesc(sortColumn) {
      return this.params.sort == sortColumn && this.params.order == "desc";
    },
    isSortAsc(sortColumn) {
      return this.params.sort == sortColumn && this.params.order == "asc";
    },
    addFile() {
      this.isLoading = false;
      jQuery(".dropzone.dz-started .dz-message").css("display", "none");
      if (jQuery("#dropzone .dz-preview").length > 1) {
        jQuery("#dropzone .dz-preview:first").remove();
      }
      this.dropzoneMax = false;
      this.extensionCSV = false;
      console.log("ahihi");
    },
    removeFile() {
      this.isLoading = false;
      jQuery(".dropzone.dz-started .dz-message").css("display", "block");
      dropzone.files = [];
      this.dropzoneMax = false;
      this.extensionCSV = false;
    },
    downloadCsv() {
      let url = router.resolve({ name: "api.segment-files.download" }).href;
      http2.downloadCSV(
        url,
        { filename: `${this.$store.getters.currentUser.owner_id}.csv` },
        () => {}
      );
    },
    uploadFile() {
      if (dropzone.files.length > 0) {
        this.dropzoneMax = false;
        this.extensionCSV = false;
        let file = dropzone.files.slice(-1)[0];
        if (
          file.name.substr(file.name.length - 3, file.name.length - 1) !== "csv"
        ) {
          this.extensionCSV = true;
        } else if (file.size / (1024 * 1024) > 6) {
          this.dropzoneMax = true;
        } else {
          this.isLoading = true;
          let url = router.resolve({
            name: "api.segment-files.upload-url",
          }).href;
          this.$http2.get(url, {}, (data) => {
            let s3Url = data.upload_url;
            http2.uploadFileS3(s3Url, file, () => {
              let params = { filename: data.file_name };
              console.log(params);
              url = router.resolve({ name: "api.segment-files" }).href;
              this.$http2.post(
                url,
                params,
                () => {
                  file = null;
                  dropzone.files = [];
                  jQuery("#dropzone .dz-preview:first").remove();
                  jQuery(".dropzone.dz-started .dz-message").css(
                    "display",
                    "block"
                  );
                  this.$toasted.success(
                    this.$t("messages.upload_segment_success")
                  );
                  this.isLoading = false;
                },
                () => {
                  this.$toasted.error(this.$t("messages.upload_segment__fail"));
                }
              );
            });
          });
        }
      }
    },
  },
  mounted() {
    let buyer = this.$route.query.buyer_id;
    let currentUser = this.$store.getters.currentUser;
    this.params.buyer_id = buyer;
    if (currentUser && this.$store.getters.isClient) {
      this.params.buyer_id = currentUser.owner_id;
    } else {
      listBuyersActive((buyerActive) => {
        this.clients = buyerActive;
      }, true);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_segment_files_upload.scss";

#dropzone {
  width: 100%;
  border: 1px dashed #e7edf6;
  background-color: transparent;
}

.segment-dropzone,
#segmentTutorial,
#youtubeTutorial {
  margin: 0 35px;
}

.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #e63757;
}

@media (max-width: 650px) {
  .segment-dropzone,
  #segmentTutorial,
  #youtubeTutorial {
    padding: 10px;
    margin: 10px;
  }
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  display: none;
}

#segmentTutorial,
#youtubeTutorial {
  background-color: transparent;
  font-size: 90%;
  max-width: max-content;
  padding: 5px 1rem;
}

#segmentTutorial .flex,
#youtubeTutorial .flex {
  display: flex;
}

.csv-upload {
  display: inline-block;
  font-weight: 500;
  color: #748194;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #748194;
  padding: 0.3125rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  margin: auto;
}

@media (prefers-reduced-motion: reduce) {
  .csv-upload {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.csv-upload:hover {
  color: #fff;
  background-color: #748194;
  border-color: #748194;
}

.csv-upload:focus,
.csv-upload.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(116, 129, 148, 0.5);
  box-shadow: 0 0 0 0 rgba(116, 129, 148, 0.5);
}

.csv-upload.disabled,
.csv-upload:disabled {
  color: #748194;
  background-color: transparent;
}
</style>