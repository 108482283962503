<template>
  <div class="modal screening-option-form modal-show" v-show="isShown" ref="modal">
    <div class="modal-content">
      <div class="header">
        <div class="btn-close" @click="closeModal()">
          <i class="icon icon-close"></i>
        </div>
      </div>
      <div class="body">
        <p class="name mb-0">スクリーニングオプション設定</p>
        <p class="mt-3 mb-8">質問数は最大３つです。<br/>
          セグメントの内容と被りがないように設定してください。</p>
        <div class="d-flex">
          <p class="title">{{ questions[0].name }}の質問内容と選択肢を入力してください。</p>
        </div>
        <div class="d-flex align-items-center form-group w-100 mb-0">
          <label>質問内容</label>
          <input
              type="text"
              class="form-control"
              v-model.trim="questions[0].value"
              placeholder="入力してください"
              :disabled="readOnly"
          />
        </div>
        <screening-option-list :qId="questions[0].id" :options="questions[0].options"
                               :disabled="!questions[0].value" :readOnly="readOnly"></screening-option-list>
        <div class="error error-message"
             v-if="!questions[0].value && submitted || !questions[0].options.length && submitted">
          {{ this.screeningValidationMsg(questions[0]) }}
        </div>
        <template v-if="questionNum > 1 && questions[1]">
          <div class="d-flex">
            <i class="icon icon-close-circle" :class="{'cursor-default opacity-7': readOnly}"
               @click="removeQuestion(questions[1].id)"></i>
            <p class="title">{{ questions[1].name }}の質問内容と選択肢を入力してください。</p>
          </div>
          <div class="d-flex align-items-center form-group w-100 mb-0">
            <label>質問内容</label>
            <input
                type="text"
                class="form-control"
                v-model.trim="questions[1].value"
                placeholder="入力してください"
                :disabled="readOnly"
            />
          </div>
          <div class="change-previous-section">
            <p class="mt-2 mb-1">前の回答によって質問対象者を変更</p>
            <div class="d-flex">
              <div v-for="(q,k,i) in questions[1].isChecked" :key="'prev-q2-' + i" class="form-group w-fit mb-3">
                <div class="form-check-box" :class="{'opacity-7': readOnly}">
                  <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="questions[1].isChecked[k]"
                      @change="resetOption(0)"
                      :disabled="readOnly"
                  />
                  <span class="btn-check"></span>
                  <label class="label-check">{{ k }}</label>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column change-previous-option" v-if="questions[1].isChecked['Q1']">
              <div class="d-flex">
                <span class="mr-3">対象者を選択</span>
                <span class="previous-option-desc">※チェックを外すとその対象者はアンケートを終了します。</span>
              </div>
              <div class="d-flex flex-wrap gap-8-16 mt-2">
                <div v-for="option in questions[0].options" :key="'q2-selected-option-' + option.id"
                     class="form-group w-fit mb-0">
                  <div class="form-check-box" :class="{'opacity-7': readOnly}">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="option.isChecked"
                        :disabled="readOnly"
                    />
                    <span class="btn-check"></span>
                    <label class="label-check">{{ option.value }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <screening-option-list :qId="questions[1].id" :options="questions[1].options"
                                 :disabled="!questions[1].value" :readOnly="readOnly"></screening-option-list>
          <div class="error error-message"
               v-if="!questions[1].value && submitted || !questions[1].options.length && submitted">
            {{ this.screeningValidationMsg(questions[1]) }}
          </div>
        </template>
        <template v-if="questionNum > 2 && questions[2]">
          <div class="d-flex">
            <i class="icon icon-close-circle" :class="{'cursor-default opacity-7': readOnly}"
               @click="removeQuestion(questions[2].id)"></i>
            <p class="title">{{ questions[2].name }}の質問内容と選択肢を入力してください。</p>
          </div>
          <div class="d-flex align-items-center form-group w-100 mb-0">
            <label>質問内容</label>
            <input
                type="text"
                class="form-control"
                v-model.trim="questions[2].value"
                placeholder="入力してください"
                :disabled="readOnly"
            />
          </div>
          <div class="change-previous-section">
            <p class="mt-2 mb-1">前の回答によって質問対象者を変更</p>
            <div class="d-flex">
              <div v-for="(q,k,i) in questions[2].isChecked" :key="'prev-q3-' + i" class="form-group w-fit mb-3"
                   :class="{'ml-4': i > 0}">
                <div class="form-check-box" :class="{'opacity-7': readOnly}">
                  <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="questions[2].isChecked[k]"
                      @change="resetOption(1)"
                      :disabled="readOnly"
                  />
                  <span class="btn-check"></span>
                  <label class="label-check">{{ k }}</label>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column change-previous-option"
                 v-if="questions[2].isChecked['Q1'] && questions[2].isChecked['Q2']">
              <div class="d-flex">
                <span class="mr-3">対象者を選択</span>
                <span class="previous-option-desc">※チェックを外すとその対象者はアンケートを終了します。</span>
              </div>
              <div v-for="option in questions[0].options" :key="'q1-selected-option-join-' + option.id">
                <template v-if="option.isChecked">
                  <div class="d-flex flex-wrap gap-8-16 mt-2">
                    <div v-for="option_2 in questions[1].options"
                         :key="'q2-selected-option-join-' + option_2.id"
                         class="form-group w-fit mb-0">
                      <div class="form-check-box" :class="{'opacity-7': readOnly}">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="questions[2].q1q2Options[option.value + 'x' + option_2.value]"
                            :disabled="readOnly"
                        />
                        <span class="btn-check"></span>
                        <label class="label-check">{{ option.value }} x {{ option_2.value }}</label>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="d-flex flex-column change-previous-option" v-else-if="questions[2].isChecked['Q2']">
              <div class="d-flex">
                <span class="mr-3">対象者を選択</span>
                <span class="previous-option-desc">※チェックを外すとその対象者はアンケートを終了します。</span>
              </div>
              <div class="d-flex flex-wrap gap-8-16 mt-2">
                <div v-for="option in questions[1].options" :key="'q3-selected-option-' + option.id"
                     class="form-group w-fit mb-0">
                  <div class="form-check-box" :class="{'opacity-7': readOnly}">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="option.isChecked"
                        :disabled="readOnly"
                    />
                    <span class="btn-check"></span>
                    <label class="label-check">{{ option.value }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column change-previous-option" v-else-if="questions[2].isChecked['Q1']">
              <div class="d-flex">
                <span class="mr-3">対象者を選択</span>
                <span class="previous-option-desc">※チェックを外すとその対象者はアンケートを終了します。</span>
              </div>
              <div class="d-flex flex-wrap gap-8-16 mt-2">
                <div v-for="option in questions[0].options" :key="'q3-selected-option-' + option.id"
                     class="form-group w-fit mb-0">
                  <div class="form-check-box" :class="{'opacity-7': readOnly}">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="questions[2].q1Options[option.value]"
                        :disabled="readOnly"
                    />
                    <span class="btn-check"></span>
                    <label class="label-check">{{ option.value }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <screening-option-list :qId="questions[2].id" :options="questions[2].options"
                                 :disabled="!questions[2].value" :readOnly="readOnly"></screening-option-list>
          <div class="error error-message"
               v-if="!questions[2].value && submitted || !questions[2].options.length && submitted">
            {{ this.screeningValidationMsg(questions[2]) }}
          </div>
        </template>
        <div v-if="questionNum < 3" class="d-flex justify-content-between align-items-center add-question"
             :class="{'cursor-default opacity-7': readOnly}"
             @click="addQuestion">
          質問を追加
          <i class="icon icon-add-circle" :class="{'cursor-default': readOnly}"></i>
        </div>
        <div class="btn-submit">
          <button
              class="btn btn-primary button-download-pdf"
              @click="showConfirm()"
              :disabled="readOnly"
          >
            完了
          </button>
        </div>
      </div>
      <modal-confirm
          :message="message"
          @confirm="save()"
          v-if="isShowModalConfirm"
          @closeModalConfirm="isShowModalConfirm = false"
      ></modal-confirm>
    </div>
  </div>
</template>
<script>
import ModalConfirm from "../Modal/ModalConfirm.vue";
import ScreeningOptionList from "@/components/Project/ScreeningOptionList.vue"

export default {
  props: {
    isShown: Boolean,
    questions: Array,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModalConfirm,
    ScreeningOptionList
  },
  data() {
    return {
      message: "登録します、よろしいですか？",
      submitted: false,
      isShowModalConfirm: false,
      questionNum: 1
    };
  },
  watch: {
    isShown() {
      this.questionNum = this.questions.length
    }
  },
  computed: {
    checkValidation() {
      let result = false
      switch (this.questions.length) {
        case 1:
          result = this.questions[0].value && this.questions[0].options.length
          break
        case 2:
          result = this.questions[0].value && this.questions[0].options.length && this.questions[1].value && this.questions[1].options.length
          break
        case 3:
          result = this.questions[0].value && this.questions[0].options.length && this.questions[1].value && this.questions[1].options.length && this.questions[2].value && this.questions[2].options.length
          break
      }
      return result
    }
  },
  methods: {
    showConfirm() {
      this.submitted = true
      if (!this.checkValidation || this.readOnly) {
        return
      }
      this.isShowModalConfirm = true
    },
    closeModal() {
      this.$emit("closeModal")
    },
    screeningValidationMsg(question) {
      if (!question.value && !question.options.length) return this.$t("validation.screening.question_answer", {field: this.$t(question.name)})
      else if (!question.options.length) return this.$t("validation.screening.answer")
      return this.$t("validation.screening.question")
    },
    save() {
      this.isShowModalConfirm = false;
      this.$emit("settingOption", true)
      this.closeModal()
    },
    addQuestion() {
      if (!this.readOnly) {
        this.$emit('addQuestion')
        this.questionNum++
      }
    },
    removeQuestion(id) {
      if (!this.readOnly) {
        this.$emit('removeQuestion', id)
        this.questionNum--
      }
    },
    resetOption(index) {
      if (!this.readOnly) {
        this.$emit('resetOption', index)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.screening-option-form {
  .modal-content {
    min-height: 0;
    padding-bottom: 0;
    
    .body {
      color: #333333;
      
      .icon {
        cursor: pointer;
      }
      
      .cursor-default {
        cursor: default;
      }

      .opacity-7 {
        opacity: 0.7;
      }
      
      .name {
        font-weight: 700;
        font-size: 24px;
        line-height: 38px;
        margin-top: -8px;
      }
      
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
      
      .title {
        font-family: 'Noto Sans JP';
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        margin: 0 0 16px 8px;
        
        &:first-child {
          margin-left: 0;
        }
      }
      
      .error-message {
        font-family: 'Noto Sans JP';
        margin-top: -20px;
        margin-bottom: 20px;
      }
      
      .change-previous-section {
        padding-left: 76px;
        
        p, span {
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
        }
        
        .w-fit {
          width: fit-content;
        }
        
        .label-check {
          font-family: 'Noto Sans JP';
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          margin-right: 0;
        }
        
        .change-previous-option {
          min-height: 27px;
          padding: 20px;
          background-color: #EDF7F4;
          border-radius: 8px;
          
          .previous-option-desc {
            color: #999999;
          }
          
          .gap-8-16 {
            gap: 8px 16px;
          }
        }
      }
      
      .add-question {
        max-width: 226px;
        height: 30px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #49AB94;
        padding: 10px 20px;
        margin: 40px auto;
        border: 1px solid #49AB94;
        border-radius: 8px;
        cursor: pointer;
      }
      
      .cursor-default {
        cursor: default;
      }
      
      .btn-submit {
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0 -40px;
        background-color: #EDF7F4;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      
      button:disabled {
        cursor: default;
      }
    }
    
    .form-group {
      label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-right: 20px;
        margin-bottom: 0;
      }
      
      input {
        max-width: 306px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding-top: 13px;
        padding-bottom: 13px;
        
        &::placeholder {
          color: #999999;
        }
        
        &:disabled {
          cursor: default;
          background-color: #EBEBEB;
        }
        
        &.error {
          border: 1px solid #ce6060;
          background-color: #fef4f4;
          
          &::placeholder {
            color: #ce6060;
          }
        }
      }
      
      .btn {
        line-height: 20px;
      }
    }
  }
}
</style>
