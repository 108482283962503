<template>
    <div
        class="
        modal
        custom-interaction modal-show
        "
        ref="modal"
        v-show="isShown"
    >
    <div class="modal-content">
      <div class="header">
        <div class="btn-back">
          <i class="icon icon-prev" @click="closeModal()"></i>
        </div>
      </div>
      <div class="body">
        <div class="label">他の反応値を設定</div>
        <div class="form-flex">
            <div
                class="form-group required"
            >
                <label>反応値1</label>
                <input
                    type="text"
                    class="form-control"
                    placeholder="スキ / ナットクなど"
                    v-model="interaction_btn_1_text"
                />
                <template v-if="submitted && $v.interaction_btn_1_text.$error">
                    <span class="error error-message" v-if="!$v.interaction_btn_1_text.required">
                        {{ $t("validation.customInteraction.interaction_btn_1_text.required")}}</span>
                    <span class="error error-message" v-else-if="!$v.interaction_btn_1_text.maxLength">
                        {{ $t("validation.customInteraction.interaction_btn_1_text.max_length") }}
                    </span>
                </template>
            </div>
            <div
                class="form-group form-group-flex ml-5"
                >
                <label>カラー</label>
                <div class= "form-flex">
                    <template v-if="deliverPeriodType === listStep.step1.id">
                      <i class="icon mr-2" :class="{'icon-circle-blue' : color_1 != 1 ,'icon-circle-blue-checked': color_1 == 1}" @click="selectColor(1, 1)"></i>
                      <i class="icon mr-2" :class="{'icon-circle-yellow' : color_1 != 2 ,'icon-circle-yellow-checked': color_1 == 2}" @click="selectColor(1, 2)"></i>
                      <i class="icon mr-2" :class="{'icon-circle-red' : color_1 != 3 ,'icon-circle-red-checked': color_1 == 3}" @click="selectColor(1, 3)"></i>
                      <i class="icon mr-2" :class="{'icon-circle-pink' : color_1 != 4 ,'icon-circle-pink-checked': color_1 == 4}" @click="selectColor(1, 4)"></i>
                      <i class="icon" :class="{'icon-circle-orange' : color_1 != 5 ,'icon-circle-orange-checked': color_1 == 5}" @click="selectColor(1, 5)"></i>
                    </template>
                    <template v-else>
                      <i class="icon mr-2" :class="{'icon-circle-blue' : color_1 == 2 ,'icon-circle-blue-checked': color_1 == 1}" @click="selectColor(1, 1)"></i>
                      <i class="icon " :class="{'icon-circle-yellow-checked' : color_1 == 2 ,'icon-circle-yellow': color_1 == 1}" @click="selectColor(1, 2)"></i>
                    </template>
                </div>
            </div>
        </div>
        <div
            class="form-group required w-100">
            <label>反応値の説明文</label>
            <input
                type="text"
                class="form-control"
                placeholder="「ためになる！と感じたらタップしてください」など"
                v-model="interaction_btn_1_explain"
            />
            <template v-if="submitted && $v.interaction_btn_1_explain.$error">
                <span class="error error-message" v-if="!$v.interaction_btn_1_explain.required">
                        {{ $t("validation.customInteraction.interaction_btn_explain.required")}}</span>
                <span class="error error-message" v-if="!$v.interaction_btn_1_explain.maxLength">
                    {{ $t("validation.customInteraction.interaction_btn_explain.max_length") }}
                </span>
            </template>
        </div>
        <div
          class="form-group required w-100 content-borer-bottom"
        >
            <label>アイコン</label>
            <div class="form-flex flex-flow-wrap">
                    <div v-for="icon in icons[color_1]" :key="'icon-'+icon.id" :class="'icon-'+icon.id">
                    <img class="icon-image" :class="{'selected' : interaction_btn_1_icon_id == icon.id}" :src="icon.image_url" @click="selectIcon(1, icon.id)" >
                    </div>
                    
            </div>
            <template v-if="submitted && $v.interaction_btn_1_icon_id.$error">
                <span class="error error-message" v-if="!$v.interaction_btn_1_icon_id.required">
                    {{ $t("validation.customInteraction.interaction_icon.required") }}
                </span>
            </template>
        </div>
        <div class="form-flex" v-if="deliverFlg != 3">
            <div
                class="form-group required"
            >
                <label>反応値２</label>
                <input
                    type="text"
                    class="form-control"
                    placeholder="スキ / ナットクなど"
                    v-model="interaction_btn_2_text"
                />
                <template v-if="submitted && $v.interaction_btn_2_text.$error">
                    <span class="error error-message" v-if="!$v.interaction_btn_2_text.required">
                        {{ $t("validation.customInteraction.interaction_btn_2_text.required")}}</span>
                    <span class="error error-message" v-else-if="!$v.interaction_btn_2_text.maxLength">
                        {{ $t("validation.customInteraction.interaction_btn_2_text.max_length") }}
                    </span>
                </template>
            </div>
            <div
                class="form-group form-group-flex ml-5"
                >
                <label>カラー</label>
                <div class="form-flex">
                  <template v-if="deliverPeriodType === listStep.step1.id">
                    <i class="icon mr-2" :class="{'icon-circle-blue' : color_2 != 1 ,'icon-circle-blue-checked': color_2 == 1}" @click="selectColor(2, 1)"></i>
                    <i class="icon mr-2" :class="{'icon-circle-yellow' : color_2 != 2 ,'icon-circle-yellow-checked': color_2 == 2}" @click="selectColor(2, 2)"></i>
                    <i class="icon mr-2" :class="{'icon-circle-red' : color_2 != 3 ,'icon-circle-red-checked': color_2 == 3}" @click="selectColor(2, 3)"></i>
                    <i class="icon mr-2" :class="{'icon-circle-pink' : color_2 != 4 ,'icon-circle-pink-checked': color_2 == 4}" @click="selectColor(2, 4)"></i>
                    <i class="icon" :class="{'icon-circle-orange' : color_2 != 5 ,'icon-circle-orange-checked': color_2 == 5}" @click="selectColor(2, 5)"></i>
                  </template>
                  <template v-else>
                    <i class="icon mr-2" :class="{'icon-circle-red-checked' : color_2 == 3 ,'icon-circle-red': color_2 == 4}" @click="selectColor(2, 3)"></i>
                    <i class="icon" :class="{'icon-circle-pink-checked' : color_2 == 4 ,'icon-circle-pink': color_2 == 3}" @click="selectColor(2, 4)"></i>
                  </template>
                </div>
            </div>
        </div>
        <div
            v-if="deliverFlg != 3"
            class="form-group required w-100"
            >
            <label>反応値の説明文</label>
            <input
                type="text"
                class="form-control"
                placeholder="「ためになる！と感じたらタップしてください」など"
                v-model="interaction_btn_2_explain"
            />
            <template v-if="submitted && $v.interaction_btn_2_explain.$error">
                <span class="error error-message" v-if="!$v.interaction_btn_2_explain.required">
                        {{ $t("validation.customInteraction.interaction_btn_explain.required")}}</span>
                <span class="error error-message" v-if="!$v.interaction_btn_2_explain.maxLength">
                    {{ $t("validation.customInteraction.interaction_btn_explain.max_length") }}
                </span>
            </template>
        </div>
        <div
          v-if="deliverFlg != 3"
          class="form-group required w-100 content-borer-bottom"
        >
            <label>アイコン</label>
            <div class="form-flex flex-flow-wrap">
                    <div v-for="icon in icons[color_2]" :key="'icon-'+icon.id">
                    <img class="icon-image mr-5 mb-5" :class="{'selected' : interaction_btn_2_icon_id == icon.id}" :src="icon.image_url" @click="selectIcon(2, icon.id)">
                    </div>
                    
            </div>
            <template v-if="submitted && $v.interaction_btn_2_icon_id.$error">
                <span class="error error-message" v-if="!$v.interaction_btn_2_icon_id.required">
                    {{ $t("validation.customInteraction.interaction_icon.required") }}
                </span>
            </template>
        </div>
        <template v-if="deliverPeriodType == listStep.step1.id">
          <div class="form-flex">
            <div
                class="form-group required"
            >
              <label>反応値3</label>
              <input
                  type="text"
                  class="form-control"
                  placeholder="スキ / ナットクなど"
                  v-model="interaction_btn_3_text"
              />
              <template v-if="submitted && $v.interaction_btn_3_text.$error">
                    <span class="error error-message" v-if="!$v.interaction_btn_3_text.required">
                        {{ $t("validation.customInteraction.interaction_btn_3_text.required")}}</span>
                <span class="error error-message" v-else-if="!$v.interaction_btn_3_text.maxLength">
                        {{ $t("validation.customInteraction.interaction_btn_3_text.max_length") }}
                    </span>
              </template>
            </div>
            <div
                class="form-group form-group-flex ml-5"
            >
              <label>カラー</label>
              <div class= "form-flex">
                  <i class="icon mr-2" :class="{'icon-circle-blue' : color_3 != 1 ,'icon-circle-blue-checked': color_3 == 1}" @click="selectColor(3, 1)"></i>
                  <i class="icon mr-2" :class="{'icon-circle-yellow' : color_3 != 2 ,'icon-circle-yellow-checked': color_3 == 2}" @click="selectColor(3, 2)"></i>
                  <i class="icon mr-2" :class="{'icon-circle-red' : color_3 != 3 ,'icon-circle-red-checked': color_3 == 3}" @click="selectColor(3, 3)"></i>
                  <i class="icon mr-2" :class="{'icon-circle-pink' : color_3 != 4 ,'icon-circle-pink-checked': color_3 == 4}" @click="selectColor(3, 4)"></i>
                  <i class="icon" :class="{'icon-circle-orange' : color_3 != 5 ,'icon-circle-orange-checked': color_3 == 5}" @click="selectColor(3, 5)"></i>
              </div>
            </div>
          </div>
          <div
              class="form-group required w-100">
            <label>反応値の説明文</label>
            <input
                type="text"
                class="form-control"
                placeholder="「ためになる！と感じたらタップしてください」など"
                v-model="interaction_btn_3_explain"
            />
            <template v-if="submitted && $v.interaction_btn_3_explain.$error">
                <span class="error error-message" v-if="!$v.interaction_btn_3_explain.required">
                        {{ $t("validation.customInteraction.interaction_btn_explain.required")}}</span>
              <span class="error error-message" v-if="!$v.interaction_btn_3_explain.maxLength">
                    {{ $t("validation.customInteraction.interaction_btn_explain.max_length") }}
                </span>
            </template>
          </div>
          <div
              class="form-group required w-100 content-borer-bottom"
          >
            <label>アイコン</label>
            <div class="form-flex flex-flow-wrap">
              <div v-for="icon in icons[color_3]" :key="'icon-'+icon.id" :class="'icon-'+icon.id">
                <img class="icon-image" :class="{'selected' : interaction_btn_3_icon_id == icon.id}" :src="icon.image_url" @click="selectIcon(3, icon.id)" >
              </div>

            </div>
            <template v-if="submitted && $v.interaction_btn_3_icon_id.$error">
                <span class="error error-message" v-if="!$v.interaction_btn_3_icon_id.required">
                    {{ $t("validation.customInteraction.interaction_icon.required") }}
                </span>
            </template>
          </div>
        </template>
        <div class="btn-submit">
          <button class="btn btn-primary btn-addition" @click="addInteractionButton">
            設定
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { listIconInteraction } from "@/commons/api_service";
import EventBus from "../../commons/event_bus";
import { required, maxLength } from "vuelidate/lib/validators";
import { stepListInfo } from "@/commons/constants";
export default {
    data(){
        return {
            icons : [],
            color_1 : 1,
            color_2 : 3,
            color_3 : 2,
            interaction_btn_1_icon_id : null,
            interaction_btn_2_icon_id : null,
            interaction_btn_3_icon_id : null,
            interaction_btn_1_text : "",
            interaction_btn_2_text : "",
            interaction_btn_3_text : "",
            interaction_btn_1_explain : "",
            interaction_btn_2_explain : "",
            interaction_btn_3_explain : "",
            submitted : false,
            listStep : JSON.parse(JSON.stringify(stepListInfo)),
        }
    },
    validations(){
        let validate = {
            interaction_btn_1_text : {required, maxLength: maxLength(5)},
            interaction_btn_1_icon_id : {required},
            interaction_btn_1_explain : {required, maxLength: maxLength(30)}
        };
        
        if(this.deliverFlg != 3) {
            validate.interaction_btn_2_text = {required, maxLength: maxLength(5)};
            validate.interaction_btn_2_icon_id = {required};
            validate.interaction_btn_2_explain = {required, maxLength: maxLength(30)};
        }

        if(this.$props.deliverPeriodType === this.listStep.step1.id) {
          validate.interaction_btn_3_text = {required, maxLength: maxLength(5)};
          validate.interaction_btn_3_icon_id = {required};
          validate.interaction_btn_3_explain = {required, maxLength: maxLength(30)};
        }
        return validate
    },
    props : {
        isShown : Boolean,
        deliverFlg : Number,
        deliverPeriodType: Number,
    },
    methods : {
        selectColor(colorType, iconColor) {
            this['color_'+colorType] = iconColor;
            this['interaction_btn_'+colorType+'_icon_id'] = null;
        },
        selectIcon(colorType, iconId) {
            this['interaction_btn_'+colorType+'_icon_id'] = iconId;
        },
        closeModal(){
            EventBus.$emit('toggleModal', '');
        },
        addInteractionButton(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            let interactionButtonData = {
                'interaction_btn_1_icon_id' : this.interaction_btn_1_icon_id,
                'interaction_btn_1_text' : this.interaction_btn_1_text,
                'interaction_btn_1_explain' : this.interaction_btn_1_explain,
            }
            if (this.deliverFlg != 3) {
                interactionButtonData['interaction_btn_2_icon_id'] = this.interaction_btn_2_icon_id
                interactionButtonData['interaction_btn_2_text'] = this.interaction_btn_2_text
                interactionButtonData['interaction_btn_2_explain'] = this.interaction_btn_2_explain
            }

            if(this.$props.deliverPeriodType === this.listStep.step1.id) {
              interactionButtonData['interaction_btn_3_icon_id'] = this.interaction_btn_3_icon_id
              interactionButtonData['interaction_btn_3_text'] = this.interaction_btn_3_text
              interactionButtonData['interaction_btn_3_explain'] = this.interaction_btn_3_explain
            }

            EventBus.$emit('customInteractionButton', interactionButtonData);
            EventBus.$emit('toggleModal', '');
        },
        resetForm(){
            this.interaction_btn_1_icon_id = null,
            this.interaction_btn_2_icon_id = null,
            this.interaction_btn_3_icon_id = null,
            this.interaction_btn_1_text = "",
            this.interaction_btn_2_text = "",
            this.interaction_btn_3_text = "",
            this.interaction_btn_1_explain = "",
            this.interaction_btn_2_explain = "",
            this.interaction_btn_3_explain = "",
            this.submitted = false
        },
        // api
        getListIconInteraction(){
            listIconInteraction()
            .then(data => {
                this.icons = data;
                this.$emit('iconsList', data);
            })
            .catch(err => {
                console.log(err)
            })
        }
    },
    mounted(){
        this.getListIconInteraction()
    },
    created() {
        EventBus.$on('resetFormCustom', this.resetForm)
    },
    destroyed() {
        EventBus.$off('resetFormCustom', this.resetForm)
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_project.scss";

.content-borer-bottom {
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 18px;
}
</style>