<template>
    <div class="tooltip-content">
      <i class="icon icon-tooltip"></i>
      <span class="tooltip-text">{{ text }}</span>
    </div>
</template>
<script>
export default {
  props: {
    text: { type: String, default: '' },
  },
}
</script>