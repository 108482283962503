<template>
  <div class="top" v-if="!$store.getters.isLoadingApi" id="top" :class="{'step3-top' : stepSelected == 3}">
    <div class="d-flex justify-content-center align-items-center footer-top" :class="{'scrollable' : this.isScrollable}">
      <div class="d-flex justify-content-center align-items-center" :class="{'btn-disabled': !selectedVideo.length}"
           @click="compareVideos">
        複数選択して比較してみる（{{ selectedVideo.length }}動画）
      </div>
    </div>
    <div class="pt-3">
      <div class="d-flex align-items-center">
        <client-list
          :clients="clients"
          @select="onChangeClient"
          @clientPlan="getClientPlan"
          :selected="clientIdSelected"
        ></client-list>
        <div
          class="compare-data ml-5"
          @click="openModal('compare-video')"
          style="display: none"
        >
          <div class="icon-action mr-1">
            <div></div>
            <div></div>
          </div>
          <span>検証データを並べて比較</span>
        </div>
        <div
          v-if="currentUser.role == 'admin'"
          class="btn-update-batch ml-5"
          :style="disabledBtnUpdateBatch ? styleDisabledBtn : {}"
          @click="updateBatch"
        >
          <i class="icon icon-update-batch mr-1"></i>
          <span>データを更新</span>
        </div>
        <div
          class="btn-download ml-2"
          :style="disabledBtnDownloadManual ? styleDisabledBtn : {}"
          @click="downloadManual()"
        >
          <i class="icon icon-small-download mr-1"></i>
          <span>マニュアルダウンロード</span>
        </div>
      </div>
      <div>
        
      </div>
      <div v-if="clientPlan !== 7" class="mt-7 mb-5"></div>
      <step-list
        v-if="clientPlan == 7"
        class="mt-7 mb-5"
        :steps="steps"
        @select="onchangeStep"
        :selected="stepSelected"
        :disabled="isActiveSelectStep === false"
      ></step-list>
      <interaction-list
        v-if="stepSelected == 1 || stepSelected == 2"
        :interactions="interactions"
        :interactionType="interactionType"
        :interactionTypeStep1="interactionTypeStep1"
        :clientPlan ="clientPlan"
        :clientIdSelected = "clientIdSelected"
        :interactionClicked= "interactionClicked"
        :deliverPeriodType = "stepSelected"
        @selectedInteraction="selectedInteraction"
        @selectedInteractionStep1="selectedInteractionStep1"
      >
      </interaction-list>
      <media-type-list
        v-if="stepSelected == 3"
        :mediaTypes="mediaTypes"
        @selectMediaType="selectedMediaType"
        :selectedId="mediaTypeSelectedId"
      >
      </media-type-list>
      <labels
        :currentUser="currentUser"
        :clientIdSelected="clientIdSelected"
        :labels="labels"
        :labelIdSelected="labelIdSelected"
        @selectLabel="onChangeLabel"
        @openModal="openModal('add-label')"
      ></labels>
      <keep-alive>
        <component
          :stepSelected="parseInt(stepSelected)"
          :changeGridListVideo="changeGridListVideo"
          :grid_list_video="grid_list_video"
          :checkedAllVideos="checkedAllVideos"
          :doSort="doSort"
          :selectVideo="selectVideo"
          :clientIdSelected="clientIdSelected"
          :order="order"
          :sort="sort"
          :selectedVideo="selectedVideo"
          :videos="videos"
          :checkAllVideos="checkAllVideos"
          :is="getStepComponent"
          v-show="videos && videos.length"
        ></component>
      </keep-alive>
    </div>
    <form-component
      :isShown="showModalName == 'compare-video'"
      @closeModal="closeModal()"
    ></form-component>
    <modal-add-label
      :isShown="showModalName == 'add-label'"
      :clientId="clientIdSelected"
      @loadPage="initData"
      @closeModal="closeModal()"
    ></modal-add-label>
  </div>
  <div class="background" :style="styleVideoBackground" v-else>
    <div class="loader"></div>
  </div>
</template>
<script>
import ClientList from "@/components/Select/ClientList.vue";
import StepList from "@/components/Select/StepList";
import { stepList, PLAN_TYPE } from "@/commons/constants";
import Labels from "@/components/Select/Labels.vue";
import InteractionList from "@/components/Top/InteractionList.vue";
import {
  listBuyersActive,
  listBuyerMarket,
  listLabels,
  listVideo,
  listInteractions,
} from "@/commons/api_service";
import {getUrlQuery} from "@/commons/helpers";
import FormComponent from "@/components/Top/HomeCompare/Form.vue"
import ModalAddLabel from "@/components/Modal/ModalAddLabel"
const jQuery = require("jquery");
import TopVideoStep2 from "@/components/Top/Step-2/TopVideo.vue";
import TopVideoStep3 from "@/components/Top/Step-3/TopVideo.vue";
import TopVideoStep1 from "@/components/Top/Step-1/TopVideo.vue";
import MediaTypeList from "@/components/Top/Step-3/MediaTypeList";
import moment from "moment/moment";
export default {
  components: {
    InteractionList,
    Labels,
    ClientList,
    FormComponent,
    ModalAddLabel,
    StepList,
    TopVideoStep2,
    TopVideoStep3,
    MediaTypeList,
    TopVideoStep1,
  },
  name: "Top",
  props: {},
  data() {
    return {
      clients: [],
      currentUser: {},
      clientIdSelected: null,
      labels: [],
      labelIdSelected: [],
      videos: [],
      checkedVideos: [],
      styleVideoBackground: {
        width: "calc(100% - 66px)",
        height: "100%",
        top: "0px",
        position: "fixed",
        display: "flex",
        right: 0,
      },
      interactions: [],
      interactionType: 0,
      interactionTypeStep1: 0,
      interactionSelected: {},
      interactionSelectedStep1: {},
      interactionSelectedStep2: {},
      interactionClicked: [],
      selectedVideo: [],
      showModalName: "",
      disabledBtnDownloadManual: false,
      styleDisabledBtn: {
        opacity: 0.7,
        cursor: "default",
      },
      disabledBtnUpdateBatch: false,
      sort: "id",
      order: "desc",
      isSortOrder: false,
      isScrollable: false,
      steps: JSON.parse(JSON.stringify(stepList)),
      stepSelected: 2,
      mediaTypes: [
        {
          id: 1,
          name: "CM",
        },
        {
          id: 2,
          name: "YouTube",
        },
        {
          id: 99,
          name: "その他",
        },
      ],
      mediaTypeSelectedId: 1,
      isActiveSelectStep: false,
      checkedAllVideos: false,
      grid_list_video: 1,
      clientPlan: null,
      contentHeight: 0,
      step1: false,
      step2: false,
      changeParams1: false,
      changeParams2: false,
    };
  },
  computed: {
    getStepComponent() {
      return "TopVideoStep" + this.stepSelected;
    },
  },
  methods: {
    changeGridListVideo(type) {
      this.grid_list_video = type;
    },
    selectedMediaType(mediaType) {
      this.mediaTypeSelectedId = mediaType.id;
      this.getVideos();
    },
    onchangeStep(stepId) {
      this.stepSelected = stepId;
      if(Number(stepId) == 3){
        this.getVideos();
      }
    },
    toggleModal(modalName) {
      this.showModalName = modalName;
    },
    getClientPlan(plan) {
      this.clientPlan = plan;
    },
    resetData() {
      this.videos = [];
      this.interactions = [];
      this.interactionType = 0;
      this.interactionTypeStep1 = 0;
      this.interactionSelected = {};
      this.interactionSelectedStep1= {};
      this.interactionSelectedStep2= {};
      this.interactionClicked= [];
      this.stepSelected = 2;
      this.selectedVideo = []
    },
    onChangeClient(data) {
      const client = this.clients.find((item) => item.id == data);
      if (client) {
        if (this.currentUser && this.currentUser.role == "admin") {
          this.isActiveSelectStep = client.plan == PLAN_TYPE.ADVANCE
        } else {
          this.isActiveSelectStep = client.plan == PLAN_TYPE.ADVANCE && this.currentUser.plan == PLAN_TYPE.ADVANCE;
        }
      }

      this.clientIdSelected = Number(data);
      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "home",
          query: { buyer_id: this.clientIdSelected },
        }).href
      );
      this.resetData();
      this.initInteraction();
      this.initData();
    },
    onChangeLabel(labelId) {

      const index = this.labelIdSelected.indexOf(labelId);
      if (index > -1) {
        this.labelIdSelected.splice(index, 1);
      } else {
        this.labelIdSelected.push(labelId);
      }
      if(this.stepSelected == 1){
        this.changeParams1 = true
      } else if(this.stepSelected == 2){
        this.changeParams2 = true
      }
      this.getVideos();
    },
    initInteraction() {
      listInteractions((interactions) => {
        if (interactions.length) {
          this.interactions = interactions;
          this.interactionSelected = interactions[0];
          if(this.stepSelected == 1){
            this.interactionSelected.deliver_period_type = 1
          } else if(this.stepSelected == 2){
            this.interactionSelected.deliver_period_type = 2
          }
          this.getVideos();
        } else {
          this.interactions = []
        }
      }, this.clientIdSelected, this.stepSelected);
    },
    initData() {
      listLabels((labels) => {
        this.labelIdSelected = [];
        this.labels = labels;
      }, this.clientIdSelected);
    },
    getVideos() {
      let step1 = false;
      let step2 = false;
      if(!this.interactionSelectedStep2.interaction_button_1_text){
        this.interactionSelectedStep2 = this.interactionSelected;
        step2 = true;
      } else if(!this.interactionSelectedStep1.interaction_button_1_text && this.interactionSelected.deliver_period_type == 1){
        this.interactionSelectedStep1 = this.interactionSelected;
        step1 = true;
      }

      if(this.step1 && this.interactionSelected.deliver_period_type == 1){
        this.interactionSelectedStep1 = this.interactionSelected;
        step1 = true;
      } else if(this.step2 && this.stepSelected == 2 && this.interactionSelected.interaction_button_1_text){
        this.interactionSelectedStep2 = this.interactionSelected;
        step2 = true;
      }

      let video_params = {
          buyer_id: this.clientIdSelected,
          status: 1,
          sort: this.sort,
          order: this.order,
          interaction_button_1:
          this.changeParams1 || step1 ? this.interactionSelectedStep1.interaction_button_1_text : this.changeParams2 || step2 || this.stepSelected == 2 && this.interactionSelectedStep2.interaction_button_1_text ? this.interactionSelectedStep2.interaction_button_1_text : this.isSortOrder ? this.interactionSelected.interaction_button_1_text : undefined,
          deliver_period_type: this.stepSelected,
          commercial_media_type: this.stepSelected == 3 ? this.mediaTypeSelectedId : undefined,
        };
        if (step2 && this.interactionSelectedStep2.interaction_button_2_text || this.changeParams2 || this.stepSelected == 2 && this.interactionSelectedStep2.interaction_button_2_text) {
          video_params = {
            ...video_params,
            ...{
              interaction_button_2:
                this.stepSelected == 2 ? this.interactionSelectedStep2.interaction_button_2_text: undefined,
            },
          };
        }
        if(step1 || this.changeParams1){
          video_params = {
            ...video_params,
            ...{
              interaction_button_2:
                this.stepSelected == 1 ? this.interactionSelectedStep1.interaction_button_2_text: undefined,
              interaction_button_3:
                this.stepSelected == 1 ? this.interactionSelectedStep1.interaction_button_3_text: undefined,
            },
          };
        }
        if(this.isSortOrder && !this.interactionSelected.interaction_button_3_text){
          video_params = {
            ...video_params,
            ...{
              interaction_button_2:
                this.interactionSelected.interaction_button_2_text
            },
          }
        }
        if(this.isSortOrder && this.interactionSelected.interaction_button_3_text){
          video_params = {
            ...video_params,
            ...{
              interaction_button_2:
                this.interactionSelected.interaction_button_2_text,
              interaction_button_3:
                this.interactionSelected.interaction_button_3_text,
            },
          }
        }
        if(this.isSortOrder && this.stepSelected == 3){
          video_params = {
            ...video_params,
            ...{
              interaction_button_1:
                undefined,
              interaction_button_2:
                undefined
            },
          }
        }

        if (this.labelIdSelected.length) {
          video_params = {
            ...video_params,
            ...{ label_ids: this.labelIdSelected.join(",") },
          };
        }
        if(this.stepSelected == 2 && (this.interactionSelectedStep2.interaction_button_1_text && this.interactionSelectedStep2.interaction_button_2_text)){
          video_params.interaction_button_1 = this.interactionSelectedStep2.interaction_button_1_text
          video_params.interaction_button_2 = this.interactionSelectedStep2.interaction_button_2_text
        }
            let buttonClicked = false;
            for(let i = 0; i< this.interactions.length; i++){
              if(video_params.interaction_button_1 == this.interactions[i].interaction_button_1_text && video_params.interaction_button_2 == this.interactions[i].interaction_button_2_text && video_params.deliver_period_type == 2){
                this.interactionClicked.push(this.interactions[i]);
                let result = this.interactionClicked.filter(function (x, i, array) { return array.findIndex(function (y) { return y.interaction_button_1_text=== x.interaction_button_1_text && y.interaction_button_2_text === x.interaction_button_2_text;}) === i});
                this.interactionClicked.length !== result.length ? buttonClicked = true: buttonClicked = false;
                this.interactionClicked = result;
              }
              if(video_params.interaction_button_1 == this.interactions[i].interaction_button_1_text && video_params.interaction_button_2 == this.interactions[i].interaction_button_2_text && video_params.interaction_button_3 == this.interactions[i].interaction_button_3_text &&video_params.deliver_period_type == 1){
                this.interactionClicked.push(this.interactions[i]);
                let result = this.interactionClicked.filter(function (x, i, array) { return array.findIndex(function (y) { return y.interaction_button_1_text=== x.interaction_button_1_text && y.interaction_button_2_text === x.interaction_button_2_text && y.interaction_button_3_text === x.interaction_button_3_text;}) === i});
                this.interactionClicked.length !== result.length ? buttonClicked = true: buttonClicked = false;
                this.interactionClicked = result;
              }
            }
          listVideo((data) => {
            this.videos = data;
            let selectedVideoTemp = [];
            if (this.labelIdSelected.length || this.isSortOrder) {
              for (let i = 0; i < this.videos.length; i++) {
                if (this.selectedVideo.includes(this.videos[i].id))
                  selectedVideoTemp.push(this.videos[i].id);
              }
            }
            this.selectedVideo = selectedVideoTemp;
            this.isSortOrder = false;
          }, video_params, buttonClicked);
        if(this.videos.length){
          if((this.stepSelected == 1 && this.videos[0].deliver_period_type !== 1) || (this.stepSelected == 2 && this.videos[0].deliver_period_type !== 2)){
            this.videos = [];
          }
        }
        this.step1 = false;
        this.step2 = false;
        this.changeParams1 = false;
        this.changeParams2 = false;
        step1 = false;
        step2 = false;
    },
    checkAllVideos() {
      if (!this.checkedAllVideos) {
        let cloneVideos = JSON.parse(JSON.stringify(this.videos));
        if (this.stepSelected != 3) {
          this.selectedVideo = cloneVideos.map((item) => item.id);
          this.checkedAllVideos = !this.checkedAllVideos;
        } else {
          let listVideoHasCheckbox = cloneVideos.filter(item => moment(item.start_at, "YYYY-MM-DD") <= moment())
          this.selectedVideo = listVideoHasCheckbox.map((item) => item.id);
          this.checkedAllVideos = this.selectedVideo.length === listVideoHasCheckbox.length && this.selectedVideo.length > 0;
        }
      } else {
        this.selectedVideo = [];
        this.checkedAllVideos = !this.checkedAllVideos;
      }
    },
    selectVideo(args) {
      if (args.checkedVideo) {
        if (!this.selectedVideo.includes(args.videoId))
          this.selectedVideo.push(args.videoId);
      } else {
        const index = this.selectedVideo.indexOf(args.videoId);
        if (index > -1) {
          this.selectedVideo.splice(index, 1);
        }
      }
    },
    compareVideos() {
      if (this.selectedVideo.length) {
        let route_name = "top-compare";
        if(this.stepSelected == 1) {
          route_name = "top-compare-step-1";
        } else if (this.stepSelected == 3) {
          route_name = "top-compare-step-3";
        }
        let compare_video_url = this.$router.resolve({
          name: route_name,
          query: {
            video_ids: this.selectedVideo.toString(),
            buyer_id: this.clientIdSelected,
          },
        });
        window.open(compare_video_url.href, "_blank");
      }
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
    closeModal() {
      this.toggleModal("");
    },
    selectedInteraction(args) {
      this.interactionSelected = args.interaction;
      this.interactionSelected.deliver_period_type = 2;
      this.interactionType = args.type;
      this.step2 = true;
      this.getVideos();
    },
    selectedInteractionStep1(args){
      this.interactionSelected = args.interaction;
      this.interactionSelected.deliver_period_type = 1;
      this.interactionTypeStep1 = args.type;
      this.step1 = true;
      this.getVideos();
    },
    downloadManual() {
      if (!this.disabledBtnDownloadManual) {
        this.disabledBtnDownloadManual = true;
        let url = this.$router.resolve({ name: "api.download.manual" }).href;
        let params = {
          user_id: this.currentUser.user_id,
        };
        this.$http2.get(
          url,
          params,
          (data) => {
            let url = data.download_url;
            let label = url.substr(url.lastIndexOf("/") + 1);
            this.$http2.downloadPDF(
              url,
              label,
              () => {
                this.disabledBtnDownloadManual = false;
              },
              () => {
                this.disabledBtnDownloadManual = false;
              }
            );
          },
          () => {
            this.disabledBtnDownloadManual = false;
          }
        );
      }
    },
    updateBatch() {
      if (!this.disabledBtnUpdateBatch) {
        this.disabledBtnUpdateBatch = true;
        let url = this.$router.resolve({ name: "api.batch" }).href;
        this.$http2.post(
          url,
          {},
          () => {
            this.disabledBtnUpdateBatch = false;
          },
          () => {
            this.disabledBtnDownloadManual = false;
          }
        );
      }
    },
    doSort(args) {
      this.sort = args.sortVideo;
      this.order = args.orderVideo;
      this.isSortOrder = true;
      this.getVideos();
    },
    checkScrollable() {
      let vm = this
      setTimeout(function () {
        let parentElement = jQuery(".wrapper-container")[0]
        vm.isScrollable = parentElement.scrollWidth > parentElement.clientWidth;
      })
    },
  },
  watch: {
    selectedVideo(val) {
      if(this.stepSelected != 3) {
        this.checkedAllVideos = val.length === this.videos.length;
      } else {
        this.checkedAllVideos = val.length === this.videos.filter(item => moment(item.start_at, "YYYY-MM-DD") < moment()).length && val.length > 0;
      }
    },
    clientIdSelected(val) {
      this.$store.commit('setBuyerSelected', val)
    },
    stepSelected(val){
      if(val == 1){
        this.changeParams1 = true
      } else if(val == 2){
        this.changeParams2 = true
      }
      this.interactionClicked = [];
      this.initInteraction();
    },
  },
  updated() {
    this.checkScrollable()
  },
  mounted() {
    let currentUser = this.$store.getters.currentUser;
    this.currentUser = currentUser;
    let { buyer_id } = getUrlQuery();
    if (currentUser && currentUser.role == "admin") {
      listBuyersActive((buyersActive) => {
        this.clients = buyersActive;
        if (buyersActive.length) {
          let clientSelected = buyersActive[0];
          if (buyer_id) {
            clientSelected = buyersActive.find((e) => e.id == buyer_id);
          }
          if (clientSelected) {
            this.clientIdSelected = clientSelected.id;
            this.isActiveSelectStep = clientSelected.plan === PLAN_TYPE.ADVANCE;
          }
          this.initInteraction();
          this.initData();
        }
      }, true);
    } else {
      listBuyerMarket((buyersMarket) => {
        this.clients = buyersMarket;
        if (buyer_id) {
          this.clientIdSelected = Number(buyer_id);
        } else {
          this.clientIdSelected = this.currentUser.owner_id;
        }
        if (this.clientIdSelected && buyersMarket && buyersMarket.length) {
            let clientSelected = buyersMarket.find((e) => e.id == this.clientIdSelected);
            this.isActiveSelectStep = !(currentUser.plan !== PLAN_TYPE.ADVANCE || clientSelected.plan !== PLAN_TYPE.ADVANCE);
        }
        this.initInteraction();
        this.initData();
      }, true);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "src/styles/variables/sidebar";
.top::v-deep {
  padding-bottom: 200px;
  min-height: calc(100vh - 220px);
  position: relative;

  .client-name {
    color: #333333;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .compare-data,
  .btn-download,
  .btn-update-batch {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    max-height: 28px;
    background-color: #ffffff;
    border: 1px solid #49ab94;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    span {
      color: #49ab94;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
    }

    .icon-action {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      background-color: #edf7f4;
      border-radius: 50%;

      div:first-child {
        width: 4px;
        height: 8px;
        background-color: #49ab94;
        border-radius: 1px;
      }

      div:last-child {
        width: 1px;
        height: 6px;
        margin-left: 1px;
        border: 1px solid #49ab94;
        border-radius: 1px;
      }
    }
  }

  .icon-checkbox {
    flex: 0 0 28px;
  }

  .number-of-video,
  .item-name {
    color: #333333;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
  }

  .item-name-default {
    color: #999999;
  }

  .item-name-active {
    color: #49ab94;
  }

  .footer-top {
    position: fixed;
    bottom: 0;
    height: 71px;
    background-color: #ffffff;
    z-index: 1;
    border-top: 1px solid #EBEBEB;
    @include cal-left-with-sidebar;

    &.scrollable {
      bottom: 10px;
    }

    div {
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      width: 308px;
      height: 40px;
      background-color: #49ab94;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }

    .btn-disabled {
      opacity: 0.7;
      cursor: default;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .border-left-default {
    height: 15px;
    border-left: 1px solid #ebebeb;
  }
}
.step3-top{
  padding-bottom: 160px !important;
}
</style>
<style type='text/css'>

.background .loader {
  border: 3px solid #49ab94;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
