<template>
  <div class="row top" v-if="!showTopBefore">
    <div class="col-9 col-lg-10 mr-0 outline-area">
      <div class="clients-list">
        <client-list
          v-if="false"
          :clients="clients"
          @select="onChangeClient"
          :selected="clientIdSelected"
        ></client-list>
      </div>
      <projects
        v-if="false"
        :projects="projects"
        :selected="projectIdSelected"
        @selectProject="onChangeProject"
      ></projects>
      <div class="video-report-area mt-4" v-show="isLoaded">
        <summary-chart
          v-if="false"
          :videos="videos"
          :summaryAvg="summaryAvg"
          @selectVideo="onSelectVideo"
          :dataReport="dataReport"
          :adId="projectIdSelected"
          :buyerId="clientIdSelected"
          @loadVideosImages="loadedData"
          :checkedPrj="checkedPrj"
          :project="projectSelected"

        ></summary-chart>
      </div>
      <div class="capture-videos mt-5" v-if="!checkedVideos.filter(e => e.duration).length" v-show="isLoaded">
        <template v-if="checkedVideos.length">恐れ入りますが各シーンのイメージを反映するまでに少々お待ちください。動画登録の翌日に反映される予定です。</template>
        <template v-else>動画を選択するとシーン一覧がこちらに表示されます</template>
      </div>
      <div class="capture-videos has-video-selected mt-5" v-else v-show="isLoaded">
        <div class="type-reaction">
          <div v-if="projectSelected.interaction_btn_1 && Object.keys(projectSelected.interaction_btn_1).length" :class="colorInteraction.rank_2 + ' mr-2'">
            {{ textInteraction.rank_2 }}
          </div>
          <div v-if="projectSelected.interaction_btn_2 && Object.keys(projectSelected.interaction_btn_2).length" :class="colorInteraction.rank_3">
            {{ textInteraction.rank_3 }}
          </div>
        </div>
        <div class="list-video-captures">
          <capture-video
            v-for="(video, index) in checkedVideos.filter(e => e.duration)"
            :key="'video-' + video.id"
            :videoImage="video"
            :index="index"
            :step="currentImageStep"
            :interactionType="dataReport.interaction_type"
            :checkedVideosLength="checkedVideos.filter(e => e.duration).length"
            :project="projectSelected"

          ></capture-video>
        </div>
        <div class="btn-next">
          <i
            class="icon icon-prev"
            v-if="currentImageStep"
            @click="prevImages"
          ></i>
          <i
            class="icon icon-next"
            v-if="hasNextImages"
            @click="nextImages"
          ></i>
        </div>
      </div>
      <div class="table-project" v-show="videos.length && isLoaded">
        <project-table :listVideo="videos" :checkedVideos="checkedVideos" :dataReport ="dataReport"></project-table>
      </div>
      <report-interaction-top
        :adId="projectIdSelected"
        :project="projectSelected"
        v-show="isLoaded"
      ></report-interaction-top>
      <segments-project
        :videos="videos"
        :adId="projectIdSelected"
        :interactionType="dataReport.interaction_type"
        :project="projectSelected"

        v-show="isLoaded"
      ></segments-project>
    </div>
    <div class="col-4 col-lg-3 detail-area" v-show="isLoaded">
      <right-side
        :listRank2="listRank2"
        :listRank3="listRank3"
        :maxRank2="maxRank2"
        :maxRank3="maxRank3"
        :videos="checkedVideos"
        :interactionType="dataReport.interaction_type"
        :fromRouter = "'market-research'"
        :isProject="isProject"
        :project="projectSelected"
      ></right-side>
    </div>
    <div class="background" :style="styleVideoBackground" v-if="!isLoaded">
      <div class="loader"></div>
    </div>
  </div>
  <div v-else class="top top-before">
    <div class="side-left">
      <div class="title-side-left">
        <div class="title-large">Welcome to PlayAds</div>
        <div class="title-small mt-6">
          動画コンテンツの反応値を測定して、
          <br />
          安全かつ効果的に配信しよう！
        </div>
        <div
          class="btn btn-register btn-primary-outline"
          @click="gotoRegisterProject(clientIdSelected)"
        >
          案件を登録する
        </div>
      </div>
    </div>
    <div class="side-right bg-primary">
      <img class="img-logo" src="images/svg/logo_before_post.svg" />
      <img src="images/svg/bg_login_logo.svg" />
    </div>
  </div>
</template>

<script>
import ClientList from "@/components/Select/ClientList.vue";
import Projects from "@/components/Select/Projects.vue";
import SummaryChart from "@/components/Top/SummaryChart.vue";
import RightSide from "@/components/Top/RightSide.vue";
import CaptureVideo from "@/components/Top/CaptureVideo.vue";
import {
  // eslint-disable-next-line no-unused-vars
  listBuyersActive,
  listProject,
  listVideoPromise,
  interactionVideoReport,
  reportTop,
  buyerNormReport,
  videoImages,
  // eslint-disable-next-line no-unused-vars
  listBuyerMarket,
  // buyerMarketNormReport
} from "@/commons/api_service";
import SegmentsProject from "../../components/Top/SegmentsProject.vue";
import EventBus from "@/commons/event_bus";
import ReportInteractionTop from "@/components/Top/ReportInteractionTop.vue";
import ProjectTable from "@/components/Top/TableProject.vue";
import {
  getUrlQuery,
  addUrlParamToPath
} from "@/commons/helpers";

export default {
  components: {
    Projects,
    ClientList,
    SummaryChart,
    RightSide,
    CaptureVideo,
    SegmentsProject,
    ReportInteractionTop,
    ProjectTable
  },
  name: "Top",
  props: {},
  data() {
    return {
      clients: [],
      currentUser: {},
      clientIdSelected: null,
      projectIdSelected: null,
      projects: [],
      videos: [],
      listRank2: [],
      listRank3: [],
      checkedVideos: [],
      reportData: [],
      reports: [],
      maxRank2: {},
      maxRank3: {},
      dataReport: {
        report_data: []
      },
      currentImageStep: 0,
      summaryAvg: {},
      showTopBefore: false,
      marketResearchClient : null,
      styleVideoBackground : {
          width: 'calc(100% - 66px)',
          height: '100%',
          top: '0px',
          position: 'fixed',
          display: 'flex',
          right : 0
      },
      isLoaded : false,
      checkedPrj : true,
      isProject : false,
      projectSelected : {},
      is_stack_bar : 0
    };
  },
  computed: {
    textInteraction() {
      return {
        rank_2 : this.projectSelected.interaction_btn_1 && Object.keys(this.projectSelected.interaction_btn_1).length ? this.projectSelected.interaction_btn_1.text : '',
        rank_3 : this.projectSelected.interaction_btn_2 && Object.keys(this.projectSelected.interaction_btn_2).length ? this.projectSelected.interaction_btn_2.text : '',
      }
    },
    iconInteraction() {
      return {
        rank_2 : this.projectSelected.interaction_btn_1 && Object.keys(this.projectSelected.interaction_btn_1).length ? this.projectSelected.interaction_btn_1.btn_icon_url : '',
        rank_3 : this.projectSelected.interaction_btn_2 && Object.keys(this.projectSelected.interaction_btn_2).length ? this.projectSelected.interaction_btn_2.btn_icon_url : '',
      }
    },
    colorInteraction(){
      let color_rank_2 = this.projectSelected.interaction_btn_1 && Object.keys(this.projectSelected.interaction_btn_1).length ? this.projectSelected.interaction_btn_1.color : '';
      let color_rank_3 = this.projectSelected.interaction_btn_2 && Object.keys(this.projectSelected.interaction_btn_2).length ? this.projectSelected.interaction_btn_2.color : ''
      return {
        rank_2 : color_rank_2 == 1 ? 'type-good' : color_rank_2 == 2 ? 'type-nattoku' : color_rank_2 == 3 ? 'type-bad' : color_rank_2 == 4 ? 'type-suki' : '',
        rank_3 : color_rank_3 == 1 ? 'type-good' : color_rank_3 == 2 ? 'type-nattoku' : color_rank_3 == 3 ? 'type-bad' : color_rank_3 == 4 ? 'type-suki' : '',
      }
    },
    hasNextImages() {
      let result = false;
      for (let i = 0; i < this.checkedVideos.length; i++) {
        let videoImage = this.checkedVideos[i];
        if (
          videoImage.duration &&
          videoImage.duration >= (this.currentImageStep + 1) * 9
        ) {
          result = true;
          break;
        }
      }
      return result;
    },
  },
  methods: {
    loadedData(){
      this.isLoaded = true;
      this.checkedPrj = false;
    },
    onChangeClient(data) {
      this.isLoaded = false;
      this.clientIdSelected = Number(data);
      this.checkedVideos.length = 0;
      buyerNormReport((reportAvg) => {
        this.summaryAvg = reportAvg;
      }, this.clientIdSelected);
      listProject((projects) => {
        this.projects = projects;
        if (!projects.length) {
          this.isLoaded = true;
          this.isProject = false;
        } else {
          this.isProject = true;
        }
        let currentQuery = getUrlQuery();
        let newParam = {
          client: Number(data),
        }
        if (this.projects.length > 0) {
          this.onChangeProject(projects[this.projects.length - 1].id);
          newParam.project = projects[this.projects.length - 1].id
        } else {
          this.videos = [];
          newParam.project = '';
          this.projectIdSelected = null;
          this.clearDataRankingProject();
        }
        addUrlParamToPath(newParam, currentQuery)
      }, this.clientIdSelected);
    },

    onChangeProject(data, updateQuery = true) {
      this.projectSelected = data;
      let adId = data.id;
      if (this.projectIdSelected != adId) {
        this.checkedPrj = true;
        this.isLoaded = false;
        this.projectIdSelected = adId;
        this.checkedVideos = [];
        if (updateQuery) {
          let currentQuery = getUrlQuery();
          let newParam = {
            project: Number(adId)
          }
          addUrlParamToPath(newParam, currentQuery)
        }
        let query_report = {
          buyer_id: this.clientIdSelected,
          ad_id: this.projectIdSelected,
          interaction_tally : 1,
          is_stack_bar : this.is_stack_bar
        };
        let query_videos = {
          buyer_id: this.clientIdSelected,
          ad_ids: this.projectIdSelected,
          status : 1,
          order : 'desc'
        };
        Promise.all([reportTop(query_report),listVideoPromise(query_videos)])
        .then((data)=> {
          this.dataReport = data[0];
          this.dataReport.report_data = data[0].report_data ? data[0].report_data : [];
          this.videos = data[1];
        })
        this.getInteractionReportData(this.projectIdSelected);
      }
    },

    onSelectVideo(video) {
      if (video.checked) {
        this.currentImageStep = 0;
        let report = this.dataReport.report_data.find(item => item.video_id == video.id);
        if (report) {
          this.$set(video, "total_2", report.total_2);
          this.$set(video, "total_3", report.total_3);
        }
        this.checkedVideos.push(video);
      } else {
        this.checkedVideos = this.checkedVideos.filter(
          (item) => item.id !== video.id
        );
      }
    },
    resetCheckedVideo() {
      this.checkedVideos = [];
    },
    clearDataRankingProject() {
      this.listRank2 = [];
      this.listRank3 = [];
      this.maxRank2 = {};
      this.maxRank3 = {};
    },
    getInteractionReportData(projectId) {
      let interactionTally = 1;
      this.clearDataRankingProject();
      interactionVideoReport((report) => {
        this.listRank2 = report["2_rank"];
        this.listRank3 = report["3_rank"];
        if (this.listRank2.length && this.listRank3.length) {
          videoImages((image) => {
            if (image) {
              let second = image.interaction_rank["2_rank"][0].second
              this.maxRank2 = {
                ...this.listRank2[0],
                second,
                image_url: image.image_url + second + ".jpg",
                interaction_count_max_per_second:  image.interaction_rank["2_rank"][0].interaction_count
              }
            }
            
          }, {videoId : this.listRank2[0].video_id,interactionTally});
          videoImages((image) => {
            if (image) {
              let second = image.interaction_rank["3_rank"][0].second
              this.maxRank3 = {
                ...this.listRank3[0],
                second,
                image_url: image.image_url + second + ".jpg",
                interaction_count_max_per_second: image.interaction_rank["3_rank"][0].interaction_count
              }
            }
            
          }, {videoId : this.listRank3[0].video_id,interactionTally});
    
        } 
      }, {projectId, interactionTally});
    },
    prevImages() {
      this.currentImageStep -= 1;
    },
    nextImages() {
      this.currentImageStep += 1;
    },
    gotoVideoDetail(video_id) {
      this.$router.push({
        name: "top-detail",
        params: {
          video_id,
        },
      });
    },
    initData(projectId) {
      listProject((projects) => {
        this.projects = projects;
        if (!projects.length) {
          this.isLoaded = true;
          this.isProject = false;
        } else {
          this.isProject = true;
        }
        if (!this.projects.length && this.currentUser.role == "buyer") {
          this.showTopBefore = true;
        }
        if (this.projects.length) {
          this.projectIdSelected = projects[projects.length - 1].id;
          this.projectSelected = projects[projects.length - 1];

          if (projectId) {
            let projectSelected = projects.find(e => e.id == projectId);
            this.projectSelected = projectSelected;
            if (projectSelected) {
              this.projectIdSelected = projectSelected.id
            }
          }
          let query_videos = {
            buyer_id: this.clientIdSelected,
            ad_ids: this.projectIdSelected,
            status : 1,
            order : 'desc'
          };

          let query_report = {
            buyer_id: this.clientIdSelected,
            ad_id: this.projectIdSelected,
            interaction_tally : 1,
            is_stack_bar : this.is_stack_bar
          };
          Promise.all([reportTop(query_report), listVideoPromise(query_videos)])
          .then((data)=> {
            this.dataReport = data[0];
            this.dataReport.report_data = data[0].report_data ? data[0].report_data : [];
            this.videos = data[1];
          })

          buyerNormReport((reportAvg) => {
            this.summaryAvg = reportAvg;
          }, this.clientIdSelected);

          this.getInteractionReportData(this.projectIdSelected);
        }
      }, this.clientIdSelected);
    },
    gotoRegisterProject() {
      this.$router.push({ name: "project", query: {is_create: 1} });
    },
  },
  mounted() {
    // let currentQueryRouter = this.$route.query
    let currentUser = this.$store.getters.currentUser;
    this.currentUser = currentUser;
    // let {project} = getUrlQuery();
    
    // if (currentUser && currentUser.role == "admin") {
    //   listBuyersActive((buyersActive) => {
    //     buyerMarketNormReport().then((reportAvg) => {
    //       this.marketResearchClient = reportAvg.buyer_id;
    //       this.clients = [buyersActive.find(item => item.id == this.marketResearchClient)];
    //       this.clientIdSelected = this.marketResearchClient;
    //       this.initData(project);
    //     });
    //
    //   }, true);
    // } else {
    //   listBuyerMarket((buyersMarket) => {
    //     buyerMarketNormReport().then((reportAvg) => {
    //       this.marketResearchClient = reportAvg.buyer_id;
    //       this.clients = [buyersMarket.find(item => item.id == this.marketResearchClient)];
    //       this.clientIdSelected = this.marketResearchClient;
    //       this.initData(project);
    //     });
    //   }, true);
    //
    // }

    // buyerMarketNormReport().then((reportAvg) => {
      this.$router.push({
        name: 'home',
        query: {
          buyer_id: process.env.VUE_APP_MARKET_RESEARCH_BUYER_ID
        }
      })
    // })
  },
  created() {
    EventBus.$on("resetVideosMarket", this.resetCheckedVideo);
  },
  beforeDestroy() {
    EventBus.$off("resetVideosMarket");
  },
};
</script>
<style lang="scss" scoped>
.top::v-deep {
  @import "@/styles/_top.scss";
  &.top-before {
    margin: -20px;
    display: block;
    height: 100vh;
    @media screen and (min-width: 992px) {
      display: flex;
    }
    .side-left {
      background-color: $green;
      color: $white;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 41.32vw;
      .title-side-left {
        .title-large {
          font-weight: bold;
          font-size: 36px;
          line-height: 38px;
          text-align: center;
        }
        .title-small {
          font-style: normal;
          font-weight: bold;
          @include font(xl);
        }
        .btn-register {
          padding: 10px 0px;
          margin-top: 32px;
          text-align: center;
        }
      }
    }
    .side-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      background-color: $green;
      .img-logo {
        position: absolute;
      }
    }
  }
}
</style>
<style type='text/css'>
@-moz-document url-prefix() {
  .video-list .body {
    direction: ltr !important;
    scrollbar-width: thin
  }
}
.background .loader {
  border: 3px solid #49ab94;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin : auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
