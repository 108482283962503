<template>
  <div v-if="videosGrid.length" class="mt-5">
    <div class="d-flex justify-content-between mt-5 top-menu">
      <div class="d-flex align-items-center">
        <span class="number-of-video mr-4">動画数：{{ videos.length }}件</span>
        <div class="d-flex align-items-center">
          <i
            class="icon icon-checkbox"
            :class="{ checked: checkedAllVideos }"
            @click="checkAllVideos"
          ></i>
          <div class="item-name pl-2">全選択</div>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex align-items-center" @click="changeGridListVideo(1)">
          <i
            class="icon icon-grid-video"
            :class="{ checked: grid_list_video === 1 }"
          ></i>
          <div
            class="item-name item-name-default pl-1 cursor-pointer"
            :class="{ 'item-name-active': grid_list_video === 1 }"
          >
            タイル
          </div>
        </div>
        <div class="ml-4 mr-4 my-auto border-left-default"></div>
        <div class="d-flex align-items-center" @click="changeGridListVideo(2)">
          <i
            class="icon icon-list-video"
            :class="{ checked: grid_list_video === 2 }"
          ></i>
          <div
            class="item-name item-name-default pl-1 cursor-pointer"
            :class="{ 'item-name-active': grid_list_video === 2 }"
          >
            リスト
          </div>
        </div>
      </div>
    </div>
    <div v-show="grid_list_video === 1" class="video-grid mt-5">
      <video-chart-list
        v-for="video in videosGrid"
        :key="video.id"
        :video="video"
        :selectedVideo="selectedVideo"
        @selectVideo="selectVideo"
      ></video-chart-list>
      <scroll-loader :loader-method="loadMoreData" :loader-disable="loadDisable"
                     :loader-color="'#49ab94'"></scroll-loader>
    </div>
    <div v-show="grid_list_video !== 1" class="row">
      <video-info-list
        :stepSelected="stepSelected"
        :videos="videos"
        :selectedVideo="selectedVideo"
        :sortVideo="sort"
        :orderVideo="order"
        :disabledEdit="
          currentUser.role !== 'admin' &&
          clientIdSelected !== currentUser.owner_id
        "
        @selectVideo="selectVideo"
        @doSort="doSort"
      >
      </video-info-list>
    </div>
  </div>
</template>
<script>
import VideoInfoList from "@/components/Top/Step-3/VideoInfoList";
import VideoChartList from "@/components/Top/Step-3/VideoChartList";
import {reportTopStep3} from "@/commons/api_service";

export default {
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
    checkAllVideos: {
      type: Function, default: () => {
      }
    },
    selectedVideo: {type: Array, default: () => []},
    sort: {type: String, default: ""},
    order: {type: String, default: ""},
    clientIdSelected: {type: Number, default: 0},
    selectVideo: {
      type: Function, default: () => {
      }
    },
    doSort: {
      type: Function, default: () => {
      }
    },
    checkedAllVideos: {type: Boolean, default: false},
    grid_list_video: {type: Number, default: 1},
    changeGridListVideo: {
      type: Function, default: () => {
      }
    },
    stepSelected: {
      type: Number,
      default: 3
    },
  },
  components: {
    VideoChartList,
    VideoInfoList,
  },
  data() {
    return {
      currentUser: {},
      videosData: [],
      videosGrid: [],
      limit: 5,
      page: 1,
      loadDisable: false,
    };
  },
  methods: {
    loadMoreData() {
      this.getVideos()
    },
    getVideos() {
      this.page += 1;
      this.loadDisable = true;
      this.videosGrid = this.videos.slice(0, this.page * this.limit);
      this.loadDisable = this.videosGrid.length == this.videos.length;
    },
    resetListGrid() {
      this.videosGrid = this.videosData.slice(0, this.limit)
      this.page = 1;
      this.loadDisable = false;
    },
    initVideosData() {
      let query_param_report = {
        buyer_id: this.clientIdSelected,
        video_ids: this.videos.map(item => item.id).join(",")
      }

      if(this.videos[0]?.deliver_period_type == 3){
        let vm = this;
        reportTopStep3(query_param_report, function (data) {
          if(Object.prototype.hasOwnProperty.call(data, 'report_data') && data.report_data.length) {
            vm.videosData = JSON.parse(JSON.stringify(vm.videos.map(function (item) {
              let data_report = data.report_data.filter(video => video.video_id == item.id)
              item.report_data = JSON.parse(JSON.stringify(data_report[0] ? data_report[0] : []))
              return item
            })))
            vm.resetListGrid()
          }
        })
      }
    }
  },
  watch: {
    grid_list_video: function () {
      this.resetListGrid()
    },
    videos: {
      handler: function () {
        this.initVideosData()
      },
      deep: true,
    }
  },
  mounted() {
    this.initVideosData()
    this.currentUser = this.$store.getters.currentUser;
    this.initVideosData()
  },
};
</script>
<style scoped lang="scss">
.top-menu {
  padding-right: 102px;
}

.video-grid {
  margin-top: 30px;
}
</style>