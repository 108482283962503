<template>
  <div class="home-compare-label-form-contain">
    <div class="form-group large flex flex-col mb-0">
      <label>比較するラベルを選択</label>
      <div class="form-group sub-form-group large flex flex-col">
        <label class="sub-label mt-2 mb-0">1つ目の比較対象</label>
        <RadioList
          :radioList="firstComparisonTargetList"
          :idSelected="firstComparisonTargetSelectedId"
          :keyName="'firstComparisonTarget'"
          @changeIdSelected="changeFirstComparisonTarget($event)"
        ></RadioList>
      </div>
      <div class="form-group sub-form-group large flex flex-col">
        <label class="sub-label mb-0">2つ目の比較対象</label>
        <RadioList
          :radioList="secondComparisonTargetList"
          :idSelected="secondComparisonTargetSelectedId"
          :keyName="'secondComparisonTarget'"
          @changeIdSelected="changeSecondComparisonTarget($event)"
        ></RadioList>
      </div>
    </div>
  </div>
</template>
<script>
import RadioList from "@/components/Top/HomeCompare/RadioList.vue"
import {required} from "vuelidate/lib/validators";

export default {
  props: {

  },
  components: {
    RadioList
  },
  data() {
    return {
      firstComparisonTargetSelectedId: null,
      firstComparisonTargetList: [
        {
          value: 1,
          text: "進研ゼミ中学講座",
        },
        {
          value: 2,
          text: "進研ゼミ小学講座",
        },
        {
          value: 3,
          text: "ラベル",
        },
        {
          value: 4,
          text: "ラベル",
        },
        {
          value: 5,
          text: "ラベル",
        },
        {
          value: 6,
          text: "ラベル",
        },
        {
          value: 7,
          text: "ラベル",
        },
        {
          value: 8,
          text: "ラベル",
        },
      ],
      secondComparisonTargetSelectedId: null,
      secondComparisonTargetList: [
        {
          value: 1,
          text: "進研ゼミ中学講座",
        },
        {
          value: 2,
          text: "進研ゼミ小学講座",
        },
        {
          value: 3,
          text: "ラベル",
        },
        {
          value: 4,
          text: "ラベル",
        },
        {
          value: 5,
          text: "ラベル",
        },
        {
          value: 6,
          text: "ラベル",
        },
        {
          value: 7,
          text: "ラベル",
        },
        {
          value: 8,
          text: "ラベル",
        },
      ],
    }
  },

  validations() {
    const validate = {
      firstComparisonTargetSelectedId: {
        required,
      },
      secondComparisonTargetSelectedId: {
        required,
      },
    }
    return validate
  },

  methods: {
    changeFirstComparisonTarget(newValue) {
      this.firstComparisonTargetSelectedId = newValue
      this.setDisabledButtonCompare()
    },

    changeSecondComparisonTarget(newValue) {
      this.secondComparisonTargetSelectedId = newValue
      this.setDisabledButtonCompare()
    },

    setDisabledButtonCompare() {
      this.$v.$touch()
      this.$emit('setDisabledButtonCompare', this.$v.$error)
    }

  },

  watch: {

  },

  created() {
    this.setDisabledButtonCompare()
  },

  destroyed() {

  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/root";

.home-compare-label-form-contain {
  .sub-form-group {
    padding-left: 34px;
  }
}
</style>
