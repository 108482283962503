<template>
  <div class="modal add-label modal-show" v-show="isShown" ref="modal">
    <div class="modal-content">
      <div class="header">
        <div class="btn-close" @click="closeModal()">
          <i class="icon icon-close"></i>
        </div>
      </div>
      <div class="body">
        <div class="form-group w-100 mb-0">
          <label>ラベルの追加</label>
          <input
              type="text"
              class="form-control"
              :class="{ error: $v.labelName.$error || !$v.labelName.xssCheckFull || !$v.labelName.maxLength }"
              placeholder="ラベル名"
              maxlength="255"
              v-model.trim="labelName"
          />
          <span class="error error-message" v-if="!$v.labelName.xssCheckFull">
            {{ $t("validation.xss_check_full", {field: $t("ラベルの追加")}) }}
          </span>
          <span class="error error-message" v-if="!$v.labelName.maxLength">
            {{ $t("validation.max_length", {field: $t("ラベルの追加"), length: 30}) }}
          </span>
          <div class="btn-submit" :class="[$v.labelName.$error || !$v.labelName.xssCheckFull || !$v.labelName.maxLength? 'mt-3' : 'mt-7']">
            <button
                class="btn btn-primary button-download-pdf"
                @click="showConfirm()"
                :disabled="!labelName || $v.labelName.$error || !$v.labelName.xssCheckFull || !$v.labelName.maxLength"
            >
              追加
            </button>
          </div>
        </div>
      </div>
      <modal-confirm
          :message="message"
          @confirm="save()"
          v-if="isShowModalConfirm"
          @closeModalConfirm="isShowModalConfirm = false"
      ></modal-confirm>
    </div>
  </div>
</template>
<script>
import EventBus from "../../commons/event_bus";
import ModalConfirm from "../Modal/ModalConfirm.vue";
import {maxLength} from "vuelidate/lib/validators";
import {xssCheckFull} from "@/commons/rules";

export default {
  props: {
    isShown: Boolean,
    clientId: [Number, String]
  },
  components: {
    ModalConfirm,
  },
  data() {
    return {
      labelName: '',
      message: "登録します、よろしいですか？",
      submitted: false,
      isShowModalConfirm: false
    };
  },
  validations() {
    return {
      labelName: {
        maxLength: maxLength(30), xssCheckFull
      }
    }
  },
  methods: {
    showConfirm() {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.isShowModalConfirm = true
    },
    closeModal() {
      this.labelName = ''
      this.$emit("closeModal")
    },
    save() {
      let url = this.$router.resolve({
        name: "api.label.create",
      }).href;
      let params = {
        buyer_id: this.clientId,
        label_name: this.labelName,
      };
      this.$http2.post(
          url,
          params,
          () => {
            this.$toasted.success(this.$t("messages.register_success"));
            this.isShowModalConfirm = false;
            this.closeModal();
            this.$emit("loadPage");
          },
          () => {
            this.isShowModalConfirm = false;
          }
      );
    },
    openFormAddSegment() {
      EventBus.$emit("toggleModal", "add-label")
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.add-label {
  .modal-content {
    max-height: 186px;
    min-height: 0;

    .form-group {
      label {
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      input {
        max-width: 266px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding-top: 13px;
        padding-bottom: 13px;

        &::placeholder {
          color: #999999;
        }

        &.error {
          border: 1px solid #ce6060;
          background-color: #fef4f4;

          &::placeholder {
            color: #ce6060;
          }
        }
      }

      .btn {
        line-height: 20px;
      }
    }
  }
}
</style>
