<template>
    <div class="modal setting-average">
        <div class="modal-content" style="width : 594px">
            <div class="header">
                <div class="btn-close" @click="closeModal" data-compare-report="action">
                    <i class="icon icon-close"></i>
                </div>
                <div class="modal-title">平均値設定</div>

            </div>
            <div class="body">
                <div class="description">平均値に含む動画を選択します。選択した動画は全て平均値に含まれます。</div>
                <div class="list-project mt-8">
                    <div class="title">平均値に含む動画</div>
                    <div class="list-item mt-2">
                        <div class="mr-5 mb-2" v-for="video in videosToSelect" :key="'video-' + video.id" :id="'video-' + video.id">
                            <div class="item" @click="checkedVideo(video)" data-compare-report="action">
                                <i class="icon icon-checkbox" :class="{ checked: video.checked }" ></i>
                                <div class="item-name pl-2">
                                    {{ video.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="list-video-length mt-8">
                    <div class="title">動画の長さ別表示</div>
                    <div class="list-item mt-2">
                        <div class="mr-5 mb-2" v-for="time in videoTimeRanges" :key="'video-time-range-' + time.value">
                            <div class="item" @click="checkedTimeRange(time)" data-compare-report="action">
                                <i class="icon icon-checkbox" :class="{ checked: time.checked }" ></i>
                                <div class="item-name pl-2">
                                    {{ time.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-submit mt-5">
                  <button
                    :disabled="!videoTimeRangesSelected.length"
                    class="btn btn-primary button-download-pdf"
                    @click="settingAverage"
                    data-compare-report="action"
                  > 設定する
                </button></div>
            </div>
        </div>
    </div>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  listProject,
} from "@/commons/api_service";
import router from '../../routes';
import { videoTimeRange } from "@/commons/constants"
import EventBus from '../../commons/event_bus';
import {listVideoPromise} from "../../commons/api_service";
export default {
    props: {
        buyerId: Number,
        videos: Array,
        checkedAvgIds: {type: Array, default: () => []},
        step: {type: Number, default: 2},
    },
    watch : {

    },
    data () {
        return {
            videosToSelect : [],
            videoTimeRanges : videoTimeRange,
            videoIdsSelected : [],
            videoTimeRangesSelected : [] ,
            isSetting : false,
            videoIds : [],
            videoTimeRangeValues : [],
            key : 1,
            commonArr: [],
            temporaryArr: [],
            clickDefault: false,
        }
    },
    methods : {
        getVideos() {
            let query_videos = this.$route.query
            if(this.step === 1) query_videos.deliver_period_type = '1';
            const listVideosPromise = listVideoPromise(query_videos)
            listVideosPromise.then((data) => {
                this.videosToSelect = data
                this.videosToSelect.forEach(item => {
                    this.$set(item, "checked", true)
                })
                this.videoIdsSelected = this.videosToSelect.map(item => item.id);
                this.videoIds = [...this.videoIdsSelected]
            })
        },

        closeModal() {
            let checkingVideo, checkingTimeRange;
            this.videoIdsSelected = this.videoIds;
            this.videoTimeRangesSelected = this.videoTimeRangeValues;
            this.videosToSelect.forEach(item => {
                checkingVideo = this.checkingVideo(item.id)
                if (checkingVideo) {
                    this.$set(item, "checked", true)
                } else {
                    this.$set(item, "checked", false)
                }
            })
            this.videoTimeRanges.forEach(item => {
                checkingTimeRange = this.checkingTimeRange(item.value);
                if (checkingTimeRange) {
                    this.$set(item, "checked", true)
                } else {
                    this.$set(item, "checked", false)
                }
            })
            this.$emit('closeModal')
        },
        checkingVideo(id){
            let result;
            result = this.videoIds.find(item => item == id);
            if (result) return true;
            else return false;
        },
        checkingTimeRange(value){
            let result;
            result = this.videoTimeRangeValues.find(item => item.value == value );
            if (result) return true;
            else return false;
        },
        checkedVideo(video){
            this.$set(video, "checked", !video.checked);
            let index = this.videoIdsSelected.findIndex((e) => e == video.id);
            if (video.checked) {
                this.videoIdsSelected.push(video.id)
            } else {
                this.videoIdsSelected.splice(index, 1)
            }
        },
        checkedTimeRange(time){
            this.$set(time, "checked", !time.checked);
            let index = this.videoTimeRangesSelected.findIndex((e) => e.value == time.value);
            if (time.checked) {
                this.videoTimeRangesSelected.push(time)
            } else {
                this.videoTimeRangesSelected.splice(index, 1);
            }
        },
        reloadSettingAverage(){
            this.videoTimeRanges.forEach(item => {
                this.$set(item, "checked", false)
            })
            this.videosToSelect.forEach(item => {
                this.$set(item, "checked", true)
            })
            this.videoIdsSelected = this.videosToSelect.map(item => item.id);
            this.videoIds = [...this.videoIdsSelected];
            let defaultTimeRange = this.videoTimeRanges[0];
            this.$set(defaultTimeRange , "checked" , true);
            this.videoTimeRangesSelected = this.videoTimeRanges.filter(item => item.checked);
            this.videoTimeRangeValues = [...this.videoTimeRangesSelected]
        },
        // api
        settingAverage(){
            let query = {
                buyer_id : this.$props.buyerId,
            }
            let url = router.resolve({name: this.step === 2 ? 'api.buyer.videos_norm' : 'api.buyer.videos_norm_promotion', params: query}).href;
            let videoTimeRangeSelected = this.videoTimeRangesSelected.sort(function(a, b){return a.value-b.value})
            let params;
            if (this.videoIdsSelected && this.videoIdsSelected.length == 1) {
                params = {
                    video_time_range : videoTimeRangeSelected.map(item => item.value) + '',
                    video_ids : this.videoIdsSelected + '',
                    interaction_tally: this.step === 2 ? undefined : 1
                }
                this.$http2.get(url, params, (data) => {
                    this.$emit('closeModal');
                    let dataSetting = {
                        videoTimeRange : videoTimeRangeSelected,
                        videoIds : this.videoIdsSelected,
                        summaryAvg : data
                    }
                    this.$emit('setSeriesData', dataSetting);
                    this.isSetting = true;
                    this.videoIds = [...this.videoIdsSelected];
                    this.videoTimeRangeValues = [...this.videoTimeRangesSelected]
                }, (err) => {
                    this.$http2.showToastError(err.body.Message)
                });
            }
            else if (this.videoIdsSelected && this.videoIdsSelected.length < this.videosToSelect.length) {
                let response = {};
                let promises = []
                for (let time of videoTimeRangeSelected) {
                    promises.push(this.getSummary(time))
                } 
                let index1 = videoTimeRangeSelected.findIndex(item => item.id == "avg")
                let index2 = videoTimeRangeSelected.findIndex(item => item.id == "avg-0-15")
                let index3 = videoTimeRangeSelected.findIndex(item => item.id == "avg-16-30")
                let index4 = videoTimeRangeSelected.findIndex(item => item.id == "avg-31-60")
                let index5 = videoTimeRangeSelected.findIndex(item => item.id == "avg-61")
                Promise.all(promises).then((data) => {
                    if (index1 != -1 && data[index1] && Object.keys(data[index1]).length > 0) {
                        response['2_avg_norm'] = data[index1]['2_avg_norm']
                        response['2_detail_norm'] = data[index1]['2_detail_norm']
                        response['3_avg_norm'] = data[index1]['3_avg_norm']
                        response['3_detail_norm'] = data[index1]['3_detail_norm']
                        response['total_avg_norm'] = data[index1]['total_avg_norm']
                        response['total_detail_norm'] = data[index1]['total_detail_norm']
                    }
                    if (index2 != -1 && data[index2] && Object.keys(data[index2]).length > 0) {
                        response['2_0_15_avg_norm'] = data[index2]['2_avg_norm']
                        response['2_0_15_detail_norm'] = data[index2]['2_detail_norm']
                        response['3_0_15_avg_norm'] = data[index2]['3_avg_norm']
                        response['3_0_15_detail_norm'] = data[index2]['3_detail_norm']
                        response['total_0_15_avg_norm'] = data[index2]['total_avg_norm']
                        response['total_0_15_detail_norm'] = data[index2]['total_detail_norm']      
                    }
                    if (index3 != -1 && data[index3] && Object.keys(data[index3]).length > 0) {
                        response['total_16_30_avg_norm'] = data[index3]['total_avg_norm']
                        response['2_16_30_avg_norm'] = data[index3]['2_avg_norm']
                        response['3_16_30_avg_norm'] = data[index3]['3_avg_norm']
                        response['total_16_30_detail_norm'] = data[index3]['total_detail_norm']; 
                        response['2_16_30_detail_norm'] = data[index3]['2_detail_norm']
                        response['3_16_30_detail_norm'] = data[index3]['3_detail_norm']      
                    }
                    if (index4 != -1 && data[index4] && Object.keys(data[index4]).length > 0) {
                        response['total_31_60_avg_norm'] = data[index4]['total_avg_norm']
                        response['2_31_60_avg_norm'] = data[index4]['2_avg_norm']
                        response['3_31_60_avg_norm'] = data[index4]['3_avg_norm']
                        response['total_31_60_detail_norm'] = data[index4]['total_detail_norm']
                        response['2_31_60_detail_norm'] = data[index4]['2_detail_norm']
                        response['3_31_60_detail_norm'] = data[index4]['3_detail_norm']   
                    }
                    if (index5 != -1 && data[index5] && Object.keys(data[index5]).length > 0) {
                        response['total_61_avg_norm'] = data[index5]['total_avg_norm']
                        response['2_61_avg_norm'] = data[index5]['2_avg_norm']
                        response['3_61_avg_norm'] = data[index5]['3_avg_norm']
                        response['total_61_detail_norm'] = data[index5]['total_detail_norm']
                        response['2_61_detail_norm'] = data[index5]['2_detail_norm']
                        response['3_61_detail_norm'] = data[index5]['3_detail_norm']   
                    }
                    let dataSetting = {
                        videoTimeRange : videoTimeRangeSelected,
                        videoIds : this.videoIdsSelected,
                        summaryAvg : response
                    }
                    this.$emit('setSeriesData', dataSetting);
                    this.$emit('closeModal');
                    this.isSetting = true;
                    this.videoIds = [...this.videoIdsSelected];
                    this.videoTimeRangeValues = [...this.videoTimeRangesSelected];
                })
            } 
            else {
                params = {
                    video_time_range : videoTimeRangeSelected.map(item => item.value) + '',
                    video_ids : this.videoIdsSelected + '',
                    interaction_tally: this.step === 2 ? undefined : 1
                }
                this.$http2.get(url, params, (data) => {
                    this.$emit('closeModal');
                    let dataSetting = {
                        videoTimeRange : videoTimeRangeSelected,
                        videoIds : this.videoIdsSelected,
                        summaryAvg : data
                    }
                    this.$emit('setSeriesData', dataSetting);
                    this.isSetting = true;
                    this.videoIds = [...this.videoIdsSelected];
                    this.videoTimeRangeValues = [...this.videoTimeRangesSelected]
                }, (err) => {
                    this.$http2.showToastError(err.body.Message)
                });
            }
            this.commonArr = [];
            for(let n = 0; n < this.checkedAvgIds.length; n++){
                for(let i = 0; i < videoTimeRangeSelected.length; i++){
                    if(this.checkedAvgIds[n] == videoTimeRangeSelected[i].id){
                        this.commonArr.push(videoTimeRangeSelected[i].id);
                    }
                }
            }
            if(this.clickDefault && videoTimeRangeSelected[0].id == 'avg'){
                this.clickDefault = false;
                for(let n =0; n < this.commonArr.length; n++){
                    if(this.commonArr[n] == 'avg'){
                        let index = this.commonArr.indexOf('avg')
                        this.commonArr.splice(index,1);
                    }
                }
                this.commonArr.push(videoTimeRangeSelected[0].id);
                this.temporaryArr = this.commonArr;
                this.$emit('checkedAvgIds', this.temporaryArr);
                return;
            }
            this.clickDefault = false;
            this.temporaryArr = this.commonArr;
            this.$emit('checkedAvgIds', this.temporaryArr);
        },
        getClickFlg(value){
            this.clickDefault = value
        },
        getSummary(time){
            let query = {
                buyer_id : this.$props.buyerId,
            }
            let params = {
                video_ids : this.videoIdsSelected + '',
                video_time_range : time.value,
                interaction_tally: this.step === 2 ? undefined : 1
            }
            return new Promise((resolve, reject) => {
                let url = router.resolve({name: this.step === 2 ? 'api.buyer.videos_norm' : 'api.buyer.videos_norm_promotion', params: query}).href;
                this.$http2.get(url, params, data => {
                resolve(data);
            }, error => {
                this.$http2.showToastError(error.body.Message)
                reject(error);
            });
        })
        }
    },
    mounted(){
        this.getVideos()
        this.videoTimeRanges.forEach(item => {
            this.$set(item, "checked", false)
        })
        if (!this.isSetting) {
            let defaultTimeRange = this.videoTimeRanges[0];
            this.$set(defaultTimeRange , "checked" , true);
        }
        this.videoTimeRangesSelected = this.videoTimeRanges.filter(item => item.checked);
        this.videoTimeRangeValues = [...this.videoTimeRangesSelected]
    },
    created(){
        EventBus.$on('reloadSettingAverage',this.reloadSettingAverage)
        EventBus.$on('clickDefaultBack' ,(value) => {
            this.getClickFlg(value)
        })
    },
    destroyed(){
        EventBus.$off('reloadSettingAverage',this.reloadSettingAverage)

    }
}
</script>
<style lang="scss" scoped>
@import '@/styles/_setting_average_modal.scss'
</style>