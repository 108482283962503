<template lang="">
  <div class="style">
    <div class="group-option-select">
      <div class="show-register">
        <div class="label">登録人数</div>
        <div class="number" id="number-record">{{ totalRecord }}</div>
      </div>
      <div class="option-select-right">
        <div class="search-member">
          <i class="icon icon-search"></i>
          <input type="text" placeholder="検索" v-model="textSearch" />
        </div>
        <button class="btn btn-primary btn-register" @click="addUser">
          新規登録
        </button>
        <add-user-modal  v-if="showUserModal" @closeModal="closeUserModal"></add-user-modal>
      </div>
    </div>
    <div class="table-labels table-wrapper">
      <table class="table table-scroll" id="datatable">
        <thead class="">
          <tr>
            <th @click="sortName">
              <div class="header">
                名前
                <i class="icon icon-table-arrow-top ml-1" v-if="orderDesc"></i>
                <i class="icon icon-table-arrow-bottom ml-1" v-else></i>
              </div>
            </th>
            <th>メールアドレス</th>
            <th>ステータス</th>
            <th>パスワード発行メール再送信</th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="button-bottom">
      <div class="button-load">
        <button
          v-if="loadedRecord < totalRecord"
          class="btn btn-primary-outline"
          @click="loadMoreData"
        >
          もっとみる
        </button>
      </div>
      <div class="button-logout" @click="logout">
        <span style="margin-right : 2px">ログアウト</span>
        <i class="icon icon-arrow-right"></i>
      </div>
    </div>
    <modal-confirm  v-if="statusModal&&isShowModalConfirm" :message="modalMessage" @confirm="switchStatus"  @closeModalConfirm="isShowModalConfirm = false;statusModal"></modal-confirm>
  </div>
</template>

<script>
import ModalConfirm from "./../Modal/ModalConfirm.vue";
import AddUserModal from "./AddUserModal.vue";
import router from "../../routes/index";
const jQuery = require("jquery");
jQuery.DataTable = require("datatables.net");
import {
  getLabelStatus,
  textEllipsis,
  btnResendPassword,
} from "@/commons/helpers";
export default {
  data() {
    return {
      DataTable: null,
      totalRecord: 0,
      loadedRecord: 0,
      page: 1,
      listLimit: 20,
      data: [],
      orderDesc: false,
      textSearch: null,
      userId: null,
      status: null,
      modalMessage: "",
      statusModal: "",
      showUserModal: false,
      isShowModalConfirm : false
    };
  },
  components: {
    ModalConfirm,
    AddUserModal
  },
  watch: {
    textSearch: function() {
      this.data = [];
      this.page = 1;
      this.DataTable.draw();
    },
  },
  methods: {
    closeUserModal() {
      this.showUserModal = false
    },
    getListMember() {
      let vm = this;
      let url;
      let currentUser = this.$store.getters.currentUser;
      if (currentUser.role == "admin") {
        url = this.$router.resolve({
          name: "api.admins",
        }).href;
      } else {
        url = this.$router.resolve({
          name: "api.users",
        }).href;
      }

      this.DataTable = jQuery("#datatable").DataTable({
        stateSave: true,
        language: vm.$t("datatable"),
        autoWidth: false,
        destroy: true,
        bPaginate: false,
        bLengthChange: false,
        bFilter: false,
        bInfo: false,
        bAutoWidth: false,
        ajax: (params, callback) => {
          params = {
            page: vm.page,
            limit: vm.listLimit,
            text: vm.textSearch,
            sort: "name",
            order: vm.orderDesc ? "desc" : "asc",
          };

          this.$http2.get(url, params, (data) => {
            vm.totalRecord = data.all;

            for (let index in data.items) {
              data.items[index].resend_password = !data.items[index].is_loggedin
                ? btnResendPassword(data.items[index].id, data.items[index].email)
                : "";
              data.items[index].status = getLabelStatus(
                data.items[index].status,
                data.items[index].id
              );
              data.items[index].email = textEllipsis(data.items[index].email);
              data.items[index].name = textEllipsis(data.items[index].name);
            }
            vm.data = [...vm.data, ...data.items];
            vm.loadedRecord = vm.data.length;
            callback({
              recordsTotal: data.all,
              recordsFiltered: data.all,
              data: vm.data,
            });
          });
        },
        processing: true,
        serverSide: true,
        columns: [
          { data: "name", orderable: false },
          { data: "email", orderable: false },
          { data: "status", orderable: false },
          {
            data: "resend_password",
            className: "text-center",
            orderable: false,
          },
        ],
      });
    },
    sortName() {
      this.listLimit = this.loadedRecord;
      this.page = 1;
      this.orderDesc = !this.orderDesc;
      this.data = [];
      this.DataTable.draw();
    },
    loadMoreData() {
      this.listLimit = 20;
      this.page = Math.round(this.loadedRecord / this.listLimit);
      this.page += 1;
      this.DataTable.draw();
    },
    logout() {
      localStorage.clear();
      // router.push({ name: "login" });
      router.go("login");
    },
    openModal() {
      let vm = this;
      jQuery("#datatable tbody").on("click", "button", function() {
        let classList = jQuery(this)
          .attr("class")
          .split(" ");
        let userId = classList[2].split("-")[1];
        let status = classList[1].split("-")[1];
        vm.userId = parseInt(userId);
        vm.status = parseInt(status);
        if (vm.status == 1) {
          vm.modalMessage =
            "ステータスを無効に変更します、よろしいですか？";
        } else {
          vm.modalMessage =
            "ステータスを有効に変更します、よろしいですか？";
        }
        vm.statusModal = true;
        vm.isShowModalConfirm = true;
      });
    },
    switchStatus() {
      let vm = this;
      let status;
      if (this.status == 1) {
        status = 0;
      } else {
        status = 1;
      }
      let params = {
        ids: [this.userId],
        status: status,
      };
      let url = this.$router.resolve({
        name: "api.users.status",
      }).href;
      this.$http2.put(url, params, () => {
        this.isShowModalConfirm = false;
        vm.text = "";
        vm.data = [];
        vm.DataTable.draw();
      });
    },
    resendPassword() {
      jQuery("#datatable tbody").on("click", ".resend-btn", (event) => {
        let user_id = jQuery(event.currentTarget).data('id');
        let email = jQuery(event.currentTarget).data('email');
        let url = this.$router.resolve({
          name: "api.users.resend-pw-mail",
          params: {
            id: user_id
          }
        }).href;
        let data = {
          email: email
        };
        this.$http2.put(url, data, () => {
          this.$toasted.success(this.$t("messages.resend_password_success"));
        }).catch(() => {
            this.$toasted.show(this.$t("messages.resend_password_fail"));
        });
      });
    },
    addUser() {
      this.showUserModal = true;
    }
  },
  mounted() {
    this.getListMember();
    this.openModal();
    this.resendPassword();
  },
};
</script>
<style lang="scss" scoped>
.style::v-deep {
  @import "@/styles/_user";
}
</style>
