<template>
  <div>
    <div v-if="videos.length">
      <template>
        <div
            v-for="video in videos.filter(item => item.avg_report != null)"
            class="card card-video card-subtitle"
            :key="'cart-video-' + video.id"
        >
          <div class="content">
            <div class="title">
              {{ video.name }}
            </div>
            <div class="body">
              <div class="body-left">
                <img class="card-image" :src="video.image_url + '2.jpg'"/>
              </div>
              <div class="body-right multiple-row">
                <div class="interaction-name">{{ interactionQuestion.name }}</div>
                <div class="item">
                  <i :class="'icon icon-reaction mr-2'" :style="{ backgroundImage: `url(${interactionQuestion.image_url})` }"></i>
                  <div class="item-number">{{ video.avg_report ? Number(video.avg_report).toFixed(1) : "0.0" }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else>
      <div class="card-download-manual mt-5">
        <img class="img-logo" src="images/svg/logo_download_manual.svg"/>
        <div class="title-large mt-2">Welcome to PlayAds</div>
        <div class="title-small mt-1">動画コンテンツの反応値を測定して<br>安全かつ効果的に配信しよう！</div>
        <div
            :style="disabled ? styleButton : {}"
            class="btn btn-download-manual btn-primary-outline mt-4"
            @click="downloadManual()"
        >
          マニュアルダウンロード
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    videos: Array,
    fromRouter: String,
    firstVideo: Object,
    interactionQuestion: Object,
  },
  data() {
    return {
      currentUser: {},
      disabled: false,
      styleButton: {
        opacity: 0.75,
        cursor: 'default'
      }
    };
  },
  computed: {},
  methods: {
    downloadManual() {
      if (!this.disabled) {
        this.disabled = true;
        let url = this.$router.resolve({name: "api.download.manual"}).href;
        let params = {
          user_id: this.currentUser.user_id
        }
        this.$http2.get(url, params, (data) => {
              let url = data.download_url;
              let label = url.substr(url.lastIndexOf("/") + 1);
              this.$http2.downloadPDF(url, label, () => {
                    this.disabled = false
                  },
                  () => {
                    this.disabled = false
                  });
            },
            () => {
              this.disabled = false
            }
        );
      }
    },
  },
  watch: {},
  mounted() {
    this.currentUser = this.$store.getters.currentUser;
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_variable.scss";

.card {
  margin-bottom: 20px;
}

.title {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 27px !important;
  color: $black;
  margin-bottom: 5px;
}

.interaction-name {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: $middle-black;
}

.item-number {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  color: $black;
}

.card-download-manual {
  height: 347px;
  box-shadow: 0 2px 8px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #49ab94;

  .title-large {
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
  }

  .title-small {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  }

  .btn-download-manual {
    padding: 10px 0;
    width: 90%;
    display: flex;
    justify-content: center;
  }
}
</style>
