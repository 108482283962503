<template lang="">
    <div class="menu-list">
        <template v-for="(menu) in menuList">
            <div :key="menu.id" class="menu-tab" :class="{'active': menu.id == selected}" @click="clickMenuTab(menu.id)">
                {{menu.name}}
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        menuList: {
            type: Array,
        },
        selected: Number
    },
    data() {
        return {
        }
    },
    methods: {
        clickMenuTab(id) {
            this.$emit('change', id)
        },
    },
}
</script>
<style lang="scss" scoped>
    @import "@/styles/_variable";
    .menu-list {
        width: 100%;
        display: flex;
        .menu-tab {
            width: 100%;
            display: flex;
            border-width: 1px;
            border-style: solid;
            @include font(l);
            font-weight: bold;
            cursor: pointer;
            padding: 14px 0px;
            justify-content: center;
            align-items: center;
            border-color: $middle-black;
            color: $middle-black;
            &.active {
                color: $white;
                border-color: $green;
                background-color: $green;
            }
            &:first-child {
                border-radius: 8px 0px 0px 8px;
            }
            &:last-child {
                border-radius: 0px 8px 8px 0px;
            }
            .active {
                background-color: $green;
                border-color: $green;
            }
        }
    }
</style>