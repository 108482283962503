<template>
  <div>
    <div class="content-banner d-flex align-items-center flex-column">
      <img class="banner" src="/images/img/target/target_banner.png" alt="banner">
      <div class="title-banner" v-html="formatText($t('texts.target.title_banner'))"></div>
      <div
          class="button-download-file"
          @click="downloadFile"
      >
        <img src="/images/svg/icon_download_pdf.png" alt="download"/>
        <span class="ml-1">資料をダウンロード</span>
      </div>
    </div>
    <div class="list-step d-flex">
      <div class="step-item">
        <span class="number">1</span>
        <img src="/images/img/target/step_1.png" alt="step-1">
        <span class="title-step" v-html="formatText($t('texts.target.title_step_1'))"></span>
        <span class="description-step" v-html="formatText($t('texts.target.description_step_1'))"></span>
      </div>
      <div class="step-item">
        <span class="number">2</span>
        <img src="/images/img/target/step_2.png" alt="step-2">
        <span class="title-step" v-html="formatText($t('texts.target.title_step_2'))"></span>
        <span class="description-step" v-html="formatText($t('texts.target.description_step_2'))"></span>
      </div>
      <div class="step-item">
        <span class="number">3</span>
        <img src="/images/img/target/step_3.png" alt="step-3">
        <span class="title-step" v-html="formatText($t('texts.target.title_step_3'))"></span>
        <span class="description-step" v-html="formatText($t('texts.target.description_step_3'))"></span>
      </div>
      <div class="step-item">
        <span class="number">4</span>
        <img src="/images/img/target/step_4.png" alt="step-4">
        <span class="title-step" v-html="formatText($t('texts.target.title_step_4'))"></span>
        <span class="description-step" v-html="formatText($t('texts.target.description_step_4'))"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    url: String
  },
  methods: {
    formatText(text) {
      return text.replace(/\n/g, '<br>');
    },
    downloadFile() {
      this.$http2.downloadPDF(this.url)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables/_colors.scss";
.content-banner {
  .banner {
    width: 650px;
  }

  .title-banner {
    color: $green;
    text-align: center;
    font-family: "Noto Sans JP";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 51.2px;
    margin-top: 30px;
  }
  .button-download-file {
    cursor: pointer;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: $green;
    margin-top: 20px;

    span {
      color: $white;
      font-family: "Noto Sans JP";
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
    }
  }
}

.list-step {
  margin-top: 40px;
  justify-content: center;
  gap: 50px;

  .step-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .number {
      position: absolute;
      color: $black;
      font-family: "Avenir Next";
      font-size: 72px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      top: -5px;
      left: 10px;
    }

    img {
      width: 250px;
    }

    .title-step {
      color: $black;
      text-align: center;
      font-family: "Noto Sans JP";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-top: 20px;
    }

    .description-step {
      color: $black;
      text-align: center;
      font-family: "Noto Sans JP";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .banner {
    width: 580px !important;
  }
  .list-step {
    .number {
      font-size: 60px !important;
      left: 0 !important;
    }
    .title-step {
      font-size: 15px !important;
      line-height: 20px !important;
    }
    .description-step {
      font-size: 12px !important;
      line-height: 15px !important;
    }
    img {
      width: 200px !important;
    }
  }

  .title-banner {
    font-size: 28px !important;
  }
}
</style>
