export default {
    state: {
        lists: [], // projects,
        interaction_rank: [],// report_interaction_rank,
        isHideGuideMessageTableau: false,
    },
    getters: {
        projects: state => state.lists,
        reportInteractionRankById: state => ad_id =>{
            return state.interaction_rank.find(e => e.ad_id == ad_id)
        },
        isHideGuideMessageTableau: state => state.isHideGuideMessageTableau
    },
    mutations: {
        setProjects(state, projects) {
            state.lists = projects;
        },
        addReportInteractionRank(state, report, ad_id) {
            if (!state.interaction_rank.find(e => e.ad_id == ad_id)) {
                state.interaction_rank.push(report);
            }
            
        },
        setHideGuideMessageTableau(state, value) {
            state.isHideGuideMessageTableau = value
        }

    },
    actions: {}
}