<template lang="">
    <div style="">
        <div class="form-person">
            <div class="form-group w-35">
                <label>ユーザー名 <span class="require">必須</span></label>
                <input
                    class="form-control"
                    type="text"
                    v-model.trim="name"
                    :class="{'error-input': (submitted && $v.name.$invalid)}"
                >
                <div v-if="submitted">
                    <span class="error error-message" v-if="!$v.name.required">{{$t('validation.userPersonUpdate.name.required')}}</span>
                    <span class="error error-message" v-else-if="!$v.name.xssCheck">{{$t('validation.userPersonUpdate.name.xssCheck')}}</span>
                    <span class="error error-message" v-else-if="!$v.name.maxLength">{{$t('validation.userPersonUpdate.name.max-length')}}</span>
                </div>
            </div>
            <div class="form-group w-35">
                <label>メールアドレス <span class="require">必須</span></label>
                <input
                    class="form-control"
                    type="email"
                    v-model.trim="email"
                    :class="{'error-input': (submitted && $v.email.$invalid)}"
                >
                <div v-if="submitted">
                    <span class="error error-message" v-if="!$v.email.required">{{$t('validation.userPersonUpdate.email.required')}}</span>
                    <span class="error error-message" v-else-if="!$v.email.email">{{$t('validation.userPersonUpdate.email.email')}}</span>
                    <span class="error error-message" v-else-if="!$v.email.maxLength">{{$t('validation.userPersonUpdate.email.max-length')}}</span>
                </div>
            </div>
            <div class="form-group w-35">
                <label>現在のパスワード <span class="require">必須</span></label>
                <input
                    class="form-control"
                    type="password"
                    v-model.trim="current_password"
                    :class="{'error-input': (submitted && $v.current_password.$invalid)}"
                >
                <div v-if="submitted">
                    <span class="error error-message" v-if="!$v.current_password.required">{{$t('validation.userPersonUpdate.current_password.required')}}</span>
                    <span class="error error-message" v-else-if="!$v.current_password.minLength">{{$t('validation.userPersonUpdate.current_password.min-length')}}</span>
                    <span class="error error-message" v-else-if="!$v.current_password.maxLength">{{$t('validation.userPersonUpdate.current_password.max-length')}}</span>
                    <span class="error error-message" v-else-if="!$v.current_password.checkPass">{{$t('validation.userPersonUpdate.current_password.regex')}}</span>
                </div>
            </div>
            <div class="form-group w-35">
                <label>登録用パスワード</label>
                <input
                    class="form-control"
                    type="password"
                    v-model.trim="new_password"
                    :class="{'error-input': (submitted && $v.new_password.$invalid)}"
                >
                <div v-if="submitted">
                    <span class="error error-message" v-if="!$v.new_password.minLength">{{$t('validation.userPersonUpdate.new_password.min-length')}}</span>
                    <span class="error error-message" v-else-if="!$v.new_password.maxLength">{{$t('validation.userPersonUpdate.new_password.max-length')}}</span>
                    <span class="error error-message" v-else-if="!$v.new_password.checkPass">{{$t('validation.userPersonUpdate.new_password.regex')}}</span>
                    <span class="error error-message" v-else-if="!$v.new_password.sameAs">{{$t('validation.userPersonUpdate.new_password.check-confirm')}}</span>
                </div>
            </div>
            <div class="form-group w-35">
                <label>確認用パスワード</label>
                <input
                    class="form-control"
                    type="password"
                    v-model.trim="confirm_password"
                >
            </div>
            <div class="form-group display-setting">
                <label>表示設定</label>
                <div class="setting">
                    <div class="setting-on">
                        <input
                            class="radiobtn checked mr-2"
                            id="status-on"
                            type="radio"
                            v-model="status"
                            value="1"
                        >
                        <label for="status-on" class="icon icon-radio">表示</label>
                    </div>
                    <div class="setting-off">
                        <input
                            class="radiobtn mr-2"
                            id="status-off"
                            type="radio"
                            v-model="status"
                            value="0"
                        >
                        <label for="status-off" class="icon icon-radio">非表示</label>
                    </div>
                </div>
                <div class="note">※非表示にするとログインできなくなります。</div>
            </div>
            <div class="btn-submit">
                <button class="btn btn-primary" @click="updateConfirm">
                    更新
                </button>
            </div>
        </div>
        <modal-confirm v-if="isShowModalConfirm"
            :message="modalMessage"
            @confirm="updateOK"
            @closeModalConfirm="isShowModalConfirm = false"
        ></modal-confirm>
    </div>
</template>

<script>
// import router from '../../routes';
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import { xssCheck, checkPass } from "../../commons/rules";
import http2 from "../../commons/http2";
import ModalConfirm from "./../Modal/ModalConfirm.vue";
const jQuery = require("jquery");
jQuery.DataTable = require("datatables.net");
import EventBus from "../../commons/event_bus";

export default {
  data() {
    return {
      name: "",
      email: "",
      current_password: "",
      new_password: "",
      confirm_password: "",
      status: "1",
      submitted: false,
      modalMessage: "",
      isShowModalConfirm: false,
    };
  },
  validations: {
    name: { required, xssCheck, maxLength: maxLength(30) },
    email: { required, email, maxLength: maxLength(255) },
    current_password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(16),
      checkPass,
    },
    new_password: {
      minLength: minLength(8),
      maxLength: maxLength(16),
      checkPass,
      sameAs: sameAs("confirm_password"),
    },
  },
  components: {
    ModalConfirm,
  },
  methods: {
    getUserPerson() {
      let currentUser = this.$store.getters.currentUser;
      let user_id = currentUser.user_id;
      let url = this.$router.resolve({
        name: "api.users.person",
        params: {
          id: user_id,
        },
      }).href;
      http2.get(url, {}, (data) => {
        this.name = data.name;
        this.email = data.email;
        this.status = data.status;
      });
    },
    openModal() {
      let vm = this;
      vm.modalMessage = "更新します、よろしいですか？";
      vm.isShowModalConfirm = true;
    },
    updateConfirm() {
      this.submitted = true;
      let vm = this;
      let error = vm.$v.$invalid;
      if (!error) {
        this.openModal();
      }
    },
    updateOK() {
      this.submitted = true;
      let currentUser = this.$store.getters.currentUser;
      let user_id = currentUser.user_id;
      let url = this.$router.resolve({
        name: "api.users.person-update",
        params: {
          id: user_id,
        },
      }).href;
      let putData = {
        name: this.name,
        email: this.email,
        current_password: this.current_password,
        new_password: this.new_password,
        status: this.status,
      };
      http2.put(
        url,
        putData,
        () => {
          this.isShowModalConfirm = false;
          this.$toasted.success(this.$t("messages.update_success"));
          EventBus.$emit("handleDone");
        },
        () => {
          EventBus.$emit("handleDone");
        }
      );
    },
  },
  mounted() {
    this.getUserPerson();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_user";
</style>