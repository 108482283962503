<template>
  <div>
    <select class="form-control" :name="element" :id="element" :disabled="disabled"></select>
  </div>
</template>
<script>
import {
  setSelect2Data,
  formatDataSelect2,
} from "../../commons/helpers";

export default {
  props: {
    data: Array,
    idSelected: [Number, String],
    isSelected: Boolean,
    element: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    data: function (data) {
      this.initClientsSelect(formatDataSelect2(data));
    },
  },
  methods: {
    initClientsSelect(data) {
      let vm = this;
      setSelect2Data(
          vm.element,
          data,
          false,
          this.handleSelectedClient,
          {
            selected: vm.idSelected,
            minimumResultsForSearch: 0,
            width: "308px",
            height: "52px",
            dropdownCssClass: "dropdown-select2-custom",
          },
          this.isSelected
        );
        if(this.idSelected){
          this.$emit("initSelectedId", vm.idSelected);
        }
    },
    handleSelectedClient(selectedId) {
      this.$emit("select", selectedId);
    },

  },
  mounted() {
    this.initClientsSelect(formatDataSelect2(this.data));
  },
};
</script>
<style lang="scss" scoped>
::v-deep select:disabled ~ .select2.select2-container--disabled .select2-selection {
  cursor: default;
  background-color: #EBEBEB;
}
</style>