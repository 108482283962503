<template>
    <div class="list-segment mt-8">
        <template v-if="reportSegmentRanks.attribute_ids && reportSegmentRanks.attribute_ids.length">
            <div class="label">セグメント別</div>
            <template v-for="attribute_id in reportSegmentRanks.attribute_ids">
                <segment :key="'segment-attribute-' + attribute_id" 
                :attributeData="getAttributeDataById(attribute_id)" 
                :interactionType="interactionType"
                :videos="videos"
                :reportAttributes="getReportAttributesById(attribute_id)"
                :project="project"
                >
                </segment>
            </template>
            
        </template>
    </div>
</template>
<script>
import Segment from '@/components/Top/Segment';
import {
    getAttributesPromise,
    getReportSegmentRanksPromise
} from '@/commons/api_service';

export default {
    props: {
        adId: Number,
        interactionType: Number,
        videos: Array,
        project : Object
    },
    components: {Segment},
    data() {
        return {
            attributes: [],
            reportSegmentRanks: {}
        }
    },
    watch: {
        adId: function() {
            this.initDataSegmentReport();
        }
    },
    methods: {
        initDataSegmentReport() {
            if (this.adId) {
                Promise.all([getAttributesPromise(), getReportSegmentRanksPromise({project_id: this.adId,interaction_tally : 1})])
                .then((data) => {
                    this.attributes = data[0];
                    this.reportSegmentRanks = data[1]
                })
            } else {
                this.attributes = [];
                this.reportSegmentRanks = [];
            }
            
        },
        getAttributeDataById(attribute_id) {
            let attributeData = {};
            if (attribute_id) {
                attributeData = this.attributes.find(e => e.id == attribute_id)
            }
            return attributeData;
        },
        getReportAttributesById(attribute_id) {
            let attributeData = this.getAttributeDataById(attribute_id);
            if (attributeData && attributeData.values) {
                return this.reportSegmentRanks.segment_ranks.filter(report => attributeData.values.find(e => report.attribute_value_id == e.id ));
            }
            return []
        }
    },
    mounted() {
        this.initDataSegmentReport();
    }

};
</script>
<style lang="">
</style>