<template>
  <div>
    <div v-if="!videos.length  && listRank2.length && isProject">
      <div v-for="(item, parent_index) in avgs" :key="parent_index"> 
        <div v-for="n of avgs.length" :key="n">
          <div class="card card-video card-subtitle avg-card" v-if="maxRank2.video_id && iconInteraction.rank2 && iconInteraction.rank2.length && item.id == checkedAvgIds[n-1]">   
            <div class="content">
              <div class="avg-title">
                  <span>{{ item.name }}の平均反応値</span>
              </div>
              <div class="body">
                <div class="body-right avg-right-card">
                  <div class="avg-right-card-content">
                    <div><i class="icon icon-reaction icon-avg-reaction"></i></div>
                    <div class="icon-title">合計</div>
                    <span v-for="(value, index) in summaryTotalAvgName" :key="index">
                      <span v-if="item.value == index && Object.keys(summaryAvgData).length !== 0">
                        {{summaryAvgData[value]}}%
                      </span>
                      <span v-else-if="item.value == index">
                        0%
                      </span>
                    </span>
                  </div>
                  <div class="avg-right-card-content">
                    <div><img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank2" alt=""></div>
                    <div class="icon-title">{{textInteraction.rank2}}</div>
                    <span v-for="(value, index) in leftAvgName" :key="index">
                      <span v-if="item.value == index && Object.keys(summaryAvgData).length !== 0">
                        {{summaryAvgData[value]}}%
                      </span>
                      <span v-else-if="item.value == index">
                        0%
                      </span>
                    </span>
                  </div>
                  <div class="avg-right-card-content" v-if="maxRank3.video_id && iconInteraction.rank3 && iconInteraction.rank3.length">
                    <div><img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank3" alt=""></div>
                    <div class="icon-title">{{textInteraction.rank3}}</div>
                    <span v-for="(value, index) in rightAvgName" :key="index">
                      <span v-if="item.value == index && Object.keys(summaryAvgData).length !== 0">
                        {{summaryAvgData[value]}}%
                      </span>
                      <span v-else-if="item.value == index">
                        0%
                      </span>
                    </span>
                  </div>
                </div>
              </div>   
            </div>
          </div>
        </div>
      </div>
      <div class="card card-video card-subtitle"  v-if="maxRank2.video_id && iconInteraction.rank2 && iconInteraction.rank2.length">
        <div class="content">
          <div class="title mb-3">
            <span class="subtitle">No.1 {{textInteraction.rank2}}動画 </span>
            <div>{{ maxRank2.video_name }}</div>
          </div>
          <div class="body">
            <div class="body-left body-left-avg">
              <img
                class="card-image"
                :src="maxRank2.image_url"
              />
            </div>
            <div class="body-right">
              <img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank2" alt="">
              <div>
                {{ maxRank2.interaction_count | numberWithCommas | addPointZero }}%
              </div>
            </div>
          </div>
        </div>
        <a class="right-card"  @click="gotoDetailVideo(maxRank2)" data-right-card="action">
          <i class="icon icon-arrow-right"></i>
        </a>
      </div>
      <div class="card card-video card-subtitle mt-5"  v-if="maxRank3.video_id && iconInteraction.rank3 && iconInteraction.rank3.length">
        <div class="content">
          <div class="title mb-3">
            <span class="subtitle">No.1 {{textInteraction.rank3}}動画</span>
            <div>{{ maxRank3.video_name }}</div>
          </div>
          <div class="body">
            <div class="body-left body-left-avg">
              <img
                class="card-image"
                :src="maxRank3.image_url"
              />
            </div>
            <div class="body-right">
              <img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank3" alt="">
              <div>{{ maxRank3.interaction_count | numberWithCommas | addPointZero }}%</div>
            </div>
          </div>
        </div>
        <div class="right-card"  @click="gotoDetailVideo(maxRank3)" data-right-card="action">
          <i class="icon icon-arrow-right"></i>
        </div>
      </div>
      <div class="card card-video card-ranking mt-5 ranking-good">
        <div class="card-name">Ranking</div>
        <div class="ranking" v-if="listRank2 && listRank2.length && iconInteraction.rank2">
          <div class="reaction-type mb-3">
            <img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank2" alt="">{{textInteraction.rank2}}
          </div>
          <div
            v-for="good in listRank2"
            :key="'good-rank-' + good.rank"
            :class="'ranking-top-' + good.text_rank"
          >
            <i
              :class="
                'icon ' +
                  'icon-ranking ' +
                  'icon-top-' +
                  good.text_rank +
                  '-' + colorInteraction.rank_2 +
                  ' mr-3'
              "
            ></i>
            {{ good.video_name }}
          </div>
        </div>
        <div class="ranking" v-if="listRank3 && listRank3.length && iconInteraction.rank3">
          <div class="reaction-type mb-3">
            <img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank3" alt="">{{textInteraction.rank3}}
          </div>
          <div
            v-for="bad in listRank3"
            :key="'bad-rank-' + bad.rank"
            :class="'ranking-top-' + bad.text_rank"
          >
            <i
              :class="
                'icon ' +
                  'icon-ranking ' +
                  'icon-top-' +
                  bad.text_rank +
                  '-' + colorInteraction.rank_3 +
                  ' mr-3'
              "
            ></i>
            {{ bad.video_name }}
          </div>
        </div>
      </div>
      <div class="card-download-manual mt-5">
        <img class="img-logo" src="images/svg/logo_download_manual.svg" />
        <div class="title-large mt-2">Welcome to PlayAds</div>
        <div class="title-small mt-1">動画コンテンツの反応値を測定して<br>安全かつ効果的に配信しよう！</div>
        <div
            :style="disabled ? styleButton : {}"
            class="btn btn-download-manual btn-primary-outline mt-4"
            @click="downloadManual()"
            >
            マニュアルダウンロード
        </div>
      </div>
    </div>
    <div v-if="videos.length && isProject">
      <template  v-for="video in videos">
        <div
        class="card card-video card-subtitle mb-4"
        v-if="video.image_url"
        :key="'cart-video-' + video.id"
      >
        <div class="content">
          <div class="title mb-3">
            <div>{{ video.name }}</div>
          </div>
          <div class="body">
            <div class="body-left">
              <img class="card-image" :src="video.image_url + '2.jpg'" />
            </div>
            <div class="body-right multiple-row">
              <div class="item" v-if="iconInteraction.rank2 && iconInteraction.rank2.length && iconInteraction.rank3 && iconInteraction.rank3.length">
                <i style="margin-right : 8px; width : 24px; height: 24px" :class="'icon icon-reaction icon-interaction mr-2'"></i>
                <div class="interaction-percentage">{{  totalInteraction(video.total_2,video.total_3) }}%</div>
              </div>
              <div class="item" v-if="iconInteraction.rank2 && iconInteraction.rank2.length">
                <img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank2" alt="">
                <div class="interaction-percentage">{{ video.total_2 | numberWithCommas | addPointZero }}%</div>
              </div>
              <div class="item" v-if="iconInteraction.rank3 && iconInteraction.rank3.length">
                <img style="margin-right : 8px; width : 24px" :src="iconInteraction.rank3" alt="">
                <div class="interaction-percentage">{{ video.total_3 | numberWithCommas | addPointZero }}%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-card" @click="gotoDetailVideo(video)" data-right-card="action">
          <i class="icon icon-arrow-right"></i>
        </div>
      </div>
      </template>

      <div class="mt-1 text-center">
        <a class="link link-to-top" @click="resetCheckedVideo(),resetCheckedAvg(),clickDefaultBack()"
           data-right-card="action"
          >デフォルト表示に戻す</a
        >
      </div>
    </div>
    <div v-if="!isProject">
      <div class="card-download-manual mt-5">
        <img class="img-logo" src="images/svg/logo_download_manual.svg" />
        <div class="title-large mt-2">Welcome to PlayAds</div>
        <div class="title-small mt-1">動画コンテンツの反応値を測定して<br>安全かつ効果的に配信しよう！</div>
        <div
            :style="disabled ? styleButton : {}"
            class="btn btn-download-manual btn-primary-outline mt-4"
            @click="downloadManual()"
            >
            マニュアルダウンロード
        </div>
      </div>
    </div>
    <div v-if="!videos.length  && !listRank2.length && isProject">
      <div class="card-download-manual mt-5">
        <img class="img-logo" src="images/svg/logo_download_manual.svg" />
        <div class="title-large mt-2">Welcome to PlayAds</div>
        <div class="title-small mt-1">動画コンテンツの反応値を測定して<br>安全かつ効果的に配信しよう！</div>
        <div
            :style="disabled ? styleButton : {}"
            class="btn btn-download-manual btn-primary-outline mt-4"
            @click="downloadManual()"
            >
            マニュアルダウンロード
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/commons/event_bus";
import { roundPercent } from "@/commons/helpers"
export default {
  props: {
    listRank2: Array,
    listRank3: Array,
    videos: Array,
    maxRank3: Object,
    maxRank2: Object,
    interactionType: Number,
    fromRouter : String,
    isProject : Boolean,
    firstVideo : Object,
    summaryAvgData: Object,
    avgs: Array,
    checkedAvgIds: Array
  },
  data() {
    let textRank = ["one", "two", "three"];
    return {
      textRank,
      goodInteractionMax: {},
      badInteractionMax: {},
      text: {
        rank2: this.interactionType ? 'Good' : 'ナットク',
        rank3: this.interactionType ? 'Bad' : 'スキ',
      },
      iconName: {
        rank2: this.interactionType ? 'good' : 'nattoku',
        rank3: this.interactionType ? 'bad' : 'suki',
      },
      currentUser : {},
      disabled : false,
      styleButton : {
        opacity : 0.75,
        cursor : 'default'
      },
      summaryTotalAvgName: [
        'total_avg_norm',
        'total_0_15_avg_norm',
        'total_16_30_avg_norm',
        'total_31_60_avg_norm',
        'total_61_avg_norm'
      ],
      leftAvgName: [
        '2_avg_norm',
        '2_0_15_avg_norm',
        '2_16_30_avg_norm',
        '2_31_60_avg_norm',
        '2_61_avg_norm'
      ],
      rightAvgName: [
        '3_avg_norm',
        '3_0_15_avg_norm',
        '3_16_30_avg_norm',
        '3_31_60_avg_norm',
        '3_61_avg_norm'
      ],
    };
  },
  computed: {
    textInteraction() {
      return {
        rank2 : this.maxRank2.interaction_text ?? this.firstVideo.interaction_btn_1.text,
        rank3 : this.maxRank3.interaction_text ?? this.firstVideo.interaction_btn_2.text,
      }
    },
    iconInteraction() {
      return {
        rank2 : this.maxRank2.interaction_icon ?? this.firstVideo.interaction_btn_1.btn_icon_url,
        rank3 : this.maxRank3.interaction_icon ?? this.firstVideo.interaction_btn_2.btn_icon_url,
      }
    },
    colorInteraction(){
      let color_rank_2 = this.maxRank2.interaction_color ?? this.firstVideo.interaction_btn_1.color
      let color_rank_3 = this.maxRank3.interaction_color ?? this.firstVideo.interaction_btn_2.color
      return {
        rank_2 : color_rank_2 == 1 ? 'good' : color_rank_2 == 2 ? 'nattoku' : color_rank_2 == 3 ? 'bad' : color_rank_2 == 4 ? 'suki' : '',
        rank_3 : color_rank_3 == 1 ? 'good' : color_rank_3 == 2 ? 'nattoku' : color_rank_3 == 3 ? 'bad' : color_rank_3 == 4 ? 'suki' : '',
      }
    },
  },
  methods: {
    downloadManual(){
        if (!this.disabled) {
          this.disabled = true;
          let url = this.$router.resolve({name: "api.download.manual"}).href;
          let params = {
            user_id : this.currentUser.user_id
          }
          this.$http2.get(url, params ,(data) => {
            let url = data.download_url;
            let label = url.substr(url.lastIndexOf("/")+1);
            this.$http2.downloadPDF(url, label, ()=> {
              this.disabled = false
            },
            ()=> {
              this.disabled = false
            });
          },
          () => {
            this.disabled = false
          }
          );
        }
    },
    totalInteraction(number1,number2){
      if(!number1) number1 = 0
      if(!number2) number2 = 0
      return (Number(number1) + Number(number2)).toFixed(1)
    },
    resetCheckedVideo() {
      EventBus.$emit('resetCheckedVideo');
    },
    resetCheckedAvg(){
      EventBus.$emit('resetCheckedAvg');
    },
    clickDefaultBack(){
      let clickDefault = true
      EventBus.$emit('clickDefaultBack' ,clickDefault)
    },
    gotoDetailVideo(video) {
      let video_id = video.id||video.video_id;
      let top_detail_url = this.$router.resolve({
        name: 'top-detail',
        params: {
          video_id,
        },
      });
      localStorage.setItem("from_router", this.$props.fromRouter);
      window.open(top_detail_url.href, '_blank');
    }
  },
  watch: {
    interactionType: function() {
      this.iconName = {
        rank2: this.interactionType ? 'good' : 'nattoku',
        rank3: this.interactionType ? 'bad' : 'suki',
      },
      this.text = {
        rank2: this.interactionType ? 'Good' : 'ナットク',
        rank3: this.interactionType ? 'Bad' : 'スキ',
      }
    },
    listRank2: function(report) {
      report.map((item, index) => {
        let obj = item;
        obj.text_rank = this.textRank[index];
        return obj;
      });
    },
    listRank3: function(report) {
      report.map((item, index) => {
        let obj = item;
        obj.text_rank = this.textRank[index];
        return obj;
      });
    },
    maxRank2 : function(data) {
      data.interaction_count = roundPercent(data.interaction_count)
    },
    maxRank3 : function(data) {
      data.interaction_count = roundPercent(data.interaction_count)
    }
  },
  mounted(){
    let currentUser = this.$store.getters.currentUser;
    this.currentUser = currentUser;
  }
};
</script>
<style lang="scss" scoped>
.card-download-manual{
  height: 347px;
  box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #49ab94;
  .title-large {
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
  }
  .title-small{
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  }
  .btn-download-manual{
    padding : 10px 0;
    width: 90%;
    display: flex;
    justify-content: center;
  }
}

.card-subtitle {
  .body {
    flex-wrap: nowrap !important;
    justify-content: space-evenly;

    .body-right.multiple-row {
      min-width: 120px;
    }

    .body-left-avg {
      margin-right: 10px !important;

      img {
        margin-right: 0 !important;
      }
    }
  }

  .content {
    padding: 20px 0 20px 20px !important;
  }
}

.interaction-percentage{
  text-align: right;
  width: 75.91px;
}

@media screen and (max-width: 1899px){

  .interaction-percentage{
    text-align: right;
    width: 91.09px;
  }
}

</style>
