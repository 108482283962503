<template>
    <div class="modal add-user-modal modal-show">
        <div class="modal-content">
            <div class="header">
                <div class="btn-close" @click="closeAddModal()">
                    <i class="icon icon-close"></i>
                </div>
            </div>
            <div class="body">
                <div class="form-group"  v-if="hasClientSelect">
                    <label>クライアント<span class="require">必須</span></label>
                    <Select
                        :data="clients"
                        :isSelected="true"
                        :idSelected="clientSelected"
                        :element="'client_add_user'"
                        :isHasIcon="false"
                        @select="handleChangeClientId"
                    ></Select>
                </div>
                <div class="form-group">
                    <label>ユーザー名<span class="require">必須</span></label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="user.name"
                        @input="$v.user.name.$touch()"
                        :class="{'error-input': ($v.user.name.$error)}"
                    />
                    <div v-if="$v.user.name.$error">
                        <span class="error error-message" v-if="!$v.user.name.required">{{$t('validation.userPersonUpdate.name.required')}}</span>
                        <span class="error error-message" v-else-if="!$v.user.name.xssCheck">{{$t('validation.userPersonUpdate.name.xssCheck')}}</span>
                        <span class="error error-message" v-else-if="!$v.user.name.maxLength">{{$t('validation.userPersonUpdate.name.max-length')}}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label>メールアドレス<span class="require">必須</span></label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="user.email"
                        @input="$v.user.email.$touch()"
                        :class="{'error-input': ($v.user.email.$error)}"
                    />
                    <div v-if="$v.user.email.$error">
                        <span class="error error-message" v-if="!$v.user.email.required">{{$t('validation.userPersonUpdate.email.required')}}</span>
                        <span class="error error-message" v-else-if="!$v.user.email.email">{{$t('validation.userPersonUpdate.email.email')}}</span>
                        <span class="error error-message" v-else-if="!$v.user.email.maxLength">{{$t('validation.userPersonUpdate.email.max-length')}}</span>
                    </div>
                </div>
                <div class="btn-submit">
                    <button
                        class="btn btn-primary button-download-pdf"
                        @click="openConfirmModal()"
                    >
                        追加
                    </button>
                </div>
            </div>
        </div>
        <modal-confirm  v-if="isShowModalConfirm" :confirmId="AddUserConfirmId" :message="modalMessage" @confirm="addOK()"  @closeModalConfirm="isShowModalConfirm = false"></modal-confirm>
    </div>
</template>
<script>
import Select from '../../components/Project/Select.vue';
import {
    required,
    maxLength,
    email
} from "vuelidate/lib/validators";
import {
    xssCheck
} from "../../commons/rules";
import i18n from '../../i18n';
import ModalConfirm from "../../components/Modal/ModalConfirm.vue";
import EventBus from '@/commons/event_bus';

export default {
    props: {
        clientSelected: [Number, String],
        clients: Array,
        hasClientSelect: Boolean
    },
    components: {
        ModalConfirm,
        Select
    },
    data() {
        return {
            user: {
                name: "",
                email: ""
            },
            plan: null,
            AddUserConfirmId: "add-user-confirm-modal",
            modalMessage: i18n.t('messages.confirm_register'),
            client: null,
            isShowModalConfirm:false
        };
    },
    validations() {
        const validate = {
        user: {
            name: { required, xssCheck, maxLength: maxLength(30) },
            email: { required, email, maxLength: maxLength(255) },
        },
        };
        return validate;
    },
    methods: {
        handleChangeClientId(selectedId) {
            this.client = this.clients?.find(e => e.id == selectedId)
        },
        openConfirmModal() {
            this.$v.$touch();
            let vm = this;
            let error = vm.$v.$invalid;
            if (!error) {
                vm.isShowModalConfirm = true;
            }
        },
        addOK() {
            let currentUser = this.$store.getters.currentUser;
            let account_id = this.hasClientSelect ? this.client.account_id : currentUser.account_id
            let url = this.$router.resolve({
                name: 'api.users'
            }).href;
            let postData = {
                name: this.user.name,
                email: this.user.email,
                account_id: account_id
            };
            this.$http2.post(url, postData, () => {
                this.isShowModalConfirm = false;
                this.$emit("closeModal");
                this.$toasted.success(this.$t("messages.register_success"));
            }, () => {
                EventBus.$emit("handleDone")
            })
        },
        closeAddModal() {
            this.$emit("closeModal");
        }
    },
    mounted() {
        this.client = this.clients?.find(e => e.id == this.clientSelected)
    }
};
</script>
<style lang="scss" scoped>
    @import "@/styles/_user.scss";
</style>
