<template>
  <div class="sidebar">
    <template v-for="menu in sideMenus">
      <div :class="'sidebar-' + menu.position" :key="menu.position">
        <template v-for="item in menu.items">
          <button
            :class="getClass(item)"
            :key="item.icon_name"
            v-if="checkPermission(item)"
            @click="redirectTo(item.router),addActive(item)"
          >
            <i :class="'icon icon-menu icon-' + item.icon_name"></i>
            <div class="icon-text">{{item.icon_text}}</div>
        </button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import router from "../../routes/index";
import { plan_sidebar, plan } from "../../commons/plan_const";
import EventBus from "../../commons/event_bus";
export default {
  name: "SideBar",
  props: {},
  data() {
    return {
      sideMenus: plan_sidebar,
      currentUser: this.$store.getters.currentUser,
      clientSelected: 0,
    };
  },
  watch: {
    clientSelected: {
      handler: function(){
        let homeItem = document.querySelector(".menu-item")
        let homeActive = document.querySelector(".active")
        let marketItem = document.querySelector(".h-60")
        if(!(this.clientSelected == process.env.VUE_APP_MARKET_RESEARCH_BUYER_ID) && !(this.clientSelected == null) && this.$route.name == 'home'){
          homeActive.classList.remove("active");
          homeItem.classList.toggle("active");
          marketItem.classList.remove("active");
        }
      }
    }
  },
  methods: {
    redirectTo(routerName) {
      if (routerName && this.$route.name != routerName) {
        router.push({ name: routerName });
        localStorage.removeItem("video_register");
        localStorage.removeItem("video_file");
        localStorage.removeItem("buyer_id");
        localStorage.removeItem("layers");
        localStorage.removeItem("groupsSegment");
        localStorage.removeItem("segmentSelected");
        localStorage.removeItem("interactionTypesCustom");
        localStorage.removeItem("interactionTypesCustomStep1");
        localStorage.removeItem("interactionTypeVerifyOneCustom");
        localStorage.removeItem("screening_option");
        localStorage.removeItem("intentionData")
        localStorage.removeItem("intentionList")
        localStorage.removeItem("videoCaptureData")
        localStorage.removeItem("checkedQuestionCDAI")
      }
    },
    getClass(item) {
      let className = "";
      if (item.routes.includes(this.$route.name) && !(this.$route.fullPath == "/?buyer_id=" + process.env.VUE_APP_MARKET_RESEARCH_BUYER_ID)) {
        className = " active";
      }
      if (item.router == this.$route.name && !(this.$route.fullPath == "/?buyer_id=" + process.env.VUE_APP_MARKET_RESEARCH_BUYER_ID)) {
          className = " active";
      }
      if ((this.$route.name == "top-detail" || this.$route.name == "home" || this.$route.name == "top-compare") && item.router == "home" && !(this.$route.fullPath == "/?buyer_id=" + process.env.VUE_APP_MARKET_RESEARCH_BUYER_ID)) {
        className = " active";
      }
      if (this.$route.name == 'whitepaper-subscribers' && item.router == "clients") {
        className = " active";
      }
      if (item.class == "mt-8 h-60" && this.$route.fullPath == "/?buyer_id=" + process.env.VUE_APP_MARKET_RESEARCH_BUYER_ID){
        return "menu-item " + "mt-8 h-60" + " active";
      }
      return "menu-item " + item.class + className;
    },
    addActive(item){
      let homeItem = document.querySelector(".menu-item");
      let marketItem = document.querySelector(".h-60");
      if (item.router == this.$route.name && this.$route.fullPath == "/?buyer_id=" + process.env.VUE_APP_MARKET_RESEARCH_BUYER_ID) {
        router.push({ name: this.$route.name });
        homeItem.classList.toggle("active");
        marketItem.classList.remove("active");
      }
    },
    getSelected(value){
      this.clientSelected = value
    },
    checkPermission(item) {
      let permissions = this.currentUser.plan
        ? plan[this.currentUser.plan]
        : [this.currentUser.role];
      return permissions.find((x) => item.plans.includes(x)) ? true : false;
    },
  },
  created(){
    EventBus.$on('clientSelected' ,(value) => {
            this.getSelected(value)
    })
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_sidebar.scss";
::v-deep .flatpickr-calendar {
  padding: 20px 16px 52px 16px !important;
  .flatpickr-day {
    &.is-saturday {
      color: #4976ab;
    }
    &.holidays {
      color: #ce6060;
    }
    &.selected {
      background-color: $green !important;
      border-color: $green !important;
    }
  }
  .flatpickr-months {
    //.flatpickr-prev-month {
    //  display: none;
    //}
    .flatpickr-next-month {
      bottom: 0 !important;
      top: 64%;
      padding: 20px;
    }
    .flatpickr-current-month {
      text-align: left !important;
      @include font(l);
      font-style: normal;
      font-weight: bold;
      left: 8px !important;
    }
  }
  .button-next {
    text-align: right;
  }
}
</style>