import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate';
import store from './store';
import router from './routes/index';
import i18n from './i18n';
import VueAuth from '@websanova/vue-auth';

import VueMoment from 'vue-moment';
import momenttz from 'moment-timezone';

// Helper http2
import http2 from './commons/http2';

import auth from './commons/auth'
import VueResource from 'vue-resource';
import Highchart from 'highcharts';
import HighchartsVue from "highcharts-vue";
import HighchartsMore from "highcharts/highcharts-more";
import {
  numberWithCommas,
  addPointZero,
  number0Hyphen
} from '@/commons/helpers';

(function(H) {
  H.wrap(
    H.Pointer.prototype,
    'getHoverData',
    function(
      proceed,
      existingHoverPoint,
      existingHoverSeries,
      series, isDirectTouch,
      shared,
      e
    ) {

      var result = proceed.apply(this, Array.prototype.slice.call(arguments, 1));

      if (result.hoverSeries) {
        result.hoverSeries.originalEvent = e;
      }
      if (result.hoverPoint) {
        result.hoverPoint.originalEvent = e;
      }

      return result;
    });
}(Highchart));

HighchartsMore(Highchart)
Vue.use(HighchartsVue);
import ScrollLoader from 'vue-scroll-loader';

Vue.config.productionTip = false

Vue.use(VueResource);


Vue.use(Vuelidate)

Vue.router = router;
Vue.checkPrevious = false;

Vue.use(VueAuth, auth);

Vue.use(ScrollLoader);
Vue.http.options = {
  root: process.env.VUE_APP_API_URL
}

Vue.http.interceptors.push((request, next) => {
    let token = store.getters.token;
    request.headers.set('Authorization', 'Bearer ' + token);
    next();
});



const moment = require('moment')
require('moment/locale/ja')
require('moment/locale/en-gb')
momenttz.tz.setDefault(process.env.VUE_APP_TIMEZONE);
momenttz.locale(process.env.VUE_APP_LOCALE == "jp" ? "ja" : "en-gb");
Vue.use(VueMoment, {
    moment
});


const helpers = {
    install(Vue) {
        Vue.prototype.$http2 = http2; // we use $ because it's the Vue convention
    }
};

Vue.use(helpers);

Vue.filter('numberWithCommas', numberWithCommas)
Vue.filter('addPointZero', addPointZero)
Vue.filter('number0Hyphen', number0Hyphen)



new Vue({
  el: '#app',
  i18n,
  store,
  router,
  render: h => h(App),
});
