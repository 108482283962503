<template>
  <div class="video-trend-table" v-if="videoTrend.length">
    <div class="table-content">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th width="50%"></th>
            <th width="50%" class="text-left">トレンド数</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in videoTrend" :key="index">
            <td class="item-keyword">{{ item.keyword }}</td>
            <td>{{ item.count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    dataVideoTrend: Array,
  },
  computed: {
    videoTrend() {
      let cloneData = JSON.parse(JSON.stringify(this.dataVideoTrend));
      return cloneData.reduce((acc, item) => {
        item.data[0].count.forEach((item) => {
          let index = acc.findIndex((x) => x.keyword === item.keyword);
          if (index === -1) {
            acc.push(item);
          } else {
            acc[index].count += item.count;
          }
        });
        return acc;
      }, []);
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/_variable.scss";
$border-color: #999999;
$table-header-bg: #edf1f7;
.video-trend-table {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  .table-content {
    width: 48%;

    .table {
      border: 1px solid $border-color;
      border-collapse:collapse;
      margin-bottom: 30px;
      width: 100%;

      thead {
        background-color: $table-header-bg;
        th {
          border: 1px solid $border-color;
          border-bottom: 0;
          padding: 10px;
          font-size: 12px;
          line-height: 19px;
          font-weight: 400;
          color: $black;
        }
      }
      tbody {
        tr {
          td {
            border: 1px solid $border-color;
            padding: 10px;
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
            font-family: "Avenir Next";
            color: $black;
            max-width: 300px;

            &.item-keyword {
              background-color: $table-header-bg;
              word-break: break-word;
              font-size: 12px;
              line-height: 19px;
              font-weight: 400;
              font-family: "Noto Sans JP";
            }
          }
        }
      }
    }
  }
}
</style>