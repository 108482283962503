<template>
    <div class="modal edit-client">
        <div class="modal-content">
            <div class="header">
                <div class="btn-close">
                    <i class="icon icon-close" @click="closeModal"></i>
                </div>
            </div>
            <div class="body">
                <div class="form-edit-client">
                    <div class="form-group">
                        <label>クライアント名<span class="require" v-if="!isApprove">必須</span></label>
                        <input type="text" class="form-control medium" :class="{'error': submitted && $v.params.name.$error}" placeholder="クライアント名を入れてください" :disabled="isApprove" v-model="params.name">
                        <template v-if="submitted && $v.params.name.$error">
                            <span class="error error-message" v-if="!$v.params.name.required">{{ $t('validation.required', {field: 'クライアント名'}) }}</span>
                            <span class="error error-message" v-else-if="!$v.params.name.xssCheck">{{ $t('validation.xss_check', {field: 'クライアント名'}) }}</span>
                            <span class="error error-message" v-else-if="!$v.params.name.maxLength">{{ $t('validation.max_length', {field: 'クライアント名', length: '30'}) }}</span>
                        </template>
                    </div>
                    <div class="form-group">
                        <label>部署名 </label>
                        <input type="text" class="form-control medium" :class="{'error': submitted && $v.params.department_name.$error}" :disabled="isApprove" v-model="params.department_name">
                        <template v-if="submitted && $v.params.department_name.$error">
                            <span class="error error-message" v-if="!$v.params.department_name.xssCheck">{{ $t('validation.xss_check', {field: '部署名'}) }}</span>
                        </template>
                    </div>
                    <div class="form-group">
                        <label>担当名<span class="require" v-if="!isApprove">必須</span></label>
                        <input type="text" class="form-control medium" :class="{'error': submitted && $v.params.contact_name.$error}" :disabled="isApprove" placeholder="クライアント名を入れてください" v-model="params.contact_name">
                        <template v-if="submitted && $v.params.contact_name.$error">
                            <span class="error error-message" v-if="!$v.params.contact_name.required">{{ $t('validation.required', {field: '担当名'}) }}</span>
                            <span class="error error-message" v-else-if="!$v.params.contact_name.xssCheck">{{ $t('validation.xss_check', {field: '担当名'}) }}</span>
                            <span class="error error-message" v-else-if="!$v.params.contact_name.maxLength">{{ $t('validation.max_length', {field: '担当名', length: '30'}) }}</span>
                        </template>
                    </div>
                    <div class="form-group">
                        <label>郵便番号</label>
                        <input type="text" class="form-control medium" :class="{'error': submitted && $v.params.postal_code.$error}" :disabled="isApprove" v-model.trim="params.postal_code">
                        <template v-if="submitted && $v.params.postal_code.$error">
                            <span class="error error-message" v-if="!$v.params.postal_code.postalCode">{{ $t('validation.postal_code') }}</span>
                        </template>
                    </div>
                    <div class="form-group w-100">
                        <label>都道府県区市町村番地</label>
                        <input type="text" class="form-control" :class="{'error': submitted && $v.params.address1.$error}" :disabled="isApprove" v-model="params.address1">
                        <template v-if="submitted && $v.params.address1.$error">
                            <span class="error error-message" v-if="!$v.params.address1.xssCheck">{{ $t('validation.xss_check', {field: '都道府県区市町村番地'}) }}</span>
                        </template>
                    </div>
                    <div class="form-group w-100">
                        <label>ビル名・マンション名</label>
                        <input type="text" class="form-control" :class="{'error': submitted && $v.params.address2.$error}" :disabled="isApprove" v-model="params.address2">
                        <template v-if="submitted && $v.params.address2.$error">
                            <span class="error error-message" v-if="!$v.params.address2.xssCheck">{{ $t('validation.xss_check', {field: 'ビル名・マンション名'}) }}</span>
                        </template>
                    </div>
                    <div class="form-group">
                        <label>電話番号<span class="require" v-if="!isApprove">必須</span></label>
                        <input type="text" class="form-control medium" :class="{'error': submitted && $v.params.phone_number.$error}" :disabled="isApprove" v-model="params.phone_number">
                        <template v-if="submitted && $v.params.phone_number.$error">
                            <span class="error error-message" v-if="!$v.params.phone_number.required">{{ $t('validation.required', {field: '電話番号'}) }}</span>
                            <span class="error error-message" v-else-if="!$v.params.phone_number.halfwidthNumeric">{{ $t('validation.half_width_numeric', {field: '電話番号'}) }}</span>
                            <span class="error error-message" v-else-if="!$v.params.phone_number.maxLength">{{ $t('validation.max_length', {field: '電話番号', max_length: '13'}) }}</span>
                        </template>
                    </div>
                    <div class="form-group" style="width : 100%">
                        <label>料金プラン</label>
                        <div class="plan-priave">
                            <div class="title">
                                {{ planMap[currentPlan] }}
                            </div>
                            <div class="plan" v-if="!isApprove">
                                <div>
                                    {{ planMap[currentPlan] }}利用登録日：
                                    <span>{{ currentPlanStartAt }}</span>
                                </div>
                                <div class="date-regester-before">
                                    <div>以前の料金プラン：</div>
                                    <div class="ml-1">
                                        <span v-html="planLogsText"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-check-box mb-2 mt-4" v-if="!isApprove">
                                <input class="form-check-input" type="checkbox" v-model="editPlan">
                                <span class="btn-check"></span>
                                <label class="label-check">料金プランを変更する</label>
                            </div>
                            <div class="plan-list-private" v-if="!isApprove">
                                <div class="form-radio-box mr-5">
                                    <input class="form-radio-input" type="radio" value="3" v-model="params.plan" :disabled="!editPlan">
                                    <span class="btn-radio"></span>
                                    <label class="label-radio">{{ planMap[3] }}</label>
                                </div>
                                <div class="form-radio-box mr-5">
                                    <input class="form-radio-input" type="radio" value="4" v-model="params.plan" :disabled="!editPlan">
                                    <span class="btn-radio"></span>
                                    <label class="label-radio">{{ planMap[4] }}</label>
                                </div>
                                <div class="form-radio-box mr-5">
                                    <input class="form-radio-input" type="radio" value="7" v-model="params.plan" :disabled="!editPlan">
                                    <span class="btn-radio"></span>
                                    <label class="label-radio">{{ planMap[7] }}</label>
                                </div>
                                <!-- <div class="form-radio-box mr-5">
                                    <input class="form-radio-input" type="radio" value="5" v-model="params.plan" :disabled="!editPlan">
                                    <span class="btn-radio"></span>
                                    <label class="label-radio">{{ planMap[5] }}</label>
                                </div> -->
                            </div>
                            <!-- <div class="plan-list mt-4" v-if="!isApprove">
                                <div class="form-radio-box mr-5">
                                    <input class="form-radio-input" type="radio" value="6" v-model="params.plan" :disabled="!editPlan">
                                    <span class="btn-radio"></span>
                                    <label class="label-radio">{{ planMap[6] }}</label>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="form-group" v-if="!isApprove">
                        <label>前受・売掛</label>
                        <div class="radio-list">
                            <div class="form-radio-box mr-5" v-for="paymentType in paymentTypeMap" :key="paymentType.key">
                                <input class="form-radio-input" type="radio" :value="paymentType.key" v-model="params.payment_type">
                                <span class="btn-radio"></span>
                                <label class="label-radio">{{ paymentType.value }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" v-if="!isApprove">
                        <label>表示設定</label>
                        <div class="radio-list">
                            <div class="form-radio-box mr-5" v-for="status in statusMap" :key="status.key">
                                <input class="form-radio-input" type="radio" :value="status.key" v-model="params.status">
                                <span class="btn-radio"></span>
                                <label class="label-radio">{{ status.value }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" v-else>
                        <label>クライアント承認</label>
                        <div class="radio-list">
                            <div class="form-radio-box mr-5" v-for="status in approveStatusMap" :key="status.key">
                                <input class="form-radio-input" type="radio" :value="status.key" v-model="approveStatus">
                                <span class="btn-radio"></span>
                                <label class="label-radio">{{ status.value }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-submit">
                        <button class="btn btn-primary button-download-pdf" @click="openConfirmModal" v-if="!isApprove">
                            {{ $t('general.update') }}
                        </button>
                        <button class="btn btn-primary button-download-pdf" @click="openConfirmModal" v-else>
                            {{ $t('general.send_announce_mail') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal-confirm :message="editConfirmMessage" @confirm="submit" v-if="!isApprove && isShowModalConfirm" @closeModalConfirm="isShowModalConfirm = false"></modal-confirm>
        <modal-confirm :message="approveConfirmMessage" @confirm="submitApprove" v-if="isApprove && isShowModalConfirm" @closeModalConfirm="isShowModalConfirm = false"></modal-confirm>
    </div>
</template>

<script>
import router from '../../routes';
import http2 from '../../commons/http2';
import i18n from '../../i18n';
import ModalConfirm from "./../Modal/ModalConfirm.vue";
import {required, maxLength} from "vuelidate/lib/validators";
import {xssCheck, postalCode, halfwidthNumeric} from "../../commons/rules";

export default {
    name: 'ClientEditModal',
    props: ['clientId', 'isApprove'],
    components: {ModalConfirm},
    data() {
        return {
            params: {
                name: '',
                department_name: '',
                contact_name: '',
                postal_code: '',
                address1: '',
                address2: '',
                phone_number: '',
                plan: 0,
                payment_type: 0,
                status: 0,
            },
            currentPlan: 0,
            currentPlanStartAt: '',
            planLogs: [],
            editPlan: false,
            planMap: {
                1: i18n.t('plans.no_longer'),
                2: i18n.t('plans.no_longer'),
                3: i18n.t('plans.private'),
                4: i18n.t('plans.free'),
                5: i18n.t('plans.market'),
                6: i18n.t('plans.market') + ' + ' + i18n.t('plans.private'),
                7: i18n.t('plans.advance')
            },
            paymentTypeMap: [
                {key: 1, value: i18n.t('payment_type.pay_before')},
                {key: 2, value: i18n.t('payment_type.pay_after')}
            ],
            statusMap: [
                {key: 0, value: i18n.t('general.hide-item')},
                {key: 1, value: i18n.t('general.show-item')},
            ],
            approveStatusMap: [
                {key: 1, value: i18n.t('status.approve')},
                {key: 2, value: i18n.t('status.reject')}
            ],
            approveStatus: 1,
            submitted: false,
            editConfirmMessage: i18n.t('messages.confirm_update_client'),
            isShowModalConfirm : false,
        }
    },
    computed: {
        planLogsText: function() {
            let text = '';
            for (let i = 0; i < this.planLogs.length; i++) {
                let planLog = this.planLogs[i];
                if(i != 0) {
                    text += '<br />'
                }
                if(planLog.start_at !== undefined && planLog.start_at !== null) {
                    text += planLog.start_at + '~';
                }
                if(planLog.end_at !== undefined && planLog.end_at !== null) {
                    text += planLog.end_at;
                }
                if(planLog.plan !== undefined && planLog.plan > 0) {
                    text += ' ' + this.planMap[planLog.plan];
                }
            }
            return text;
        },
        approveConfirmMessage: function() {
            if(this.approveStatus == 1) {
                return i18n.t('messages.confirm_approve');
            } else {
                return i18n.t('messages.confirm_unapprove');
            }
        }
    },
    watch: {
        editPlan: function() {
            if(this.editPlan == false) {
                this.params.plan = this.currentPlan;
            }
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal')
        },
        getClientData() {
            let query = {
                id: this.clientId
            }
            if(this.clientId) {
                let url = router.resolve({name: 'api.buyers.detail', params: query}).href;
                http2.getList(url, {}, (data) => {
                    this.params.name = data.name;
                    this.params.department_name = data.department_name;
                    this.params.contact_name = data.contact_name;
                    this.params.postal_code = data.postal_code;
                    this.params.address1 = data.address1;
                    this.params.address2 = data.address2;
                    this.params.phone_number = data.phone_number;
                    this.params.plan = data.plan;
                    this.currentPlan = data.plan;
                    this.currentPlanStartAt = data.current_plan_start_at;
                    this.planLogs = data.plan_logs;
                    this.params.payment_type = data.payment_type;
                    this.params.status = data.status;
                });
            }
        },
        submit() {
            this.submitted = true;
            this.$v.$touch();
            if(this.$v.$invalid) {
                this.isShowModalConfirm = false;
                return;
            }

            let query = {
                id: this.clientId
            }
            if(this.clientId) {
                this.params.postal_code = !this.params.postal_code ? null : this.params.postal_code
                let url = router.resolve({name: 'api.buyers.update', params: query}).href;
                http2.put(url, this.params, () => {
                    this.isShowModalConfirm = false;
                    this.$emit('successEdit');
                    this.$toasted.success(this.$t("messages.update_success"));
                }, () => {
                    this.isShowModalConfirm = false;
                });
            }
        },
        submitApprove() {
            let query = {
                id: this.clientId
            }
            if(this.clientId) {
                let url = router.resolve({name: 'api.buyers.approve', params: query}).href;
                http2.put(url, {approve_status: this.approveStatus}, () => {
                    this.isShowModalConfirm = false;
                    this.$emit('successEdit');
                    if(this.approveStatus == 1) {
                        this.$toasted.success(this.$t("messages.approve_success"));
                    } else {
                        this.$toasted.success(this.$t("messages.unapprove_success"));
                    }
                }, () => {
                    this.isShowModalConfirm = false;
                })
            }
        },
        openConfirmModal() {
            this.isShowModalConfirm = true;
        }
    },
    mounted() {
        this.getClientData();
    },
    validations: {
        params: {
            name: { required, xssCheck, maxLength: maxLength(30) },
            department_name: { xssCheck },
            contact_name: { required, xssCheck, maxLength: maxLength(30) },
            postal_code: { postalCode },
            address1: { xssCheck },
            address2: { xssCheck },
            phone_number: { required, maxLength: maxLength(13), halfwidthNumeric }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_client_edit_modal.scss'
</style>