import bearer from '@websanova/vue-auth/drivers/auth/bearer';
import resource from '@websanova/vue-auth/drivers/http/vue-resource.1.x';
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x';
import Vue from 'vue';
import router_app from '../routes/index';


const config = {
    auth: bearer,
    http: resource,
    router: router,
    tokenDefaultName: 'playads-jwt-auth',
    tokenStore: ['localStorage'],
    // API endpoints used in Vue Auth.
    loginData: {
        url: router_app.resolve({name: 'api.login'}).href,
        method: 'POST',
        redirect: '',
        fetchUser: false
    },
    logoutData: {
        url: router_app.resolve({name: 'api.logout'}).href,
        method: 'POST',
        redirect: '/login',
        makeRequest: true
    },
    authRedirect: {
        path: "/login"
    },
    parseUserData(data) {
        Vue.currentUserLogin = data;
        return data || {}
    },
}
export default config;
