<template>
  <div class="select-sales-staff">
    <select class="form-control" :name="element" :id="element"></select>
  </div>
</template>
<script>
import { setSelect2Data, formatDataSelect2 } from "../../commons/helpers";
const jQuery = require('jquery');
export default {
  props: {
    data: Array,
    idSelected: Number,
    isSelected: Boolean,
    element: String,
    isHasIcon : Boolean
  },
  watch: {
    data: function (data) {
      this.initClientsSelect(formatDataSelect2(data));
    },
  },
  methods: {
    initClientsSelect(data) {
      let vm = this;
      setSelect2Data(
        vm.element,
        data,
        false,
        this.handleSelectedClient,
        {
          selected: vm.idSelected,
          minimumResultsForSearch: 0,
          width: "308px",
          height: "52px",
          dropdownCssClass: "dropdown-select2-custom",
        },
        this.isSelected,
        this.isHasIcon
      );
    },
    handleSelectedClient(selectedId) {
      this.$emit("select", selectedId);
    },
    select2Custom() {
      jQuery(".select2-option-icon").on("mouseout", function () {
        jQuery(this).attr(
          "src",
          "./images/svg/icon_highlight_option_hover.svg"
        );
      });
    },
  },
  mounted() {
    this.initClientsSelect(formatDataSelect2(this.data));
  },
};
</script>
<style lang="scss">
@import "../../styles/variable";
.select-sales-staff {
    .select2.select2-container {
        width: 100% !important;
        .selection {
            .select2-selection--single {
                border: none;
                .select2-selection__rendered {
                    @include font(l);
                    display: inline-flex;
                    align-items: center;
                    color: $green;
                    font-weight: 500;
                }
            }
            .select2-selection__arrow {
                b {
                    display: inline-block;
                    width: 8px;
                    height: 7px;
                    background-image: url(../../../public/images/svg/icon_select_arrow_bottom.svg);
                    background-repeat: no-repeat;
                    border: none;
                }
            }
        }

        &.select2-container--open {
            .selection {
                .select2-selection {
                    border: 1px solid #49AB94;
                    //border-color: #49AB94 #49AB94 #EBEBEB #49AB94 !important;
                }
            }

            .select2-selection__arrow {
                b {
                    background-image: url(../../../public/images/svg/icon_select_arrow_top.svg);
                }
            }
        }
    }
}

.select2-container--default {
    .dropdown-select2-custom.select2-dropdown--above {
        border: 1px solid #49AB94;
        border-bottom: 0;

        .select2-results__options {
            border-radius: 0;
            border-top: none;
        }

        .select2-results__option--selectable {
            &:first-child {
                border-radius: 0;
            }
        }
    }
}
</style>
