<template>
  <div class="modal home-compare modal-show" v-show="isShown" ref="modal">
    <div class="modal-content">
      <div class="header">
        <div class="btn-close" @click="closeModal()">
          <i class="icon icon-close"></i>
        </div>
      </div>
      <div class="body">
        <div class="modal-title">検証データを並べて比較</div>
        <div class="modal-description">
          各ラベル、各動画、ユーザー別で検証レポートを２つ並べて確認することができます。
          <br>
          以下から比較対象を選択してください。
        </div>
        <div class="form-group large targets-for-comparison flex flex-col">
          <label>比較対象</label>
          <RadioList
            :radioList="targetsForComparisonList"
            :idSelected="targetForComparison"
            :keyName="'targetForComparison'"
            @changeIdSelected="changeTargetForComparison($event)"
          ></RadioList>
        </div>

        <FormLabel
          v-if="targetForComparison == LABEL_UNIT_VALUE"
          @setDisabledButtonCompare="handleEventSetDisabledButtonCompare($event)"
        ></FormLabel>

        <FormMovie
          v-else-if="targetForComparison == MOVIE_UNIT_VALUE"
          @setDisabledButtonCompare="handleEventSetDisabledButtonCompare($event)"
        ></FormMovie>

        <FormUser
          v-else
          @setDisabledButtonCompare="handleEventSetDisabledButtonCompare($event)"
        ></FormUser>

        <div class="btn-submit">
          <button
            class="btn btn-primary button-compare"
            @click="showCompare()"
            :disabled="isDisabledCompareButton"
          >
            比較
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RadioList from "@/components/Top/HomeCompare/RadioList.vue"
import FormLabel from "@/components/Top/HomeCompare/FormLabel.vue"
import FormMovie from "@/components/Top/HomeCompare/FormMovie.vue"
import FormUser from "@/components/Top/HomeCompare/FormUser.vue"
import {
  targetsForComparison,
  LABEL_UNIT_VALUE,
  MOVIE_UNIT_VALUE,
  USER_UNIT_VALUE,
} from "@/commons/constants";

export default {
  props: {
    isShown: Boolean,
  },
  components: {
    RadioList,
    FormLabel,
    FormMovie,
    FormUser,
  },
  data() {
    return {
      targetsForComparisonList : targetsForComparison,
      targetForComparison: 1,
      LABEL_UNIT_VALUE,
      MOVIE_UNIT_VALUE,
      USER_UNIT_VALUE,
      isDisabledCompareButton: false,
    }
  },

  methods: {
    changeTargetForComparison(newValue) {
      this.targetForComparison = newValue
    },

    showCompare() {
      console.log('console in showCompare()')
    },

    handleEventSetDisabledButtonCompare(isDisabled) {
      this.isDisabledCompareButton = isDisabled
    },

    closeModal() {
      this.$emit("closeModal");
    },
  },

  watch: {
    isShown: function () {
      this.$refs.modal.scrollTop = 0;
    },
  },

  created() {

  },

  destroyed() {

  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/root";

.edit-segment-link {
  display: flex;
  align-items: center;
  color: #49ab94;
  font-size: 12px;
  margin-top: 8px;
  cursor: pointer;
  .icon-arrow-right {
    margin-left: 2px;
  }
}
.text-link-setting-interaction-button{
  color : #49ab94;
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.text-link-setting-interaction-button::after {
  content : "";
  display: block;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #49AB94;
  margin-left : 4px;
}
.verification-methods-description{
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  color: #999999;
}

.button-compare {
  &:disabled {
    opacity: unset;
    background: $middle-black;
  }
}
</style>
